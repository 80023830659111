import React from 'react';
import '../../assets/css/main.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from "../../styles/global.module.css"
import AboutTeamImg1 from '../../assets/images/cpg-santa-maria/area-das-piscinas.jpg';
import AboutTeamImg2 from '../../assets/images/cpg-santa-maria/area-das-piscinas2.jpg';
import AboutTeamImg3 from '../../assets/images/cpg-santa-maria/cabanas.jpg';
import AboutTeamImg4 from '../../assets/images/cpg-santa-maria/cancha-de-bocha.jpg';


const CarrouselSantaMariaData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: 'Área das Piscinas',

        heading: 'Área das Piscinas',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: 'Área das Piscinas',

        heading: 'Área das Piscinas',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: 'Cabanas',

        heading: 'Cabanas',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: 'Cancha de bocha',

        heading: 'Cancha de bocha',
        position: '',
    },
];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position }) => {


    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center position-relative">
                    <div className="single-team-style-2-img">
                        <img src={img} alt={imgAlt} />
                    </div>

                    <div className='shadow'>
                    </div>

                    <div className={styles.aboutSede}>
                        <h4 className='infosSedeImage'>{heading}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CarrouselSantaMaria = () => {
    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className='decor'>

            </div>

            <div className="team-area white-bg pt-30 pb-30">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span>Presidentes</span>
                                <h2 className={styles.titleTeamArea}>CLUBE DO PROFESSOR GAÚCHO</h2>
                            </div>
                        </div>
                    </div> */}
                    <Slider
                        {...settings}
                    >
                        {CarrouselSantaMariaData.map((data) => (
                            <AboutTeamItem
                                key={data.id}
                                img={data.img}
                                imgAlt={data.imgAlt}

                                heading={data.heading}
                                position={data.position}
                            />
                        ))}
                    </Slider>

                </div>
            </div>
        </>
    )
}

export default CarrouselSantaMaria;