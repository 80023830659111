import React, { useEffect, useState } from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ServiceDetailsContent from '../components/service-details/ServiceDetailsContent';
import ServiceHowItWork from '../components/service-details/ServiceHowItWork';
import { useParams } from 'react-router-dom';
import { events_list } from '../data/events_list';
import FooterOne from '../common/footer/FooterOne';

const ServiceDetails = () => {

    const { id } = useParams();

    const [currentPage, setCurrentPage] = useState([{}]);

    useEffect(() => {

        const chosenPage = events_list.filter(obj => (obj.id == id));

        setCurrentPage(chosenPage[0]);

    }, []);

    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading={currentPage.title}
                description=""
                currentPage={currentPage.title}
            />
            <div className="service-details pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-4 col-lg-4 pr-50">
                            {currentPage.title}
                            <ServiceWidget />
                            <ContactWidget />
                            <QuoteWidget />
                        </div> */}
                        <div className="container">
                            <div className="service-details-wrapper">
                                <ServiceDetailsContent currentPage={currentPage} />
                                <ServiceHowItWork currentPage={currentPage} />
                                {/* <ServiceFaq /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default ServiceDetails;