import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const LocationCPGSantaMaria = () => {
    return (
        <>
            <div className="about-block pt-30 pb-110">
                <div>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>LOCALIZAÇÃO</h3><br />
                        </div>
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.978597861209!2d-53.789673423537394!3d-29.662395413667177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9503cb421d79bdb5%3A0x967032f72b6bc63a!2sGaucho%20Teacher%20Club!5e0!3m2!1sen!2sbr!4v1696953664874!5m2!1sen!2sbr" width="100%" height="600"></iframe>
                        </div>
                        <div className={stylesHeadOffice.address}>
                            <div className={styles.aboutContent}>
                                <h3 className={stylesHeadOffice.titlePattern}>ENDEREÇO DA SEDE</h3>

                                <p className={stylesHeadOffice.textPattern}>TRAVESSA ESPÍNDOLA, 430 – BAIRRO CAMPESTRE<br />
                                    SANTA MARIA/RS<br />
                                    CEP 97090-190<br />
                                    FONE: <a href="tel:+5551996357557">(55) 99635.7557</a></p><br />

                                <h3 className={stylesHeadOffice.titlePattern}>HORÁRIOS</h3>
                                <p className={stylesHeadOffice.textPattern} style={{textTransform:"uppercase"}}><strong>FUNCIONAMENTO DA SEDE</strong><br />
                                    TERÇA-FEIRA A DOMINGO: 9H ÀS 20H<br />
                                </p><br />

                                <p className={stylesHeadOffice.textPattern}><strong>SECRETARIA</strong><br />
                                    TERÇA-FEIRA A DOMINGO: 9H ÀS 18H<br />
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationCPGSantaMaria;