import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';

const AboutCPGPinhal = () => {
    const [isCulturaOpen, setCulturaOpen] = useState(false);
    const [isEspacosEsportivosOpen, setEspacosEsportivosOpen] = useState(false);

    const toggleCulturaELazer = () => setCulturaOpen(!isCulturaOpen);
    const toggleEspacosEsportivos = () => setEspacosEsportivosOpen(!isEspacosEsportivosOpen);

    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>INFRAESTRUTURA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                                O CPG Balneário Pinhal é uma alternativa para os associados curtirem o veraneio. Nos seus 900 m², a sede dispõe de uma estrutura para camping e hospedagem em 24 apartamentos, oferecendo um espaço acolhedor, de lazer e descanso.
                            </p><br />

                            <div className={stylesHeadOffice.recreationArea}>
                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleCulturaELazer}>ESPAÇOS DE CULTURA E LAZER <span className={ isCulturaOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isCulturaOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Apartamentos</li>
                                            <li className={stylesHeadOffice.listItem}>Área de Camping</li>
                                            <li className={stylesHeadOffice.listItem}>Área de Churrasqueiras</li>
                                            <li className={stylesHeadOffice.listItem}>Biblioteca</li>
                                            <li className={stylesHeadOffice.listItem}>Sala de Jogos</li>
                                            <li className={stylesHeadOffice.listItem}>Cozinha coletiva</li>
                                            <li className={stylesHeadOffice.listItem}>Refeitório Comunitário</li>
                                        </ul>
                                    )}
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEspacosEsportivos}>ESPAÇOS ESPORTIVOS <span className={ isEspacosEsportivosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEspacosEsportivosOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Cancha de Bocha</li>
                                            <li className={stylesHeadOffice.listItem}>Piscina Externa</li>
                                            <li className={stylesHeadOffice.listItem}>Quadra de vôlei de areia</li>
                                        </ul>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGPinhal;