import React from 'react';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../styles/global.module.css';
import { AiOutlineCheckCircle } from 'react-icons/ai'


const BlogStyleThreeData = [
    {
        id: uuidv4(),
        img: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2023/01/BANNER_CONEXAO-scaled.jpg?resize=340%2C310&ssl=1',
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: 'Confira a retrospectiva do ano de 2022, com os principais acontecimentos das Sedes Porto Alegre, Santa Maria e Pinhal, nas áreas sociocultural, esporte e infraestrutura.…',
        date: '31 DE JANEIRO DE 2023',
        author: '',
        btnLink: '/news-details-1',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2022/07/BANNER_CONEXAO.png?resize=340%2C310&ssl=1',
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: '“Conexão CPG” é o informativo do Clube do Professor Gaúcho, que traz as novidades e o que rolou no terceiro bimestre de 2022. Confira os…',
        date: '9 DE JULHO DE 2022 ',
        author: '',
        btnLink: '/news-details-2',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: 'https://i2.wp.com/cpg.com.br/site/wp-content/uploads/2022/04/BANNER_CONEXAO.png?resize=340%2C310&ssl=1',
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: '“Conexão CPG” é o informativo do Clube do Professor Gaúcho, que traz as novidades e o que rolou no primeiro bimestre de 2022. A Pasta…',
        date: '13 DE ABRIL DE 2022',
        author: '',
        btnLink: '/news-detail-3',
        btnText: 'SAIBA MAIS',
    },
];


const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className={styles.categoryNews}>
                <div className="bg-hover-style-1">
                    <Link to={btnLink}><img className="categoryImages" src={img} alt="" /></Link>
                    {/* <Link to={btnLink}><FaPlus /></Link> */}
                </div>
                <div className={styles.categoryInfos}>
                    {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                    <h4><Link to={btnLink}>{heading}</Link></h4>
                    <span>{date}</span>
                    <p>{content}</p>
                    {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                    <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                </div>
            </div>
        </>
    )
}



const ComunicationNews = () => {
    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className={styles.newsContentInternal}>

                        <div className={styles.newsContainer}>
                            <div className="">
                                {BlogStyleThreeData.map((data, index) => (
                                    <BlogStyleThreeItem
                                        key={data.id}
                                        img={data.img}
                                        parentCat={data.parentCat}
                                        regularCat={data.regularCat}
                                        heading={data.heading}
                                        content={data.content}
                                        date={data.date}
                                        author={data.author}
                                        description={data.description}
                                        btnLink={data.btnLink}
                                        btnText={data.btnText}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='news-right-content'>
                            <div className={styles.newsCategoryPageInternal}>
                                <h3 className={styles.newsCategoryTitle}>Categorias</h3>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-comunicacao">COMUNICAÇÃO</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-pinhal">CPG PINHAL</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-poa">CPG POA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-santa-maria">CPG SANTA MARIA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-ctg-laco-da-querencia">CTG LAÇO DA QUERÊNCIA</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComunicationNews;