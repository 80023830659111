import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const AboutCPGLacoDaQuerencia = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <p className={stylesHeadOffice.textPattern}>
                                Fundado em 5 de outubro de 1985, o CTG Laço da Querência é uma das
                                principais instituições de divulgação da cultura gauchesca na Capital. Filiado ao
                                MTG (Movimento Tradicionalista Gaúcho).<br />
                                O CTG promove aulas de fandango, concursos de prendas e peões, jantares,
                                fandangos campeiros e café campeiros, além de ações de integração com
                                outros centros de Tradicionalismo, também participa, com os representantes da
                                invernada Mirim, do FESTIRIM e com os integrantes da invernada adulta, do
                                ENART. Possui Invernadas, Pré-mirim (até 9 anos) Mirim (até 13 anos) juvenil (até
                                17 anos), Adulta (Mínimo 15 anos) veterano (mínimo 30 anos) e Xiru (Mínimo 40
                                anos). Nossa entidade também procura desenvolver projetos de cunho social
                                através de iniciativas do prendado ou da patronagem. Podem participar das
                                invernadas, associados e comunidade, mediante pagamento da mensalidade,
                                onde terá uma carteirinha que dá acesso somente ao CTG, os ensaios
                                acontecem nas sextas feiras a partir das 20h,Maiores informações com o
                                patrão Marcio ou a coordenadora Lisiane.
                            </p>
                        </div>

                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "550px", borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>PATRÃO</h4>
                                <p className='pb-50' style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>MÁRCIO CORNÉLIO DE CARVALHO</p>
                            </div>
                        </div>

                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "550px", borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>COORDENADOR</h4>
                                <p className='pb-50' style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>LISIANE VIDAL</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='pt-70'>
                    <div>
                        <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                    </div>
                    <table className={styles.tablePattern}>
                        <thead>
                            <tr>
                                <th>MODALIDADE</th>
                                <th>DIA</th>
                                <th>HORÁRIO</th>
                                <th>LOCAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Invernada Xiru</td>
                                <td>Terça-feira</td>
                                <td>20h30 às 21h30</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Invernada Juvenil</td>
                                <td>Quarta-feira</td>
                                <td>20h30 às 21h30</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Invernada Juvenil</td>
                                <td>Sábado</td>
                                <td>16h às 18h</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Quinta Gaúcha</td>
                                <td>Quinta-feira</td>
                                <td>20h30 às 22h30</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Invernada Pré-Mirim</td>
                                <td>Sexta-feira</td>
                                <td>20h30 às 21h30</td>
                                <td>Sala de Ginástica (Ginásio)</td>
                            </tr>

                            <tr>
                                <td>Invernada Mirim</td>
                                <td>Sexta-feira</td>
                                <td>20h30 às 23h</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Invernada Adulta</td>
                                <td>Sábado</td>
                                <td>18h às 21h</td>
                                <td>CTG Laço da Querência</td>
                            </tr>

                            <tr>
                                <td>Curso de Fandango</td>
                                <td>Quinta-feira</td>
                                <td>20h às 22h</td>
                                <td>CTG Laço da Querência</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default AboutCPGLacoDaQuerencia;