import React from 'react';
import styles from '../../styles/global.module.css';
import { FiMapPin } from 'react-icons/fi';
import { BsTelephone, BsEnvelopeOpen, BsClock } from 'react-icons/bs';

const ContactMap = () => {
    return (
        <>
            <div className="map-area">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3450.242280550958!2d-51.22626492352106!3d-30.144488435924668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519839c8ab9fb91%3A0xb3bdb00c4baa3d01!2sClube%20do%20Professor%20Ga%C3%BAcho!5e0!3m2!1sen!2sbr!4v1697564737776!5m2!1sen!2sbr" width="100%" height="800"></iframe>
            </div>
            <div className='container'>
                <div className={styles.contactInfos}>
                    <div className='card-info'>
                        <div className={styles.cardIcon}>
                            <FiMapPin size={24} />
                        </div>
                        <h3>LOCALIZAÇÃO</h3>
                        <p className={styles.textPattern}><strong>SEDE ZONA SUL</strong><br />
                            Porto Alegre / RS - Avenida Guaíba,
                            12060 - Bairro Ipanema
                            CEP: 91770-110
                        </p>
                    </div>

                    <div className='card-info'>
                        <div className={styles.cardIcon}>
                            <BsTelephone size={24} />
                        </div>
                        <h3>TELEFONE</h3>
                        <p className={styles.textPattern}>
                            <a id='link-contact' href="tel:+555132485710">(51) 3248-5710</a><br />
                            <a id='link-contact' href="tel:+555132080705">(51) 3208-0705</a><br />
                            <a id='link-contact' href="tel:+555130174284">(51) 3017-4284</a><br />
                            <a id='link-contact' href="tel:+555130174285">(51) 3017-4285</a><br />
                            <a id='link-contact' href="tel:+555130174286">(51) 3017-4286</a>
                        </p>
                    </div>

                    <div className='card-info'>
                        <div className={styles.cardIcon}>
                            <BsEnvelopeOpen size={24} />
                        </div>
                        <h3>EMAIL</h3>
                        <p className={styles.textPattern}>
                        <a id='link-contact' href="mailto:atendimento@cpg.com.br">atendimento@cpg.com.br</a>
                        </p>
                    </div>

                    <div className='card-info'>
                        <div className={styles.cardIcon}>
                            <BsClock size={24} />
                        </div>
                        <h3>HORÁRIO DE FUNCIONAMENTO</h3>
                        <p className={styles.textPattern}>
                            Terça a Sábado: 8h00 às 22h00
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactMap;