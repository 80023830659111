import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from '../../styles/global.module.css';

const AboutCPGTasteMasters = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-90 pb-60">
                <div className="container">
                    <div className="row">
                        <div className={styles.columnCenter}>
                            <p className={styles.textPattern}>
                                A confraria masculina “Cooks Mestres do Sabor”, foi criada em 8 de março de 2008.
                                Através de um grupo de associados e dependentes, com humor peculiar, que se
                                reúnem mensalmente, na primeira Quinta-feira de cada mês, para elaborarem
                                receitas e apreciar bebidas. Relatar experiências gastronômicas e de vida.
                                É um grupo heterogêneo, mas em comum a amizade e companheirismo dos
                                seus integrantes, possui um coordenador, eleito que orienta as reuniões e
                                representa o grupo em suas demandas.<br />
                                Para fazer parte do grupo tem que encaminhar uma solicitação, por escrito, ou
                                ser convidado. A solicitação é avaliada e votada pelo grupo. Imprescindível
                                saber cozinhar e ter bom humor.
                            </p>

                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222" }}>COORDENADOR</h4>
                                <p style={{ fontWeight: "500", color: "#222", marginBottom: "2px" }}>ODENIR BARROSO DE OLIVEIRA FILHO</p>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", color: "#222" }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGTasteMasters;