import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGDancaDeSalaoContent from '../components/cpg-sports/CPGDancaDeSalaoContent';
import CPGFutsalContent from '../components/cpg-sports/CPGFutsalContent';
import CPGHandebolContent from '../components/cpg-sports/CPGHandebolContent';

const CPGHandebol = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="HANDEBOL"
                description=""
                currentPage="HANDEBOL" 
            />
            <CPGHandebolContent />
            <FooterOne />
        </>
    )
}

export default CPGHandebol;