import React from 'react';
import ServiceDetailsImg1 from '../../assets/images/service/1.jpg';
import styles from '../../styles/global.module.css';
import Slider from "react-slick";

const ServiceDetailsContent = ({ currentPage }) => {
    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <Slider
                {...settings}
            >
                {currentPage.images?.map((image, index) => {
                    return (
                        <img key={index} src={image.url} className="mt-20 mb-35 width-400" alt="" />
                    )
                })}
            </Slider>
            <p className={styles.titlePattern2}>{currentPage.subtitle}</p>
            <p className={styles.textPattern}>{currentPage.text}</p>

            <div className='library-content'>
                {
                    currentPage.libraryContent?.map((item, index) => {
                        return(
                            <div key={index}>
                                <p  className={styles.titlePattern2}>{item.titleLibrary}</p>
                                <p className={styles.textPattern}>{item.textLibrary}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default ServiceDetailsContent;