import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from '../../styles/global.module.css';

const AboutCouplesFraternity = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-90 pb-60">
                <div className="container">
                    <div className="row">
                        <div className={styles.columnCenter}>
                            <p className={styles.textPattern}>Mais informações com o Departamento Sociocultural, pelo telefone (51) 3248.5710.</p>
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222" }}>COORDENADOR</h4>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", fontWeight: "500", color: "#222", marginBottom: "2px" }}>CESAR DAY</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCouplesFraternity;