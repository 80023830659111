import React, { useEffect, useState } from 'react';
import axios from "axios";
import ModalVideo from 'react-modal-video';
import styles from '../../styles/global.module.css';
import '../../App.css';
import api from '../../services/api';

const BusinessHoursContent = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        api.get('/horario-funcionamento.php')
            .then(res => {
                if (res.data.success) {
                    setData(res.data.horarios);
                    console.log("dataaa", res.data.horarios);
                }
            });
    }, []);
    let previousTitle = '';


    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-12" id='text-transform' style={{ display: "flex", flexDirection: "column" }}>
                            {/* <p className={styles.textPattern} style={{ "fontStyle": "italic" }}>Horários sujeitos a alterações. Verifique na Central de Atendimento.</p><br />

                            <h3 className={styles.titlePattern}>CPG PORTO ALEGRE</h3>
                            <p className={styles.textPattern}>AV GUAÍBA, 12060 – BAIRRO IPANEMA<br />
                                PORTO ALEGRE/RS<br />
                                CEP 91760-740<br />
                                FONE: <a href="tel:+555132485710">(51) 3248.5710</a> / <a href="tel:+5551989065114">(51) 98906.5114</a><br />
                                <a href="mailto:atendimento@cpg.com.br">atendimento@cpg.com.br</a>
                            </p>
                            <h3 className={stylesHeadOffice.titlePattern} style={{ justifyContent: "center" }}>HORÁRIOS</h3>
                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>Funcionamento da sede</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA  a domingo: 8h às 22h</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>PISCINAS EXTERNAS</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A DOMINGO: 9H ÀS 18H</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>CENTRAL DE ATENDIMENTO</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A SÁBADO: 8H30 ÀS 19H50<br />DOMINGOS E FERIADOS: 9H ÀS 16H50</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>ATENDIMENTO PARA ASSOCIAÇÃO</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A SÁBADO: 9H ÀS 16H50</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>MUSCULAÇÃO</span>
                            <p className={styles.textPattern}>DE TERÇA-FEIRA A QUINTA-FEIRA: 8H ÀS 22H.<br />SEXTA-FEIRA: 8H ÀS 21H.<br />SÁBADO: 8H ÀS 12H E 14H ÀS 18H.</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>BIBLIOTECA</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A SÁBADO: 9H ÀS 17H.</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>CASINHA</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A DOMINGO: 10H ÀS 19H.</p><br />


                            <h3 className={`${styles.titlePattern} ${styles.mt2Rem}`}>CPG SANTA MARIA</h3>
                            <p className={styles.textPattern}>TRAVESSA ESPÍNDOLA, 430 – BAIRRO CAMPESTRE<br />
                                SANTA MARIA/RS<br />
                                CEP 97090-190<br />
                                FONE: (55) 99635.7557<br />
                                <a href="mailto:secretariasm@cpg.com.br">secretariasm@cpg.com.br</a>
                            </p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>Funcionamento da Sede</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A DOMINGO: 9H ÀS 20H</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>Secretaria</span>
                            <p className={styles.textPattern}>TERÇA-FEIRA A DOMINGO: 9H ÀS 18H</p>

                            <h3 className={`${styles.titlePattern} ${styles.mt2Rem}`}>CPG PINHAL</h3>
                            <p className={styles.textPattern}>Rua Agostinho Rocha, 1458 <br /> Balneário Pinhal/RS<br />
                                CEP 95599-000<br />
                                FONE: (51) 3682-2995
                            </p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>Funcionamento da Sede / ATENDIMENTO ADMINISTRATIVO</span>
                            <p className={styles.textPattern}>8h às 20h</p>

                            <span className={styles.textPattern} style={{ fontWeight: 600 }}>Entre em contato:</span>
                            <p className={styles.textPattern}>51 3682-2995</p> */}


                            {data.map((item, index) => {
                                    const showTitle = previousTitle !== item.titulo;
                                    previousTitle = item.titulo;
                                    
                                    return (
                                        <div className='horarios' key={index}>
                                            {showTitle && (
                                                <h3 className={styles.titlePattern} style={{ marginTop: "3.7rem" }}>{item.titulo}</h3>
                                            )}

                                            {item.subtitulo && (
                                                <p className={styles.textPatternmargin} style={{ fontWeight: 600, marginTop: "1.2rem" }}>{item.subtitulo}</p>
                                            )}

                                            {item.paragrafo1 && (
                                                <p className={styles.textPatternmargin}>{item.paragrafo1}</p>
                                            )}

                                            {item.paragrafo2 && (
                                                <p className={styles.textPatternmargin}>{item.paragrafo2}</p>
                                            )}

                                            {item.paragrafo3 && (
                                                <p className={styles.textPatternmargin}>{item.paragrafo3}</p>
                                            )}

                                            {item.paragrafo4 && (
                                                <p className={styles.textPatternmargin}>{item.paragrafo4}</p>
                                            )}

                                            {item.paragrafo5 && (
                                                <p className={styles.textPatternmargin}>{item.paragrafo5}</p>
                                            )}

                                            {item.email && (
                                                <a href={`mailto:${item.email}`}>
                                                    <p className={styles.textPatternmargin} style={{textTransform:"lowercase"}}>{item.email}</p>
                                                </a>
                                            )}
                                        </div>
                                    );
                            })}

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default BusinessHoursContent;