import React from 'react';

import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';

import FooterOne from '../common/footer/FooterOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import TerceirizadosGallery from '../components/cpg-terceirizados/TerceirizadosGallery';


const CPGTerceirizados = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading="TERCEIRIZADOS NO CLUBE"
                description=""
                currentPage="TERCEIRIZADOS NO CLUBE"
            />
            <TerceirizadosGallery />
            <FooterOne />
            
        </>
    )
}

export default CPGTerceirizados;