import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import news2 from '../../assets/images/home/conexao-edicao-44.webp';
import news3 from '../../assets/images/home/conexao-edicao-43.webp';
import news4 from '../../assets/images/home/conexao-edicao-horario.webp';
import styles from '../../styles/global.module.css';
import privacyPolicy from '../../assets/images/home/politica-de-privacidade.webp';
import { AiOutlineCheckCircle } from 'react-icons/ai'

const principalNew = [
    {
        id: uuidv4(),
        img: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2023/01/BANNER_CONEXAO-scaled.jpg?resize=360%2C300&ssl=1',
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: 'Confira a retrospectiva do ano de 2022, com os principais acontecimentos das Sedes Porto Alegre, Santa Maria e Pinhal, nas áreas sociocultural, esporte e infraestrutura. Além de oferecer a versão…',
        date: '31 DE JANEIRO DE 2023',
        author: '',
        btnLink: '/news-details-1',
        btnText: 'CONTINUE LENDO',
    },
]

const BlogStyleThreeData = [

    {
        id: uuidv4(),
        img: news2,
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: '',
        date: '28 DE OUTUBRO DE 2022 ',
        author: '',
        btnLink: '/news-details-2',
        btnText: 'CONTINUE LENDO',
    },

    {
        id: uuidv4(),
        img: news3,
        parentCat: '',
        regularCat: '',
        heading: 'CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG',
        content: '',
        date: '',
        author: '',
        btnLink: '/news-details-3',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: news4,
        parentCat: '',
        regularCat: '',
        heading: 'HORÁRIO DE FUNCIONAMENTO NO FERIADO | 16.06.2022',
        content: '',
        date: '',
        author: '',
        btnLink: '/news-details-4',
        btnText: 'SAIBA MAIS',
    },
];

const PrincipalNewItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12">

                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <div className="blog-img">
                        <img src={img} alt="" />
                        <Link to={btnLink}><FaPlus /></Link>
                    </div>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to={btnLink}>{heading}</Link></h4>
                        <span>{date}</span>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                    </div>
                </div>
            </div>
        </>
    )
};


const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className={styles.miniNews}>

                <div className={styles.miniNewsContent}>
                    <div className="bg-hover-style-1">
                        <Link to={btnLink}><img className={styles.miniNewsImages} src={img} alt="" /></Link>
                        {/* <Link to={btnLink}><FaPlus /></Link> */}
                    </div>
                    <div className={styles.miniNewsContentInfos}>
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to={btnLink}>{heading}</Link></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        {/* <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link> */}
                    </div>
                </div>
            </div>
        </>
    )
}



const HomeNews = () => {
    return (
        <>
            <div className={`blog-area bg-white ${styles.pb27}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 pt-80">
                            <div className="section-title mb-40">
                                <span style={{ fontSize: "28px", fontWeight: "300" }}>Fique por dentro das</span>
                                <h3 style={{ fontSize: "34px", fontWeight: "600", color: "#333", textAlign: "left" }}>ÚLTIMAS NOVIDADES</h3>
                            </div>
                        </div>
                    </div>
                    <div className={styles.newsContent}>

                        <div className={styles.newsContainer}>
                            <div className='principal-new'>
                                {principalNew.map((item) => (
                                    <PrincipalNewItem
                                        key={item.id}
                                        img={item.img}
                                        parentCat={item.parentCat}
                                        regularCat={item.regularCat}
                                        heading={item.heading}
                                        content={item.content}
                                        date={item.date}
                                        author={item.author}
                                        description={item.description}
                                        btnLink={item.btnLink}
                                        btnText={item.btnText}
                                    />
                                ))}
                            </div>
                            <div className="">
                                {BlogStyleThreeData.map((data, index) => (
                                    <BlogStyleThreeItem
                                        key={data.id}
                                        img={data.img}
                                        parentCat={data.parentCat}
                                        regularCat={data.regularCat}
                                        heading={data.heading}
                                        content={data.content}
                                        date={data.date}
                                        author={data.author}
                                        description={data.description}
                                        btnLink={data.btnLink}
                                        btnText={data.btnText}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='news-right-content'>
                            <a href='https://drive.google.com/file/d/1WOI19PfYB2DlX5sX62A0uUdGl44EhCfp/view?usp=sharing' target='_blank'>
                                <img src={privacyPolicy} />
                            </a>
                            <div className={styles.newsCategoryHome}>
                                <h3 className={styles.newsCategoryTitle}>Categorias</h3>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-comunicacao">COMUNICAÇÃO</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-cpg-pinhal">CPG PINHAL</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-cpg-poa">CPG POA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-cpg-santa-maria">CPG SANTA MARIA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-ctg-laco-da-querencia">CTG LAÇO DA QUERÊNCIA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-esporte">ESPORTE</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink}  to="/categoria-social">SOCIAL</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeNews;