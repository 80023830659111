import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import institutionalImage from '../../assets/images/cpg-management/DIRETORIA.jpg'

const AboutCPGManagement = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.imageContent}>
                            <img src={institutionalImage} />
                        </div>
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>MISSÃO</h3>
                            <p className={styles.textPattern}>
                                Proporcionar satisfação, bem-estar e qualidade de vida aos associados através de ações de acolhimento e dedicação nos âmbitos social, cultural, esportivo e de lazer.
                            </p><br />

                            <h3 className={styles.titlePattern}>VISÃO</h3>
                            <p className={styles.textPattern}>
                                Ser reconhecido como clube de excelência e inovador nas atividades desenvolvidas e na satisfação dos associados de todas as gerações, de forma sustentável.
                            </p><br />

                            <h3 className={styles.titlePattern}>VALORES</h3>
                            <p className={styles.textPattern}>
                               <strong>Sustentabilidade </strong>  – Atuamos em convergência dos propósitos econômicos, sociais e ambientais para preservar a capacidade de atendimento das necessidades das gerações futuras.<br />
                               <strong>Comprometimento </strong>  – Respeitamos e assumimos compromissos com os propósitos, regras e diretrizes do Clube.<br />
                               <strong>Competência </strong>  – Atuamos com pleno conhecimento e aptidão na realização de nossas atividades.<br />
                               <strong>Eficiência </strong>  – Otimizamos permanentemente os recursos disponíveis para criação de valor nas ações que realizamos.<br />
                               <strong>Comportamento Ético </strong>  – Os princípios e valores do Clube são os elementos fundamentais para relacionamento entre as pessoas e entidades com quem nos relacionamos.<br />
                               <strong>Inovação </strong>  – Buscamos desenvolver ideias e melhorias contínuas em nossos serviços e processos.<br />
                               <strong>Trabalho em Equipe </strong>  – Os objetivos do clube são alcançados através do esforço coletivo, com todos atuando como um time.<br />
                            </p>

                            {/* <div className={stylesHeadOffice.recreationArea}>
                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titlePattern}>ESPAÇOS DE CULTURA E LAZER</h3>
                                    <ul className={stylesHeadOffice.list}>
                                        <li className={stylesHeadOffice.listItem}>Apartamentos</li>
                                        <li className={stylesHeadOffice.listItem}>Área de Camping</li>
                                        <li className={stylesHeadOffice.listItem}>Área de Churrasqueiras</li>
                                        <li className={stylesHeadOffice.listItem}>Biblioteca</li>
                                        <li className={stylesHeadOffice.listItem}>Sala de Jogos</li>
                                        <li className={stylesHeadOffice.listItem}>Cozinha coletiva</li>
                                        <li className={stylesHeadOffice.listItem}>Refeitório Comunitário</li>
                                    </ul>
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titlePattern}>ESPAÇOS ESPORTIVOS</h3>
                                    <ul className={stylesHeadOffice.list}>
                                        <li className={stylesHeadOffice.listItem}>Cancha de Bocha</li>
                                        <li className={stylesHeadOffice.listItem}>Piscina Externa</li>
                                        <li className={stylesHeadOffice.listItem}>Quadra de vôlei de areia</li>
                                    </ul>
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titlePattern}>ESPAÇOS DE EVENTOS</h3>
                                    <ul className={stylesHeadOffice.list}>

                                    </ul>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGManagement;