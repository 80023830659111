import React from 'react';

import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';

import BusinessHoursContent from '../components/project/BusinessHours';
import FooterOne from '../common/footer/FooterOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import EventContent from '../components/project/EventContent';
import HandicraftContent from '../components/project/HandicraftContent';
import HandicraftImages from '../components/project/HandicraftImages';


const Handicraft = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading="ARTESANATO"
                description=""
                currentPage="ARTESANATO"
            />
            <HandicraftContent />
            <HandicraftImages />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default Handicraft;