import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutCPGLibrary from '../components/cgp-library/AboutCGPLibrary';
import CarrouselCPGLibrary from '../components/cgp-library/CarrouselCPGLibrary';
import CPGLibraryAcquisitions from '../components/cgp-library/CPGLibraryAcquisitions';
import AboutCPGCoral from '../components/cpg-coral/AboutCPGCoral';
import AboutCPGCultural from '../components/cpg-cultural/AboutCPGCultural';
import CPGCulturalImages from '../components/cpg-cultural/CPGCulturalImages';

const CPGCultural = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CULTURAL"
                description=""
                currentPage="CULTURAL" 
            />
            <AboutCPGCultural />
            <CPGCulturalImages />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGCultural;