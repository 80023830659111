import React from 'react';
import '../../assets/css/main.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from "../../styles/global.module.css"
import AboutTeamImg1 from '../../assets/images/team/odalgir-alfredo-lazzari.jpg';
import AboutTeamImg2 from '../../assets/images/team/conceicao.jpg';
import AboutTeamImg3 from '../../assets/images/team/iara.jpg';
import AboutTeamImg4 from '../../assets/images/team/teresinha.jpg';
import AboutTeamImg5 from '../../assets/images/team/thereza-noronha.jpg';
import AboutTeamImg6 from '../../assets/images/team/maria-ruth-1.jpg';
import AboutTeamImg7 from '../../assets/images/team/eunice.jpg';
import AboutTeamImg8 from '../../assets/images/team/airton.jpg';
import AboutTeamImg9 from '../../assets/images/team/osvaldo-mazola-bassanesi-rodrigues.jpg';

const AboutTeamData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: 'Odalgir Alfredo Lazzari img',

        heading: 'Odalgir Alfredo Lazzari',
        position: '1991 a 2003',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: 'Maria da Conceição Nogueira Pires img',

        heading: 'Maria da Conceição Nogueira Pires',
        position: '2003 a 2009',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: 'Iara Teresinha Bernardes Malta Img',

        heading: 'Iara Teresinha Bernardes Malta',
        position: '2009 a 2016',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: 'Maria Teresinha Mendes Img',

        heading: 'Maria Teresinha Mendes',
        position: '2017 a 2024',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg5,
        imgAlt: 'Thereza Noronha Carvalho Img',

        heading: 'Thereza Noronha Carvalho',
        position: '1966 a 1975',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg6,
        imgAlt: 'Maria Ruh Issler Img',

        heading: 'Maria Ruh Issler',
        position: '1975 a 1978',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg7,
        imgAlt: 'Eunice da Silva Olsen Img',

        heading: 'Eunice da Silva Olsen',
        position: '1978 a 1980',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg8,
        imgAlt: 'Airton da Silva Negrini Img',

        heading: 'Airton da Silva Negrini',
        position: '1980 a 1985',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg9,
        imgAlt: 'Oswaldo Mazola Rodrigues Img',

        heading: 'Oswaldo Mazola Rodrigues',
        position: '1985 a 1991',
    },

];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position }) => {


    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center">
                    <div className="single-team-style-2-img">
                        <img className={styles.imgPadding} src={img} alt={imgAlt} />
                    </div>
                    <div className="single-team-content-style-2">
                        <h4 style={{ color: "#222", fontSize: "16px", textTransform: "uppercase" }}>{heading}</h4>
                        <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}>{position}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AboutTeam = () => {
    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [
            
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className='decor'>
                
            </div>

            <div className="team-area white-bg pt-70 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span>Presidentes</span>
                                <h2 className={styles.titleTeamArea}>CLUBE DO PROFESSOR GAÚCHO</h2>
                            </div>
                        </div>
                    </div>
                    <Slider
                        {...settings}
                    >
                            {AboutTeamData.map((data) => (
                                <AboutTeamItem
                                    key={data.id}
                                    img={data.img}
                                    imgAlt={data.imgAlt}

                                    heading={data.heading}
                                    position={data.position}
                                />
                            ))}
                    </Slider>

                </div>
            </div>
        </>
    )
}

export default AboutTeam;