import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ComunicationNews from "../components/news-categories/ComunicationNews";


const ComunicationCategory = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="COMUNICAÇÃO"
                description=""
                currentPage="COMUNICAÇÃO" 
            />
            <ComunicationNews />
            <FooterOne />
        </>
    )
}

export default ComunicationCategory;