import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGHidroginasticaContent from '../components/cpg-sports/CPGHidroginasticaContent';
import CPGJazzContent from '../components/cpg-sports/CPGJazzContent';

const CPGJazz = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="JAZZ"
                description=""
                currentPage="JAZZ" 
            />
            <CPGJazzContent />
            <FooterOne />
        </>
    )
}

export default CPGJazz;