import React, { useState } from 'react';
import styles from '../../styles/global.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';

const GalleryDetailsContent = ({ currentPage }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const downloadImage = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = selectedImage.url; // Use the selected image URL for download
        downloadLink.download = 'cpg-imagem.jpg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowDownloadButton(false);
    }

    function openModal(image) {
        setSelectedImage(image)
        setShowModal(true);
    }

    function closeModal() {
        setSelectedImage(null);
        setShowModal(false);
    }

    return (
        <>
            {currentPage.images?.map((image, index) => {
                return (
                    <div key={index}>
                        {console.log(image.url)}

                        <img onClick={() => openModal(image)} src={image.url} className="mt-20 mb-35 cursor-pointer" alt="" />

                        {showModal && selectedImage && (
                            <div className={styles.imageModalDetail}>
                                <img className={styles.zoomImageGallery} src={selectedImage.url} alt="" />
                                <button onClick={closeModal} className={styles.closeModalGallery}>
                                    <AiOutlineClose size={27} />
                                </button>
                                <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                            </div>
                        )}
                    </div>
                )
            })}
        </>
    )
}

export default GalleryDetailsContent;