import React from 'react';
import {Link} from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import BlogStyleThreeImg1 from '../../assets/images/cpg-revive/revive1.webp';
import BlogStyleThreeImg2 from '../../assets/images/ctg-laco-da-querencia/ctg-5webp.webp';
import BlogStyleThreeImg3 from '../../assets/images/cpg-gallery/festa-fantasia.webp';
import BlogStyleThreeImg4 from '../../assets/images/about/couplesFraternity1.webp';
import BlogStyleThreeImg5 from '../../assets/images/about/Cultural1.webp';
import BlogStyleThreeImg6 from '../../assets/images/cooks-fraternity/cook6.webp';
import BlogStyleThreeImg7 from '../../assets/images/cooks-fraternity/master1.webp';
import AboutTeamImg9 from '../../assets/images/cpg-coral/coral9.webp';
import artesanato1 from '../../assets/images/about/artesanato.webp';

import Pagination from '../pagination/Pagination';
import { events_list } from '../../data/events_list';

const BlogStyleThreeData = [
    
    {
        id: uuidv4(),
        img: BlogStyleThreeImg1,
        parentCat: '',
        regularCat: '',
        heading: 'Departamento Reviver',
        content: '',
        date: '',
        author: '',
        btnLink: '/reviver',
        btnText: 'SAIBA MAIS',
    },
    
    {
        id: uuidv4(),
        img: BlogStyleThreeImg2,
        parentCat: '',
        regularCat: '',
        heading: 'Departamento CTG Laço da Querência',
        content: '',
        date: '',
        author: '',
        btnLink: '/ctg-laco-da-querencia',
        btnText: 'SAIBA MAIS',
    },
    
    {
        id: uuidv4(),
        img: AboutTeamImg9,
        parentCat: '',
        regularCat: '',
        heading: 'Departamento Coral',
        content: '',
        date: '',
        author: '',
        btnLink: '/coral',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg5,
        parentCat: '',
        regularCat: '',
        heading: 'Departamento Cultural',
        content: '',
        date: '',
        author: '',
        btnLink: '/cultural',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg3,
        parentCat: '',
        regularCat: '',
        heading: 'Clube de Leitura',
        content: '',
        date: '',
        author: '',
        btnLink: '/5',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg3,
        parentCat: '',
        regularCat: '',
        heading: 'Clube de Cinema',
        content: '',
        date: '',
        author: '',
        btnLink: '/6',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg3,
        parentCat: '',
        regularCat: '',
        heading: 'Exposições Culturais',
        content: '',
        date: '',
        author: '',
        btnLink: '/7',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: artesanato1,
        parentCat: '',
        regularCat: '',
        heading: 'Departamento Artesanato',
        content: '',
        date: '',
        author: '',
        btnLink: '/artesanato',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg6,
        parentCat: '',
        regularCat: '',
        heading: 'Confraria Cookies do Saber',
        content: '',
        date: '',
        author: '',
        btnLink: '/confraria-cooks-do-saber',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg7,
        parentCat: '',
        regularCat: '',
        heading: 'Confraria Mestres do Sabor',
        content: '',
        date: '',
        author: '',
        btnLink: '/confraria-mestres-do-sabor',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg4,
        parentCat: '',
        regularCat: '',
        heading: 'Confraria dos Casais',
        content: '',
        date: '',
        author: '',
        btnLink: '/confraria-dos-casais',
        btnText: 'SAIBA MAIS',
    },
];

const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <div className="blog-img sociocultural-img">
                        <img src={img} alt="" />
                        <Link to={btnLink}><FaPlus /></Link>
                    </div>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to={btnLink}>{heading}</Link></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const CPGSocioculturalContent = () => {
    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="section-title text-center mb-70">
                                <span>Latest News & Blog</span>
                                <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <Pagination />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default CPGSocioculturalContent;