import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGBasqueteContent from '../components/cpg-sports/CPGBasqueteContent';
import CPGDancaCiganaContent from '../components/cpg-sports/CPGDancaCiganaContent';

const CPGDancaCigana = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="DANÇA CIGANA"
                description=""
                currentPage="DANÇA CIGANA" 
            />
            <CPGDancaCiganaContent />
            <FooterOne />
        </>
    )
}

export default CPGDancaCigana;