import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGKarateContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>KARATÊ</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                Você conhece os benefícios do Karatê? Ele desenvolve disciplina, respeito mútuo, fortalecimento do caráter, autoestima e convívio social, além das vantagens da prática de uma atividade física.
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGKarateContent;