import React from 'react';

import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';

import FooterOne from '../common/footer/FooterOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import EventContent from '../components/project/EventContent';


const MakeAnEvent = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading="ESPAÇOS PARA LOCAÇÃO"
                description=""
                currentPage="ESPAÇOS PARA LOCAÇÃO"
            />
            <EventContent />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default MakeAnEvent;