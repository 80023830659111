import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import terceirizados1 from '../../assets/images/cpg-terceirizados/restaurante-anfitriao.JPG';
import terceirizados2 from '../../assets/images/cpg-terceirizados/estetica-lotus.JPG';
import terceirizados3 from '../../assets/images/cpg-terceirizados/beneficium-pilates.jpg';
import styles from '../../styles/global.module.css';


// const BlogStyleThreeData = [

//     {
//         id: uuidv4(),
//         img: terceirizados1,
//         parentCat: '',
//         regularCat: '',
//         heading: 'Restaurante Anfittrião',
//         content: '',
//         date: '',
//         author: '',
//         btnLink: '/cpg-galeria-1',
//         btnText: 'SAIBA MAIS',
//     },

//     {
//         id: uuidv4(),
//         img: terceirizados2,
//         parentCat: '',
//         regularCat: '',
//         heading: 'Estética Espaço Lótus',
//         content: '',
//         date: '',
//         author: '',
//         btnLink: '/cpg-galeria-2',
//         btnText: 'SAIBA MAIS',
//     },

//     {
//         id: uuidv4(),
//         img: terceirizados3,
//         parentCat: '',
//         regularCat: '',
//         heading: 'Beneficium Pilates',
//         content: '',
//         date: '',
//         author: '',
//         btnLink: '/cpg-galeria-3',
//         btnText: 'SAIBA MAIS',
//     },
// ];

// const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
//     const [showModal, setShowModal] = useState(false);
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         axios.get('http://cpg.web15f86.uni5.net/ws/terceirizados-cpg.php')
//             .then(res => {
//                 if (res.data.success) {
//                     setData(res.data.horarios);
//                     console.log("dataaa", res.data.horarios);
//                 }
//             });
//     }, []);

//     function openModal() {
//         setShowModal(true);
//     }

//     function closeModal() {
//         setShowModal(false);
//     }


//     return (
//         <>
//             <div className="col-xl-4 col-lg-4 col-md-4">
//                 <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
//                     <div className="blog-img">
//                         <img onClick={openModal} src={img} alt="" />
//                         <Link to={btnLink}><FaPlus /></Link>
//                     </div>
//                     <div className="blog-content pt-20 pr-20 pb-20 pl-20">
//                         {/* <ul className="cat-list mt-30 mb-30">
//                             <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
//                             <li><Link to="/">{regularCat}</Link></li>
//                         </ul> */}
//                         <h4>{data.titulo}</h4>
//                         {/* <p>{content}</p> */}
//                         {/* <ul className="post-meta mb-30">
//                                 <li><Link to="/">{date}</Link></li>
//                                 <li><span>By</span> <Link to="/">{author}</Link></li>
//                             </ul> */}
//                         {/* <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link> */}
//                     </div>
//                 </div>

//             </div>
//             {
//                 showModal === true ?
//                     <div className={styles.imageModal}>
//                         <img className={styles.zoomImage} src={img} alt="" />
//                         <button onClick={closeModal} className={styles.closeModalBtn}><AiOutlineClose size={22} /></button>
//                     </div>
//                     :

//                     ""
//             }

//         </>
//     )
// }

const TerceirizadosGallery = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        axios.get('http://cpg.web15f86.uni5.net/ws/terceirizados-cpg.php')
            .then(res => {
                if (res.data.success) {
                    setData(res.data.horarios);
                    console.log("dataaa here", res.data.horarios);
                }
            });
    }, []);

    function openModal(item) {
        setSelectedItem(item);
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="section-title text-center mb-70">
                                <span>Latest News & Blog</span>
                                <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        {data.map((item, index) => (
                            <div className="col-xl-4 col-lg-4 col-md-4" key={index}>
                                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                                    <div className="blog-img">
                                        <img onClick={() => openModal(item)} src={`http://cpg.web15f86.uni5.net/admin/images/terceirizados/${item.id_terceirizado}.jpg`} alt="banner terceirizado" />
                                    </div>
                                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                                        {item.link ? <a href={item.link} target="_blank" rel="noopener noreferrer"><h4>{item.titulo}</h4></a> : <h4>{item.titulo}</h4>}
                                        {/* <p>{content}</p> */}
                                        {/* <ul className="post-meta mb-30">
                                <li><Link to="/">{date}</Link></li>
                                <li><span>By</span> <Link to="/">{author}</Link></li>
                            </ul> */}
                                        {/* <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link> */}
                                    </div>
                                </div>

                            </div>
                        ))}

                        {
                            showModal && selectedItem && (
                                <div className={styles.imageModal}>
                                    <img className={styles.zoomImage} src={`http://cpg.web15f86.uni5.net/admin/images/terceirizados/${selectedItem.id_terceirizado}.jpg`} alt="banner terceirizado" />
                                    <button onClick={closeModal} className={styles.closeModalBtn}><AiOutlineClose size={22} /></button>
                                </div>
                            )
                        }

                        {/* {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))} */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TerceirizadosGallery;