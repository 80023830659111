import homeNews1 from '../assets/images/home/home_news1.webp';
import homeNews2 from '../assets/images/home/home_news2.webp';
import principalImage1 from '../assets/images/home/principal-new-image-1.webp';
import principalImage2 from '../assets/images/home/principal-new-image-2.webp';

export const cpg_ctg_news = [
    {
        id: 1,
        title: "CTG PROMOVE LIVE PARA COMEMORAR OS FESTEJOS FARROUPILHAS",
        subtitle: "CTG PROMOVE LIVE PARA COMEMORAR OS FESTEJOS FARROUPILHAS",
        textContent: [
            {
                text: [
                    "Na próxima segunda-feira, 14/09, às 20h, o CTG Laço da Querência, do Clube do Professor Gaúcho, irá promover uma uma Live em comemoração ao mês farroupilha."
                ],
            },
            {
                text: [
                    "Além da presença dos músicos da casa, os quais irão comandar a Live, teremos a alegria de receber a Patrona dos Festejos Farroupilhas deste ano.",
                    "Teremos muita dança, música, descontração e surpresas."
                ]
                
            },
            {
                text: ["A transmissão será na página do CTG no Facebook."],
            },
        ],

        principalImage: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2020/09/119065214_4644644142243187_2876450127916715151_n.jpg?resize=750%2C400&ssl=1',
        
        // images: [
        //     {
        //         url: homeNews1,
        //     }
        // ],
    },
    {
        id: 2,
        title: "INSCRIÇÕES PARA CONCURSO MEU CHIMARRÃO JÁ ESTÃO ABERTAS",
        subtitle: "INSCRIÇÕES PARA CONCURSO MEU CHIMARRÃO JÁ ESTÃO ABERTAS",
        // textContent: [
        //     {
        //         text: ["Confira os principais acontecimentos das Sedes Porto Alegre e Santa Maria, nas áreas sociocultural, esporte e infraestrutura."],
        //     },
        //     {
        //         text: ["Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede."],
                
        //     },
        //     {
        //         text: ["Não esqueça de retirar o seu!"],
        //     },
        //     {
        //         text: ["Confira a versão digital! ?"],
        //     }
        // ],

        principalImage: "https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2020/09/e9ae4363-4e9d-4182-abc4-c6c5d19c126b.jpg?resize=750%2C400&ssl=1",

        // link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_ed44?utm_medium=referral&utm_source=cpg.com.br",

        // iframe: "https://e.issuu.com/embed.html?identifier=rbjoolwg22a6&embedType=script&u=clubedoprofessorgaucho&d=conex_o_ed44&p=1",
        

        images: [
            {
                url: homeNews2,
            }
        ],
    },
]