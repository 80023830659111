import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import CPGSantaMariaNews from "../components/news-categories/CPGSantaMariaNews";


const CPGSantaMariaCategory = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CPG SANTA MARIA"
                description=""
                currentPage="CPG SANTA MARIA" 
            />
            <CPGSantaMariaNews />
            <FooterOne />
        </>
    )
}

export default CPGSantaMariaCategory;