import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGRitmosContent from '../components/cpg-sports/CPGRitmosContent';
import CPGLocalContent from '../components/cpg-sports/CPGLocalContent';
import CPGFuncionalContent from '../components/cpg-sports/CPGFuncionalContent';
import CPGQuadraSinteticaContent from '../components/cpg-sports/CPGQuadraSinteticaContent';

const CPGQuadraSintetica = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="QUADRA DE GRAMA SINTÉTICA"
                description=""
                currentPage="QUADRA DE GRAMA SINTÉTICA" 
            />
            <CPGQuadraSinteticaContent />
            <FooterOne />
        </>
    )
}

export default CPGQuadraSintetica;