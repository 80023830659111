import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const AboutCPGLibrary = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <p className={stylesHeadOffice.textPattern}>
                                Inaugurada em 28 de abril de 1985. A Biblioteca Prof.ª Miguelina Contursi
                                Schilling, é o departamento mais antigo do CPG, com o ideal de promover e
                                oferecer aos associados o contato com a leitura através das mais de 12.000
                                obras que completam o acervo. A biblioteca dispõe de livros de literatura
                                nacional e estrangeira, técnicos, infanto-juvenis, DDDs, gibis, revistas e jornais
                                diários. Além de promover diversas exposições relacionadas à literatura. No ano
                                de 2013 se deu início ao Clube de Leitura, que fica sob responsabilidade do
                                Departamento Cultural e tem participação do Bibliotecário(a), onde se propõe
                                um debate a partir de determinada obra ou autor. O espaço da Biblioteca é
                                bastante utilizado pelos associados, que além de terem acesso aos jornais
                                regionais podem utilizar os serviços sem custo adicional à mensalidade.
                            </p><br />
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>BIBLIOTECÁRIA RESPONSÁVEL</h4>
                                <p style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>Maria Ester</p>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", color: "#222", fontSize: "15px" }}></p>
                            </div>
                        </div>
                        <div className='pt-70'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>Dia</th>
                                        <th>Horário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Terça a Sábado</td>
                                        <td>8h30 às 17h30</td>
                                    </tr>
                                    <tr>
                                        <td>Domingos e Feriados</td>
                                        <td>Fechado</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGLibrary;