import cha1 from '../assets/images/cpg-gallery/cha-prata/cha-prata-1.webp';
import cha2 from '../assets/images/cpg-gallery/cha-prata/cha-prata-2.webp';
import cha3 from '../assets/images/cpg-gallery/cha-prata/cha-prata-3.webp';
import cha4 from '../assets/images/cpg-gallery/cha-prata/cha-prata-4.webp';
import cha5 from '../assets/images/cpg-gallery/cha-prata/cha-prata-5.webp';
import cha6 from '../assets/images/cpg-gallery/cha-prata/cha-prata-6.webp';
import cha7 from '../assets/images/cpg-gallery/cha-prata/cha-prata-7.webp';
import cha8 from '../assets/images/cpg-gallery/cha-prata/cha-prata-8.webp';
import cha9 from '../assets/images/cpg-gallery/cha-prata/cha-prata-9.webp';
import cha10 from '../assets/images/cpg-gallery/cha-prata/cha-prata-10.webp';
import cha11 from '../assets/images/cpg-gallery/cha-prata/cha-prata-11.webp';
import cha12 from '../assets/images/cpg-gallery/cha-prata/cha-prata-12.webp';
import cha13 from '../assets/images/cpg-gallery/cha-prata/cha-prata-13.webp';
import cha14 from '../assets/images/cpg-gallery/cha-prata/cha-prata-14.webp';
import cha15 from '../assets/images/cpg-gallery/cha-prata/cha-prata-15.webp';
import cha16 from '../assets/images/cpg-gallery/cha-prata/cha-prata-16.webp';
import cha17 from '../assets/images/cpg-gallery/cha-prata/cha-prata-17.webp';
import cha18 from '../assets/images/cpg-gallery/cha-prata/cha-prata-18.webp';
import cha19 from '../assets/images/cpg-gallery/cha-prata/cha-prata-19.webp';
import cha20 from '../assets/images/cpg-gallery/cha-prata/cha-prata-20.webp';
import cha21 from '../assets/images/cpg-gallery/cha-prata/cha-prata-21.webp';
import cha22 from '../assets/images/cpg-gallery/cha-prata/cha-prata-22.webp';
import cha23 from '../assets/images/cpg-gallery/cha-prata/cha-prata-23.webp';
import cha24 from '../assets/images/cpg-gallery/cha-prata/cha-prata-24.webp';
import cha25 from '../assets/images/cpg-gallery/cha-prata/cha-prata-25.webp';
import cha26 from '../assets/images/cpg-gallery/cha-prata/cha-prata-26.webp';
import cha27 from '../assets/images/cpg-gallery/cha-prata/cha-prata-27.webp';
import cha28 from '../assets/images/cpg-gallery/cha-prata/cha-prata-28.webp';
import cha29 from '../assets/images/cpg-gallery/cha-prata/cha-prata-29.webp';
import cha30 from '../assets/images/cpg-gallery/cha-prata/cha-prata-30.webp';
import cha31 from '../assets/images/cpg-gallery/cha-prata/cha-prata-31.webp';
import cha32 from '../assets/images/cpg-gallery/cha-prata/cha-prata-32.webp';
import cha33 from '../assets/images/cpg-gallery/cha-prata/cha-prata-33.webp';
import cha34 from '../assets/images/cpg-gallery/cha-prata/cha-prata-34.webp';
import cha35 from '../assets/images/cpg-gallery/cha-prata/cha-prata-35.webp';
import cha36 from '../assets/images/cpg-gallery/cha-prata/cha-prata-36.webp';
import cha37 from '../assets/images/cpg-gallery/cha-prata/cha-prata-37.webp';
import cha38 from '../assets/images/cpg-gallery/cha-prata/cha-prata-38.webp';
import cha39 from '../assets/images/cpg-gallery/cha-prata/cha-prata-39.webp';
import cha40 from '../assets/images/cpg-gallery/cha-prata/cha-prata-40.webp';
import cha41 from '../assets/images/cpg-gallery/cha-prata/cha-prata-41.webp';
import cha42 from '../assets/images/cpg-gallery/cha-prata/cha-prata-42.webp';
import cha43 from '../assets/images/cpg-gallery/cha-prata/cha-prata-43.webp';
import cha44 from '../assets/images/cpg-gallery/cha-prata/cha-prata-44.webp';
import cha45 from '../assets/images/cpg-gallery/cha-prata/cha-prata-45.webp';
import cha46 from '../assets/images/cpg-gallery/cha-prata/cha-prata-46.webp';
import cha47 from '../assets/images/cpg-gallery/cha-prata/cha-prata-47.webp';
import cha48 from '../assets/images/cpg-gallery/cha-prata/cha-prata-48.webp';
import cha49 from '../assets/images/cpg-gallery/cha-prata/cha-prata-49.webp';
import cha50 from '../assets/images/cpg-gallery/cha-prata/cha-prata-50.webp';
import cha51 from '../assets/images/cpg-gallery/cha-prata/cha-prata-51.webp';
import cha52 from '../assets/images/cpg-gallery/cha-prata/cha-prata-52.webp';
import cha53 from '../assets/images/cpg-gallery/cha-prata/cha-prata-53.webp';
import cha54 from '../assets/images/cpg-gallery/cha-prata/cha-prata-54.webp';
import cha55 from '../assets/images/cpg-gallery/cha-prata/cha-prata-55.webp';
import cha56 from '../assets/images/cpg-gallery/cha-prata/cha-prata-56.webp';
import cha57 from '../assets/images/cpg-gallery/cha-prata/cha-prata-57.webp';
import cha58 from '../assets/images/cpg-gallery/cha-prata/cha-prata-58.webp';
import cha59 from '../assets/images/cpg-gallery/cha-prata/cha-prata-59.webp';
import cha60 from '../assets/images/cpg-gallery/cha-prata/cha-prata-60.webp';
import cha61 from '../assets/images/cpg-gallery/cha-prata/cha-prata-61.webp';
import cha62 from '../assets/images/cpg-gallery/cha-prata/cha-prata-62.webp';
import cha63 from '../assets/images/cpg-gallery/cha-prata/cha-prata-63.webp';
import cha64 from '../assets/images/cpg-gallery/cha-prata/cha-prata-64.webp';
import cha65 from '../assets/images/cpg-gallery/cha-prata/cha-prata-65.webp';
import cha66 from '../assets/images/cpg-gallery/cha-prata/cha-prata-66.webp';
import cha67 from '../assets/images/cpg-gallery/cha-prata/cha-prata-67.webp';
import cha68 from '../assets/images/cpg-gallery/cha-prata/cha-prata-68.webp';
import cha69 from '../assets/images/cpg-gallery/cha-prata/cha-prata-69.webp';
import cha70 from '../assets/images/cpg-gallery/cha-prata/cha-prata-70.webp';
import cha71 from '../assets/images/cpg-gallery/cha-prata/cha-prata-71.webp';
import cha72 from '../assets/images/cpg-gallery/cha-prata/cha-prata-72.webp';
import cha73 from '../assets/images/cpg-gallery/cha-prata/cha-prata-73.webp';
import cha74 from '../assets/images/cpg-gallery/cha-prata/cha-prata-74.webp';
import cha75 from '../assets/images/cpg-gallery/cha-prata/cha-prata-75.webp';
import cha76 from '../assets/images/cpg-gallery/cha-prata/cha-prata-76.webp';
import cha77 from '../assets/images/cpg-gallery/cha-prata/cha-prata-77.webp';
import cha78 from '../assets/images/cpg-gallery/cha-prata/cha-prata-78.webp';
import cha79 from '../assets/images/cpg-gallery/cha-prata/cha-prata-79.webp';
import cha80 from '../assets/images/cpg-gallery/cha-prata/cha-prata-80.webp';
import cha81 from '../assets/images/cpg-gallery/cha-prata/cha-prata-81.webp';
import cha82 from '../assets/images/cpg-gallery/cha-prata/cha-prata-82.webp';
import cha83 from '../assets/images/cpg-gallery/cha-prata/cha-prata-83.webp';
import cha84 from '../assets/images/cpg-gallery/cha-prata/cha-prata-84.webp';
import cha85 from '../assets/images/cpg-gallery/cha-prata/cha-prata-85.webp';
import cha86 from '../assets/images/cpg-gallery/cha-prata/cha-prata-86.webp';
import cha87 from '../assets/images/cpg-gallery/cha-prata/cha-prata-87.webp';
import cha88 from '../assets/images/cpg-gallery/cha-prata/cha-prata-88.webp';
import cha89 from '../assets/images/cpg-gallery/cha-prata/cha-prata-89.webp';
import cha90 from '../assets/images/cpg-gallery/cha-prata/cha-prata-90.webp';
import cha91 from '../assets/images/cpg-gallery/cha-prata/cha-prata-91.webp';
import cha92 from '../assets/images/cpg-gallery/cha-prata/cha-prata-92.webp';
import cha93 from '../assets/images/cpg-gallery/cha-prata/cha-prata-93.webp';
import cha94 from '../assets/images/cpg-gallery/cha-prata/cha-prata-94.webp';
import cha95 from '../assets/images/cpg-gallery/cha-prata/cha-prata-95.webp';
import cha96 from '../assets/images/cpg-gallery/cha-prata/cha-prata-96.webp';
import cha97 from '../assets/images/cpg-gallery/cha-prata/cha-prata-97.webp';
import cha98 from '../assets/images/cpg-gallery/cha-prata/cha-prata-98.webp';
import cha99 from '../assets/images/cpg-gallery/cha-prata/cha-prata-99.webp';
import cha100 from '../assets/images/cpg-gallery/cha-prata/cha-prata-100.webp';
import cha101 from '../assets/images/cpg-gallery/cha-prata/cha-prata-101.webp';
import cha102 from '../assets/images/cpg-gallery/cha-prata/cha-prata-102.webp';
import cha103 from '../assets/images/cpg-gallery/cha-prata/cha-prata-103.webp';
import cha104 from '../assets/images/cpg-gallery/cha-prata/cha-prata-104.webp';
import cha105 from '../assets/images/cpg-gallery/cha-prata/cha-prata-105.webp';
import cha106 from '../assets/images/cpg-gallery/cha-prata/cha-prata-106.webp';
import cha107 from '../assets/images/cpg-gallery/cha-prata/cha-prata-107.webp';
import cha108 from '../assets/images/cpg-gallery/cha-prata/cha-prata-108.webp';
import cha109 from '../assets/images/cpg-gallery/cha-prata/cha-prata-109.webp';
import cha110 from '../assets/images/cpg-gallery/cha-prata/cha-prata-110.webp';
import cha111 from '../assets/images/cpg-gallery/cha-prata/cha-prata-111.webp';
import cha112 from '../assets/images/cpg-gallery/cha-prata/cha-prata-112.webp';
import cha113 from '../assets/images/cpg-gallery/cha-prata/cha-prata-113.webp';
import cha114 from '../assets/images/cpg-gallery/cha-prata/cha-prata-114.webp';
import cha115 from '../assets/images/cpg-gallery/cha-prata/cha-prata-115.webp';
import cha116 from '../assets/images/cpg-gallery/cha-prata/cha-prata-116.webp';
import cha117 from '../assets/images/cpg-gallery/cha-prata/cha-prata-117.webp';
import cha118 from '../assets/images/cpg-gallery/cha-prata/cha-prata-118.webp';
import cha119 from '../assets/images/cpg-gallery/cha-prata/cha-prata-119.webp';
import cha120 from '../assets/images/cpg-gallery/cha-prata/cha-prata-120.webp';
import cha121 from '../assets/images/cpg-gallery/cha-prata/cha-prata-121.webp';
import cha122 from '../assets/images/cpg-gallery/cha-prata/cha-prata-122.webp';
import cha123 from '../assets/images/cpg-gallery/cha-prata/cha-prata-123.webp';

import ritmo1 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-1.webp';
import ritmo2 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-2.webp';
import ritmo3 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-3.webp';
import ritmo4 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-4.webp';
import ritmo5 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-5.webp';
import ritmo6 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-6.webp';
import ritmo7 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-7.webp';
import ritmo8 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-8.webp';
import ritmo9 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-9.webp';
import ritmo10 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-10.webp';
import ritmo11 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-11.webp';
import ritmo12 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-12.webp';
import ritmo13 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-13.webp';
import ritmo14 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-14.webp';
import ritmo15 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-15.webp';
import ritmo16 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-16.webp';
import ritmo17 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-17.webp';
import ritmo18 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-18.webp';
import ritmo19 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-19.webp';
import ritmo20 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-20.webp';
import ritmo21 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-21.webp';
import ritmo22 from '../assets/images/cpg-gallery/ritmos-e-zumba/ritmos-22.webp';

import fantasia1 from '../assets/images/cpg-gallery/fantasia/fantasia-1.webp';
import fantasia2 from '../assets/images/cpg-gallery/fantasia/fantasia-2.webp';
import fantasia3 from '../assets/images/cpg-gallery/fantasia/fantasia-3.webp';
import fantasia4 from '../assets/images/cpg-gallery/fantasia/fantasia-4.webp';
import fantasia5 from '../assets/images/cpg-gallery/fantasia/fantasia-5.webp';
import fantasia6 from '../assets/images/cpg-gallery/fantasia/fantasia-6.webp';
import fantasia7 from '../assets/images/cpg-gallery/fantasia/fantasia-7.webp';
import fantasia8 from '../assets/images/cpg-gallery/fantasia/fantasia-8.webp';
import fantasia9 from '../assets/images/cpg-gallery/fantasia/fantasia-9.webp';
import fantasia10 from '../assets/images/cpg-gallery/fantasia/fantasia-10.webp';
import fantasia11 from '../assets/images/cpg-gallery/fantasia/fantasia-11.webp';
import fantasia12 from '../assets/images/cpg-gallery/fantasia/fantasia-12.webp';
import fantasia13 from '../assets/images/cpg-gallery/fantasia/fantasia-13.webp';
import fantasia14 from '../assets/images/cpg-gallery/fantasia/fantasia-14.jpg';
import fantasia15 from '../assets/images/cpg-gallery/fantasia/fantasia-15.webp';
import fantasia16 from '../assets/images/cpg-gallery/fantasia/fantasia-16.jpg';
import fantasia17 from '../assets/images/cpg-gallery/fantasia/fantasia-17.webp';
import fantasia18 from '../assets/images/cpg-gallery/fantasia/fantasia-18.webp';
import fantasia19 from '../assets/images/cpg-gallery/fantasia/fantasia-19.webp';
import fantasia20 from '../assets/images/cpg-gallery/fantasia/fantasia-20.webp';
import fantasia21 from '../assets/images/cpg-gallery/fantasia/fantasia-21.webp';
import fantasia22 from '../assets/images/cpg-gallery/fantasia/fantasia-22.webp';
import fantasia23 from '../assets/images/cpg-gallery/fantasia/fantasia-23.webp';
import fantasia24 from '../assets/images/cpg-gallery/fantasia/fantasia-24.webp';
import fantasia25 from '../assets/images/cpg-gallery/fantasia/fantasia-25.webp';
import fantasia26 from '../assets/images/cpg-gallery/fantasia/fantasia-26.webp';
import fantasia27 from '../assets/images/cpg-gallery/fantasia/fantasia-27.webp';
import fantasia28 from '../assets/images/cpg-gallery/fantasia/fantasia-28.webp';
import fantasia29 from '../assets/images/cpg-gallery/fantasia/fantasia-29.webp';
import fantasia30 from '../assets/images/cpg-gallery/fantasia/fantasia-30.webp';
import fantasia31 from '../assets/images/cpg-gallery/fantasia/fantasia-31.webp';
import fantasia32 from '../assets/images/cpg-gallery/fantasia/fantasia-32.webp';
import fantasia33 from '../assets/images/cpg-gallery/fantasia/fantasia-33.webp';
import fantasia34 from '../assets/images/cpg-gallery/fantasia/fantasia-34.webp';
import fantasia35 from '../assets/images/cpg-gallery/fantasia/fantasia-35.webp';
import fantasia36 from '../assets/images/cpg-gallery/fantasia/fantasia-36.webp';
import fantasia37 from '../assets/images/cpg-gallery/fantasia/fantasia-37.webp';
import fantasia38 from '../assets/images/cpg-gallery/fantasia/fantasia-38.webp';
import fantasia39 from '../assets/images/cpg-gallery/fantasia/fantasia-39.webp';
import fantasia40 from '../assets/images/cpg-gallery/fantasia/fantasia-40.webp';
import fantasia41 from '../assets/images/cpg-gallery/fantasia/fantasia-41.webp';
import fantasia42 from '../assets/images/cpg-gallery/fantasia/fantasia-42.jpg';
import fantasia43 from '../assets/images/cpg-gallery/fantasia/fantasia-43.webp';
import fantasia44 from '../assets/images/cpg-gallery/fantasia/fantasia-44.webp';
import fantasia45 from '../assets/images/cpg-gallery/fantasia/fantasia-45.webp';
import fantasia46 from '../assets/images/cpg-gallery/fantasia/fantasia-46.webp';
import fantasia47 from '../assets/images/cpg-gallery/fantasia/fantasia-47.webp';
import fantasia48 from '../assets/images/cpg-gallery/fantasia/fantasia-48.webp';
import fantasia49 from '../assets/images/cpg-gallery/fantasia/fantasia-49.webp';
import fantasia50 from '../assets/images/cpg-gallery/fantasia/fantasia-50.webp';
import fantasia51 from '../assets/images/cpg-gallery/fantasia/fantasia-51.jpg';
import fantasia52 from '../assets/images/cpg-gallery/fantasia/fantasia-52.webp';
import fantasia53 from '../assets/images/cpg-gallery/fantasia/fantasia-53.webp';
import fantasia54 from '../assets/images/cpg-gallery/fantasia/fantasia-54.webp';
import fantasia55 from '../assets/images/cpg-gallery/fantasia/fantasia-55.webp';
import fantasia56 from '../assets/images/cpg-gallery/fantasia/fantasia-56.webp';
import fantasia57 from '../assets/images/cpg-gallery/fantasia/fantasia-57.webp';
import fantasia58 from '../assets/images/cpg-gallery/fantasia/fantasia-58.webp';
import fantasia59 from '../assets/images/cpg-gallery/fantasia/fantasia-59.webp';
import fantasia60 from '../assets/images/cpg-gallery/fantasia/fantasia-60.webp';
import fantasia61 from '../assets/images/cpg-gallery/fantasia/fantasia-61.webp';
import fantasia62 from '../assets/images/cpg-gallery/fantasia/fantasia-62.webp';
import fantasia63 from '../assets/images/cpg-gallery/fantasia/fantasia-63.webp';
import fantasia64 from '../assets/images/cpg-gallery/fantasia/fantasia-64.webp';
import fantasia65 from '../assets/images/cpg-gallery/fantasia/fantasia-65.jpg';
import fantasia66 from '../assets/images/cpg-gallery/fantasia/fantasia-66.webp';
import fantasia67 from '../assets/images/cpg-gallery/fantasia/fantasia-67.webp';
import fantasia68 from '../assets/images/cpg-gallery/fantasia/fantasia-68.webp';
import fantasia69 from '../assets/images/cpg-gallery/fantasia/fantasia-69.webp';
import fantasia70 from '../assets/images/cpg-gallery/fantasia/fantasia-70.webp';
import fantasia71 from '../assets/images/cpg-gallery/fantasia/fantasia-71.webp';
import fantasia72 from '../assets/images/cpg-gallery/fantasia/fantasia-72.webp';
import fantasia73 from '../assets/images/cpg-gallery/fantasia/fantasia-73.webp';
import fantasia74 from '../assets/images/cpg-gallery/fantasia/fantasia-74.webp';
import fantasia75 from '../assets/images/cpg-gallery/fantasia/fantasia-75.webp';
import fantasia76 from '../assets/images/cpg-gallery/fantasia/fantasia-76.webp';
import fantasia77 from '../assets/images/cpg-gallery/fantasia/fantasia-77.webp';
import fantasia78 from '../assets/images/cpg-gallery/fantasia/fantasia-78.webp';
import fantasia79 from '../assets/images/cpg-gallery/fantasia/fantasia-79.webp';
import fantasia80 from '../assets/images/cpg-gallery/fantasia/fantasia-80.webp';
import fantasia81 from '../assets/images/cpg-gallery/fantasia/fantasia-81.webp';
import fantasia82 from '../assets/images/cpg-gallery/fantasia/fantasia-82.webp';
import fantasia83 from '../assets/images/cpg-gallery/fantasia/fantasia-83.webp';
import fantasia84 from '../assets/images/cpg-gallery/fantasia/fantasia-84.jpg';
import fantasia85 from '../assets/images/cpg-gallery/fantasia/fantasia-85.webp';
import fantasia86 from '../assets/images/cpg-gallery/fantasia/fantasia-86.webp';
import fantasia87 from '../assets/images/cpg-gallery/fantasia/fantasia-87.webp';
import fantasia88 from '../assets/images/cpg-gallery/fantasia/fantasia-88.webp';
import fantasia89 from '../assets/images/cpg-gallery/fantasia/fantasia-89.webp';
import fantasia90 from '../assets/images/cpg-gallery/fantasia/fantasia-90.webp';
import fantasia91 from '../assets/images/cpg-gallery/fantasia/fantasia-91.webp';
import fantasia92 from '../assets/images/cpg-gallery/fantasia/fantasia-92.webp';
import fantasia93 from '../assets/images/cpg-gallery/fantasia/fantasia-93.webp';
import fantasia94 from '../assets/images/cpg-gallery/fantasia/fantasia-94.webp';
import fantasia95 from '../assets/images/cpg-gallery/fantasia/fantasia-95.webp';
import fantasia96 from '../assets/images/cpg-gallery/fantasia/fantasia-96.webp';
import fantasia97 from '../assets/images/cpg-gallery/fantasia/fantasia-97.webp';
import fantasia98 from '../assets/images/cpg-gallery/fantasia/fantasia-98.webp';
import fantasia99 from '../assets/images/cpg-gallery/fantasia/fantasia-99.webp';
import fantasia100 from '../assets/images/cpg-gallery/fantasia/fantasia-100.webp';
import fantasia101 from '../assets/images/cpg-gallery/fantasia/fantasia-101.webp';
import fantasia102 from '../assets/images/cpg-gallery/fantasia/fantasia-102.webp';
import fantasia103 from '../assets/images/cpg-gallery/fantasia/fantasia-103.webp';
import fantasia104 from '../assets/images/cpg-gallery/fantasia/fantasia-104.webp';
import fantasia105 from '../assets/images/cpg-gallery/fantasia/fantasia-105.webp';
import fantasia106 from '../assets/images/cpg-gallery/fantasia/fantasia-106.webp';
import fantasia107 from '../assets/images/cpg-gallery/fantasia/fantasia-107.webp';
import fantasia108 from '../assets/images/cpg-gallery/fantasia/fantasia-108.webp';
import fantasia109 from '../assets/images/cpg-gallery/fantasia/fantasia-109.webp';
import fantasia110 from '../assets/images/cpg-gallery/fantasia/fantasia-110.webp';
import fantasia111 from '../assets/images/cpg-gallery/fantasia/fantasia-111.webp';
import fantasia112 from '../assets/images/cpg-gallery/fantasia/fantasia-112.webp';
import fantasia113 from '../assets/images/cpg-gallery/fantasia/fantasia-113.webp';
import fantasia114 from '../assets/images/cpg-gallery/fantasia/fantasia-114.webp';
import fantasia115 from '../assets/images/cpg-gallery/fantasia/fantasia-115.webp';
import fantasia116 from '../assets/images/cpg-gallery/fantasia/fantasia-116.webp';
import fantasia117 from '../assets/images/cpg-gallery/fantasia/fantasia-117.webp';
import fantasia118 from '../assets/images/cpg-gallery/fantasia/fantasia-118.webp';
import fantasia119 from '../assets/images/cpg-gallery/fantasia/fantasia-119.webp';
import fantasia120 from '../assets/images/cpg-gallery/fantasia/fantasia-120.webp';
import fantasia121 from '../assets/images/cpg-gallery/fantasia/fantasia-121.webp';
import fantasia122 from '../assets/images/cpg-gallery/fantasia/fantasia-122.webp';
import fantasia123 from '../assets/images/cpg-gallery/fantasia/fantasia-123.webp';
import fantasia124 from '../assets/images/cpg-gallery/fantasia/fantasia-124.jpg';
import fantasia125 from '../assets/images/cpg-gallery/fantasia/fantasia-125.webp';
import fantasia126 from '../assets/images/cpg-gallery/fantasia/fantasia-126.webp';
import fantasia127 from '../assets/images/cpg-gallery/fantasia/fantasia-127.webp';
import fantasia128 from '../assets/images/cpg-gallery/fantasia/fantasia-128.webp';
import fantasia129 from '../assets/images/cpg-gallery/fantasia/fantasia-129.webp';
import fantasia130 from '../assets/images/cpg-gallery/fantasia/fantasia-130.webp';
import fantasia131 from '../assets/images/cpg-gallery/fantasia/fantasia-131.webp';
import fantasia132 from '../assets/images/cpg-gallery/fantasia/fantasia-132.webp';
import fantasia133 from '../assets/images/cpg-gallery/fantasia/fantasia-133.webp';
import fantasia134 from '../assets/images/cpg-gallery/fantasia/fantasia-134.webp';
import fantasia135 from '../assets/images/cpg-gallery/fantasia/fantasia-135.webp';
import fantasia136 from '../assets/images/cpg-gallery/fantasia/fantasia-136.webp';
import fantasia137 from '../assets/images/cpg-gallery/fantasia/fantasia-137.webp';

import festaDisco1 from '../assets/images/cpg-gallery/festa-disco/festa-disco-1.webp';
import festaDisco2 from '../assets/images/cpg-gallery/festa-disco/festa-disco-2.webp';
import festaDisco3 from '../assets/images/cpg-gallery/festa-disco/festa-disco-3.webp';
import festaDisco4 from '../assets/images/cpg-gallery/festa-disco/festa-disco-4.webp';
import festaDisco5 from '../assets/images/cpg-gallery/festa-disco/festa-disco-5.webp';
import festaDisco6 from '../assets/images/cpg-gallery/festa-disco/festa-disco-6.webp';
import festaDisco7 from '../assets/images/cpg-gallery/festa-disco/festa-disco-7.webp';
import festaDisco8 from '../assets/images/cpg-gallery/festa-disco/festa-disco-8.webp';
import festaDisco9 from '../assets/images/cpg-gallery/festa-disco/festa-disco-9.webp';
import festaDisco10 from '../assets/images/cpg-gallery/festa-disco/festa-disco-10.webp';
import festaDisco11 from '../assets/images/cpg-gallery/festa-disco/festa-disco-11.webp';
import festaDisco12 from '../assets/images/cpg-gallery/festa-disco/festa-disco-12.webp';
import festaDisco13 from '../assets/images/cpg-gallery/festa-disco/festa-disco-13.webp';
import festaDisco14 from '../assets/images/cpg-gallery/festa-disco/festa-disco-14.webp';
import festaDisco15 from '../assets/images/cpg-gallery/festa-disco/festa-disco-15.webp';
import festaDisco16 from '../assets/images/cpg-gallery/festa-disco/festa-disco-16.webp';
import festaDisco17 from '../assets/images/cpg-gallery/festa-disco/festa-disco-17.webp';
import festaDisco18 from '../assets/images/cpg-gallery/festa-disco/festa-disco-18.webp';
import festaDisco19 from '../assets/images/cpg-gallery/festa-disco/festa-disco-19.webp';
import festaDisco20 from '../assets/images/cpg-gallery/festa-disco/festa-disco-20.webp';
import festaDisco21 from '../assets/images/cpg-gallery/festa-disco/festa-disco-21.webp';
import festaDisco22 from '../assets/images/cpg-gallery/festa-disco/festa-disco-22.webp';
import festaDisco23 from '../assets/images/cpg-gallery/festa-disco/festa-disco-23.webp';
import festaDisco24 from '../assets/images/cpg-gallery/festa-disco/festa-disco-24.webp';
import festaDisco25 from '../assets/images/cpg-gallery/festa-disco/festa-disco-25.webp';
import festaDisco26 from '../assets/images/cpg-gallery/festa-disco/festa-disco-26.webp';
import festaDisco27 from '../assets/images/cpg-gallery/festa-disco/festa-disco-27.webp';
import festaDisco28 from '../assets/images/cpg-gallery/festa-disco/festa-disco-28.webp';
import festaDisco29 from '../assets/images/cpg-gallery/festa-disco/festa-disco-29.webp';
import festaDisco30 from '../assets/images/cpg-gallery/festa-disco/festa-disco-30.webp';
import festaDisco31 from '../assets/images/cpg-gallery/festa-disco/festa-disco-31.webp';
import festaDisco32 from '../assets/images/cpg-gallery/festa-disco/festa-disco-32.webp';
import festaDisco33 from '../assets/images/cpg-gallery/festa-disco/festa-disco-33.webp';
import festaDisco34 from '../assets/images/cpg-gallery/festa-disco/festa-disco-34.webp';
import festaDisco35 from '../assets/images/cpg-gallery/festa-disco/festa-disco-35.webp';
import festaDisco36 from '../assets/images/cpg-gallery/festa-disco/festa-disco-36.webp';

import farroupilha1 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-1.webp';
import farroupilha2 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-2.webp';
import farroupilha3 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-3.webp';
import farroupilha4 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-4.webp';
import farroupilha5 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-5.webp';
import farroupilha6 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-6.webp';
import farroupilha7 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-7.webp';
import farroupilha8 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-8.webp';
import farroupilha9 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-9.webp';
import farroupilha10 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-10.webp';
import farroupilha11 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-11.webp';
import farroupilha12 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-12.webp';
import farroupilha13 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-13.webp';
import farroupilha14 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-14.webp';
import farroupilha15 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-15.webp';
import farroupilha16 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-16.webp';
import farroupilha17 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-17.webp';
import farroupilha18 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-18.webp';
import farroupilha19 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-19.webp';
import farroupilha20 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-20.webp';
import farroupilha21 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-21.webp';
import farroupilha22 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-22.webp';
import farroupilha23 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-23.webp';
import farroupilha24 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-24.webp';
import farroupilha25 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-25.webp';
import farroupilha26 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-26.webp';
import farroupilha27 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-27.webp';
import farroupilha28 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-28.webp';
import farroupilha29 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-29.webp';
import farroupilha30 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-30.webp';
import farroupilha31 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-31.webp';
import farroupilha32 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-32.webp';
import farroupilha33 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-33.webp';
import farroupilha34 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-34.webp';
import farroupilha35 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-35.webp';
import farroupilha36 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-36.webp';
import farroupilha37 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-37.webp';
import farroupilha38 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-38.webp';
import farroupilha39 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-39.webp';
import farroupilha40 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-40.webp';
import farroupilha41 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-41.webp';
import farroupilha42 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-42.webp';
import farroupilha43 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-43.webp';
import farroupilha44 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-44.webp';
import farroupilha45 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-45.webp';
import farroupilha46 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-46.webp';
import farroupilha47 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-47.webp';
import farroupilha48 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-48.webp';
import farroupilha49 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-49.webp';
import farroupilha50 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-50.webp';
import farroupilha51 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-51.webp';
import farroupilha52 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-52.webp';
import farroupilha53 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-53.webp';
import farroupilha54 from '../assets/images/cpg-gallery/farroupilhas/farroupilha-54.webp';

import yoga1 from '../assets/images/cpg-gallery/yoga/yoga-1.webp';
import yoga2 from '../assets/images/cpg-gallery/yoga/yoga-2.webp';

import visita1 from '../assets/images/cpg-gallery/visita/visita1.webp';
import visita2 from '../assets/images/cpg-gallery/visita/visita2.webp';

import aniversario1 from '../assets/images/cpg-gallery/aniversario/aniversario1.webp';
import aniversario2 from '../assets/images/cpg-gallery/aniversario/aniversario2.webp';

import volei1 from '../assets/images/cpg-gallery/voleyball/volei-1.webp';
import volei2 from '../assets/images/cpg-gallery/voleyball/volei-2.webp';

import desafio1 from '../assets/images/cpg-gallery/desafio-futebol/desafio1.webp';
import desafio2 from '../assets/images/cpg-gallery/desafio-futebol/desafio2.webp';

export const cpg_gallery_list = [
    {
        id: 1,
        title: "CHÁ PRATA",
        images: [
            {
                url: cha1,
            },
            {
                url: cha2,
            },
            {
                url: cha3,
            },
            {
                url: cha4,
            },
            {
                url: cha5,
            },
            {
                url: cha6,
            },
            {
                url: cha7,
            },
            {
                url: cha8,
            },
            {
                url: cha9,
            },
            {
                url: cha10,
            },
            {
                url: cha11,
            },
            {
                url: cha12,
            },
            {
                url: cha13,
            },
            {
                url: cha14,
            },
            {
                url: cha15,
            },
            {
                url: cha16,
            },
            {
                url: cha17,
            },
            {
                url: cha18,
            },
            {
                url: cha19,
            },
            {
                url: cha20,
            },
            {
                url: cha21,
            },
            {
                url: cha22,
            },
            {
                url: cha23,
            },
            {
                url: cha24,
            },
            {
                url: cha25,
            },
            {
                url: cha26,
            },
            {
                url: cha27,
            },
            {
                url: cha28,
            },
            {
                url: cha29,
            },
            {
                url: cha30,
            },
            {
                url: cha31,
            },
            {
                url: cha32,
            },
            {
                url: cha33,
            }
            ,
            {
                url: cha34,
            },
            {
                url: cha35,
            },
            {
                url: cha36,
            },
            {
                url: cha37,
            },
            {
                url: cha38,
            },
            {
                url: cha39,
            },
            {
                url: cha40,
            },
            {
                url: cha41,
            },
            {
                url: cha42,
            },
            {
                url: cha43,
            },
            {
                url: cha44,
            },
            {
                url: cha45,
            },
            {
                url: cha46,
            },
            {
                url: cha47,
            },
            {
                url: cha48,
            },
            {
                url: cha49,
            },
            {
                url: cha50,
            },
            {
                url: cha51,
            },
            {
                url: cha52,
            },
            {
                url: cha53,
            },
            {
                url: cha54,
            },
            {
                url: cha55,
            },
            {
                url: cha56,
            },
            {
                url: cha57,
            },
            {
                url: cha58,
            },
            {
                url: cha59,
            },
            {
                url: cha60,
            },
            {
                url: cha61,
            },
            {
                url: cha62,
            },
            {
                url: cha63,
            },
            {
                url: cha64,
            },
            {
                url: cha65,
            },
            {
                url: cha66,
            },
            {
                url: cha67,
            },
            {
                url: cha68,
            },
            {
                url: cha69,
            },
            {
                url: cha70,
            },
            {
                url: cha71,
            },
            {
                url: cha72,
            },
            {
                url: cha73,
            },
            {
                url: cha74,
            },
            {
                url: cha75,
            },
            {
                url: cha76,
            },
            {
                url: cha77,
            },
            {
                url: cha78,
            },
            {
                url: cha79,
            },
            {
                url: cha80,
            },
            {
                url: cha81,
            },
            {
                url: cha82,
            },
            {
                url: cha83,
            },
            {
                url: cha84,
            },
            {
                url: cha85,
            },
            {
                url: cha86,
            },
            {
                url: cha87,
            },
            {
                url: cha88,
            },
            {
                url: cha89,
            },
            {
                url: cha90,
            },
            {
                url: cha91,
            },
            {
                url: cha92,
            },
            {
                url: cha93,
            },
            {
                url: cha94,
            },
            {
                url: cha95,
            },
            {
                url: cha96,
            },
            {
                url: cha97,
            },
            {
                url: cha98,
            },
            {
                url: cha99,
            },
            {
                url: cha100,
            },
            {
                url: cha101,
            },
            {
                url: cha102,
            },
            {
                url: cha103,
            },
            {
                url: cha104,
            },
            {
                url: cha105,
            },
            {
                url: cha106,
            },
            {
                url: cha107,
            },
            {
                url: cha108,
            },
            {
                url: cha109,
            },
            {
                url: cha110,
            },
            {
                url: cha111,
            },
            {
                url: cha112,
            },
            {
                url: cha113,
            },
            {
                url: cha114,
            },
            {
                url: cha115,
            },
            {
                url: cha116,
            },
            {
                url: cha117,
            },
            {
                url: cha118,
            },
            {
                url: cha119,
            },
            {
                url: cha120,
            },
            {
                url: cha121,
            },
            {
                url: cha122,
            },
            {
                url: cha123,
            }
        ],
    },

    {
        id: 2,
        title: "AULÃO DE RITMOS E ZUMBA",
        images: [
            {
                url: ritmo1,
            },
            {
                url: ritmo2,
            },
            {
                url: ritmo3,
            },
            {
                url: ritmo4,
            },
            {
                url: ritmo5,
            },
            {
                url: ritmo6,
            },
            {
                url: ritmo7,
            },
            {
                url: ritmo8,
            },
            {
                url: ritmo9,
            },
            {
                url: ritmo10,
            },
            {
                url: ritmo11,
            },
            {
                url: ritmo12,
            },
            {
                url: ritmo13,
            },
            {
                url: ritmo14,
            },
            {
                url: ritmo15,
            },
            {
                url: ritmo16,
            },
            {
                url: ritmo17,
            },
            {
                url: ritmo18,
            },
            {
                url: ritmo19,
            },
            {
                url: ritmo20,
            },
            {
                url: ritmo21,
            },
            {
                url: ritmo22,
            },
        ],
    },

    {
        id: 3,
        title: "FESTA FANTASIA",
        images: [
            {
                url: fantasia1,
            },
            {
                url: fantasia2,
            },
            {
                url: fantasia3,
            },
            {
                url: fantasia4,
            },
            {
                url: fantasia5,
            },
            {
                url: fantasia6,
            },
            {
                url: fantasia7,
            },
            {
                url: fantasia8,
            },
            {
                url: fantasia9,
            },
            {
                url: fantasia10,
            },
            {
                url: fantasia11,
            },
            {
                url: fantasia12,
            },
            {
                url: fantasia13,
            },
            {
                url: fantasia14,
            },
            {
                url: fantasia15,
            },
            {
                url: fantasia16,
            },
            {
                url: fantasia17,
            },
            {
                url: fantasia18,
            },
            {
                url: fantasia19,
            },
            {
                url: fantasia20,
            },
            {
                url: fantasia21,
            },
            {
                url: fantasia22,
            },
            {
                url: fantasia23,
            },
            {
                url: fantasia24,
            },
            {
                url: fantasia25,
            },
            {
                url: fantasia26,
            },
            {
                url: fantasia27,
            },
            {
                url: fantasia28,
            },
            {
                url: fantasia29,
            },
            {
                url: fantasia30,
            },
            {
                url: fantasia31,
            },
            {
                url: fantasia32,
            },
            {
                url: fantasia33,
            },
            {
                url: fantasia34,
            },
            {
                url: fantasia35,
            },
            {
                url: fantasia36,
            },
            {
                url: fantasia37,
            },
            {
                url: fantasia38,
            },
            {
                url: fantasia39,
            },
            {
                url: fantasia40,
            },
            {
                url: fantasia41,
            },
            {
                url: fantasia42,
            },
            {
                url: fantasia43,
            },
            {
                url: fantasia44,
            },
            {
                url: fantasia45,
            },
            {
                url: fantasia46,
            },
            {
                url: fantasia47,
            },
            {
                url: fantasia48,
            },
            {
                url: fantasia49,
            },
            {
                url: fantasia50,
            },
            {
                url: fantasia51,
            },
            {
                url: fantasia52,
            },
            {
                url: fantasia53,
            },
            {
                url: fantasia54,
            },
            {
                url: fantasia55,
            },
            {
                url: fantasia56,
            },
            {
                url: fantasia57,
            },
            {
                url: fantasia58,
            },
            {
                url: fantasia59,
            },
            {
                url: fantasia60,
            },
            {
                url: fantasia61,
            },
            {
                url: fantasia62,
            },
            {
                url: fantasia63,
            },
            {
                url: fantasia64,
            },
            {
                url: fantasia65,
            },
            {
                url: fantasia66,
            },
            {
                url: fantasia67,
            },
            {
                url: fantasia68,
            },
            {
                url: fantasia69,
            },
            {
                url: fantasia70,
            },
            {
                url: fantasia71,
            },
            {
                url: fantasia72,
            },
            {
                url: fantasia73,
            },
            {
                url: fantasia74,
            },
            {
                url: fantasia75,
            },
            {
                url: fantasia76,
            },
            {
                url: fantasia77,
            },
            {
                url: fantasia78,
            },
            {
                url: fantasia79,
            },
            {
                url: fantasia80,
            },
            {
                url: fantasia81,
            },
            {
                url: fantasia82,
            },
            {
                url: fantasia83,
            },
            {
                url: fantasia84,
            },
            {
                url: fantasia85,
            },
            {
                url: fantasia86,
            },
            {
                url: fantasia87,
            },
            {
                url: fantasia88,
            },
            {
                url: fantasia89,
            },
            {
                url: fantasia90,
            },
            {
                url: fantasia91,
            },
            {
                url: fantasia92,
            },
            {
                url: fantasia93,
            },
            {
                url: fantasia94,
            },
            {
                url: fantasia95,
            },
            {
                url: fantasia96,
            },
            {
                url: fantasia97,
            },
            {
                url: fantasia98,
            },
            {
                url: fantasia99,
            },
            {
                url: fantasia100,
            },
            {
                url: fantasia101,
            },
            {
                url: fantasia102,
            },
            {
                url: fantasia103,
            },
            {
                url: fantasia104,
            },
            {
                url: fantasia105,
            },
            {
                url: fantasia106,
            },
            {
                url: fantasia107,
            },
            {
                url: fantasia108,
            },
            {
                url: fantasia109,
            },
            {
                url: fantasia110,
            },
            {
                url: fantasia111,
            },
            {
                url: fantasia112,
            },
            {
                url: fantasia113,
            },
            {
                url: fantasia114,
            },
            {
                url: fantasia115,
            },
            {
                url: fantasia116,
            },
            {
                url: fantasia117,
            },
            {
                url: fantasia118,
            },
            {
                url: fantasia119,
            },
            {
                url: fantasia120,
            },
            {
                url: fantasia121,
            },
            {
                url: fantasia122,
            },
            {
                url: fantasia123,
            },
            {
                url: fantasia124,
            },
            {
                url: fantasia125,
            },
            {
                url: fantasia126,
            },
            {
                url: fantasia127,
            },
            {
                url: fantasia128,
            },
            {
                url: fantasia129,
            },
            {
                url: fantasia130,
            },
            {
                url: fantasia131,
            },
            {
                url: fantasia132,
            },
            {
                url: fantasia133,
            },
            {
                url: fantasia134,
            },
            {
                url: fantasia135,
            },
            {
                url: fantasia136,
            },
            {
                url: fantasia137,
            }
        ],
    },

    {
        id: 4,
        title: "FESTA DISCO II EDIÇÃO",
        images: [
            {
                url: festaDisco1,
            },
            {
                url: festaDisco2,
            },
            {
                url: festaDisco3,
            },
            {
                url: festaDisco4,
            },
            {
                url: festaDisco5,
            },
            {
                url: festaDisco6,
            },
            {
                url: festaDisco7,
            },
            {
                url: festaDisco8,
            },
            {
                url: festaDisco9,
            },
            {
                url: festaDisco10,
            },
            {
                url: festaDisco11,
            },
            {
                url: festaDisco12,
            },
            {
                url: festaDisco13,
            },
            {
                url: festaDisco14,
            },
            {
                url: festaDisco15,
            },
            {
                url: festaDisco16,
            },
            {
                url: festaDisco17,
            },
            {
                url: festaDisco18,
            },
            {
                url: festaDisco19,
            },
            {
                url: festaDisco20,
            },
            {
                url: festaDisco21,
            },
            {
                url: festaDisco22,
            },
            {
                url: festaDisco23,
            },
            {
                url: festaDisco24,
            },
            {
                url: festaDisco25,
            },
            {
                url: festaDisco26,
            },
            {
                url: festaDisco27,
            },
            {
                url: festaDisco28,
            },
            {
                url: festaDisco29,
            },
            {
                url: festaDisco30,
            },
            {
                url: festaDisco31,
            },
            {
                url: festaDisco32,
            },
            {
                url: festaDisco33,
            },
            {
                url: festaDisco34,
            },
            {
                url: festaDisco35,
            },
            {
                url: festaDisco36,
            }
        ],
    },

    {
        id: 5,
        title: "ABERTURA DOS FESTEJOS FARROUPILHAS 2019",
        images: [
            {
                url: farroupilha1,
            },
            {
                url: farroupilha2,
            },
            {
                url: farroupilha3,
            },
            {
                url: farroupilha4,
            },
            {
                url: farroupilha5,
            },
            {
                url: farroupilha6,
            },
            {
                url: farroupilha7,
            },
            {
                url: farroupilha8,
            },
            {
                url: farroupilha9,
            },
            {
                url: farroupilha10,
            },
            {
                url: farroupilha11,
            },
            {
                url: farroupilha12,
            },
            {
                url: farroupilha13,
            },
            {
                url: farroupilha14,
            },
            {
                url: farroupilha15,
            },
            {
                url: farroupilha16,
            },
            {
                url: farroupilha17,
            },
            {
                url: farroupilha18,
            },
            {
                url: farroupilha19,
            },
            {
                url: farroupilha20,
            },
            {
                url: farroupilha21,
            },
            {
                url: farroupilha22,
            },
            {
                url: farroupilha23,
            },
            {
                url: farroupilha24,
            },
            {
                url: farroupilha25,
            },
            {
                url: farroupilha26,
            },
            {
                url: farroupilha27,
            },
            {
                url: farroupilha28,
            },
            {
                url: farroupilha29,
            },
            {
                url: farroupilha30,
            },
            {
                url: farroupilha31,
            },
            {
                url: farroupilha32,
            },
            {
                url: farroupilha33,
            },
            {
                url: farroupilha34,
            },
            {
                url: farroupilha35,
            },
            {
                url: farroupilha36,
            },
            {
                url: farroupilha37,
            },
            {
                url: farroupilha38,
            },
            {
                url: farroupilha39,
            },
            {
                url: farroupilha40,
            },
            {
                url: farroupilha41,
            },
            {
                url: farroupilha42,
            },
            {
                url: farroupilha43,
            },
            {
                url: farroupilha44,
            },
            {
                url: farroupilha45,
            },
            {
                url: farroupilha46,
            },
            {
                url: farroupilha47,
            },
            {
                url: farroupilha48,
            },
            {
                url: farroupilha49,
            },
            {
                url: farroupilha50,
            },
            {
                url: farroupilha51,
            },
            {
                url: farroupilha52,
            },
            {
                url: farroupilha53,
            },
            {
                url: farroupilha54,
            }
        ],
    },

    {
        id: 6,
        title: "AULÃO DE YOGA",
        images: [
            {
                url: yoga1,
            },
            {
                url: yoga2,
            }
        ],
    },

    {
        id: 7,
        title: "NORTE AMERICANOS DA FCA VISITAM O CPG",
        images: [
            {
                url: visita1,
            },
            {
                url: visita2,
            }
        ],
    },

    {
        id: 8,
        title: "CHÁ DE ANIVERSÁRIO DO CORAL",
        images: [
            {
                url: aniversario1,
            },
            {
                url: aniversario2,
            }
        ],
    },

    {
        id: 9,
        title: "FINAL METROPOLITANO CPG DE VOLEIBOL +35",
        images: [
            {
                url: volei1,
            },
            {
                url: volei2,
            }
        ],
    },

    {
        id: 10,
        title: "DESAFIO INTERNACIONAL E FUTEBOL 2019",
        images: [
            {
                url: desafio1,
            },
            {
                url: desafio2,
            }
        ],
    }
]

export default cpg_gallery_list;