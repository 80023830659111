import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import styles from '../../styles/global.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import api from '../../services/api';


const AboutTeamItem = ({ img, imgAlt, heading, position, openModal }) => {

    return (
        <>
            <div className={styles.centerCarroussel}>
                <div className="col-11">
                    <div className="single-team-style-2 text-center">
                        <div className="single-team-style-2-img">
                            <img onClick={() => openModal(img)} style={{ paddingBottom: "130px", cursor: "pointer" }} src={img} alt={imgAlt} />
                        </div>
                        <div className="single-team-content-style-2" style={{ backgroundColor: "transparent" }}>
                            <h4 style={{ color: "#222", fontSize: "16px", textTransform: "uppercase" }}>{heading}</h4>
                            <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}>{position}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CPGCarrousselSubmodalidades = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);

    // console.log("id que puxamos:", id);

    useEffect(() => {
        api.get('/submodalidades-esportivas-imagens.php')
            .then(res => {
                if (res.data.success) {
                    // console.log("data SUBMENUS ", res.data);

                    const filteredData = res.data.horarios.filter(item => item.id_modalidade === id);
                    setData(filteredData);
                    // setData(res.data.horarios || []);
                    console.log("data CARROSSEL  Submodalidades", res.data.horarios);
                    
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [id]);

    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Add state to track the current image

    const downloadImage = () => {
        if (currentImage) {
            const downloadLink = document.createElement('a');
            downloadLink.href = currentImage;
            downloadLink.download = 'imagem_cpg.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowDownloadButton();
        }
    }

    function openModal(img) {
        setCurrentImage(img); // Set the current image before opening the modal
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }


    var settings = {
        speed: 500,
        autoplay: false,
        slidesToShow: Math.min(3, data.length),
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className="team-area white-bg pt-20">
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row">
                        <Slider
                            {...settings}
                        >
                            {data.map((item, index) => (
                                <AboutTeamItem
                                    key={item.id_imagens_esportivas}
                                    img={`http://cpg.web15f86.uni5.net/admin/images/submodalidades-esportivas/${item.id_imagens_esportivas}.jpg`}
                                    openModal={openModal}
                                    downloadImage={downloadImage}
                                />
                            ))}
                        </Slider>
                        {
                            showModal && currentImage && (
                                <div className={styles.imageModal}>
                                    <img className={styles.zoomImageGallery} src={currentImage} alt="" />
                                    <button onClick={closeModal} title="Fechar" className={styles.closeModalGallery}><AiOutlineClose size={28} /></button>
                                    <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGCarrousselSubmodalidades;