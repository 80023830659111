import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const LocationCPGPinhal = () => {
    return (
        <>
            <div className="about-block pt-30 pb-110">
                <div>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>LOCALIZAÇÃO</h3><br />
                        </div>
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2709.02796102155!2d-50.245936056856785!3d-30.260490594973685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95181b93b4b0bf1f%3A0x7aa5d9ec41fb2b91!2sClube%20do%20Professor%20Ga%C3%BAcho%20Pinhal!5e0!3m2!1sen!2sbr!4v1696950091462!5m2!1sen!2sbr" width="100%" height="600"></iframe>
                        </div>
                        <div className={stylesHeadOffice.address}>
                            <div className={styles.aboutContent}>
                                <h3 className={stylesHeadOffice.titlePattern}>ENDEREÇO DA SEDE</h3>
                                <p className={stylesHeadOffice.textPattern}>
                                    RUA AGOSTINHO ROCHA, 1458<br />
                                    BALNEÁRIO PINHAL/RS<br />
                                    CEP 95599-000<br />
                                    FONE: <a href="tel:+555136822995">(51) 3682-2995</a>
                                </p><br />

                                <h3 className={stylesHeadOffice.titlePattern}>HORÁRIOS</h3>
                                <p className={stylesHeadOffice.textPattern}><strong>FUNCIONAMENTO DA SEDE – ATENDIMENTO ADMINISTRATIVO</strong><br />
                                    8h às 20h<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationCPGPinhal;