import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGLocalContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>LOCAL</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                A ginástica aeróbica ou ginástica localizada nada mais é do que série de exercícios
                                de ginástica com número elevado de repetições para grupos musculares distintos,
                                com o fim de os moldar. Consiste basicamente em sessões estruturadas de séries de
                                exercícios com número elevado de repetições para grupos musculares distintos, com
                                a finalidade de aprimorar: o tônus muscular, as capacidades aeróbias, anaeróbia,
                                flexibilidade ou o condicionamento físico como um todo.
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGLocalContent;