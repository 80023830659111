import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import principalImage from '../../assets/images/receive-news/grupo-whatsapp.png';
import qrcodeImage from '../../assets/images/receive-news/QRCODE.png';

const ReceiveNewsContent = () => {
    return (
        <>
            <div className="about-block pt-80 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.groupImageContent}>
                            <img src={principalImage} />
                        </div>
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>Bem-vindo(a) ao Nosso Clube!</h3>
                            <p className={styles.textPattern}>
                                Você já conhece o ‘’VEM Aí CPG”? Aqui, encontrará todas as informações , em
                                um só lugar. Queremos que esteja sempre atualizado(a) sobre tudo o que
                                acontece. Nossa equipe está dedicada em trazer as últimas notícias, eventos

                                sociais, esportivos, infraestrutura e muito mais!
                            </p><br />


                            <p className={styles.textPattern}>
                                Associado ou comunidade, este é o local ideal para ficar por dentro de tudo.
                                Queremos oferecer uma experiência completa, mantendo informado e

                                engajado com as novidades.
                            </p><br />

                            <p className={styles.textPattern}>
                                Então, para estar sempre por dentro de tudo o que acontece no seu clube,
                                navegue pelo nosso site, explore as seções, desfrute da experiência completa

                                e participe do nosso grupo do WhatsApp!
                            </p><br />

                            <p className={styles.textPattern}>
                                É muito simples! Leia o QR Code abaixo!
                            </p><br />

                            <div className="qrcode-content">
                                <img src={qrcodeImage} />
                            </div><br />

                            <p className={styles.textPattern}>
                                Estamos ansiosos para compartilhar todas as emoções e conquistas do seu clube

                                favorito. Junte-se a nós e não perca nenhum detalhe!
                            </p><br />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReceiveNewsContent;