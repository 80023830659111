import homeNews1 from '../assets/images/home/home_news1.webp';
import homeNews2 from '../assets/images/home/home_news2.webp';

export const cpg_social_news = [
    {
        id: 1,
        title: "ATIVIDADES E HORÁRIO DE FUNCIONAMENTO",
        subtitle: "ATIVIDADES E HORÁRIO DE FUNCIONAMENTO",
        // textContent: [
        //     {
        //         text: [
        //             "Na próxima segunda-feira, 14/09, às 20h, o CTG Laço da Querência, do Clube do Professor Gaúcho, irá promover uma uma Live em comemoração ao mês farroupilha."
        //         ],
        //     },
        //     {
        //         text: [
        //             "Além da presença dos músicos da casa, os quais irão comandar a Live, teremos a alegria de receber a Patrona dos Festejos Farroupilhas deste ano.",
        //             "Teremos muita dança, música, descontração e surpresas."
        //         ]
                
        //     },
        //     {
        //         text: ["A transmissão será na página do CTG no Facebook."],
        //     },
        // ],

        principalImage: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2021/09/BANNER-SITE_porto_alegre_horario-de-funcionamento.png?resize=750%2C400&ssl=1',

        infosImage: [
            'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/1.jpg?resize=619%2C876&ssl=1',
            'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/005B3F06-F3C2-4BEC-8C9C-4540365AB067.jpeg?resize=618%2C877&ssl=1',
            'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/D5060B4C-7D91-476E-950A-4874A51CFB6B.jpeg?resize=619%2C874&ssl=1',
            'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/8DB2EF0E-BC0C-4A8B-B17B-AD1D76F643DA.jpeg?resize=618%2C876&ssl=1',
        ],

        // link: "https://issuu.com/clubedoprofessorgaucho/docs/atividades_esportivas_2022_-_sem_valores_sem_cano",

        // iframe: "https://e.issuu.com/embed.html?identifier=x9k5cy7bq6xe&embedType=script&u=clubedoprofessorgaucho&d=atividades_esportivas_2022_-_sem_valores_sem_cano&p=1",
        
        // images: [
        //     {
        //         url: homeNews1,
        //     }
        // ],
    },
    {
        id: 2,
        title: "ATIVIDADES E HORÁRIO DE FUNCIONAMENTO",
        subtitle: "ATIVIDADES E HORÁRIO DE FUNCIONAMENTO",
        // textContent: [
        //     {
        //         text: ["Confira os principais acontecimentos das Sedes Porto Alegre e Santa Maria, nas áreas sociocultural, esporte e infraestrutura."],
        //     },
        //     {
        //         text: ["Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede."],
                
        //     },
        //     {
        //         text: ["Não esqueça de retirar o seu!"],
        //     },
        //     {
        //         text: ["Confira a versão digital! ?"],
        //     }
        // ],

        principalImage: "https://i2.wp.com/cpg.com.br/site/wp-content/uploads/2021/11/BANNER-SITE_porto_alegre_horario-de-funcionamento.png?resize=750%2C400&ssl=1",

        infosImage: [
            'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/1.jpg?resize=619%2C876&ssl=1',
            'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/005B3F06-F3C2-4BEC-8C9C-4540365AB067.jpeg?resize=618%2C877&ssl=1',
            'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/D5060B4C-7D91-476E-950A-4874A51CFB6B.jpeg?resize=619%2C874&ssl=1',
            'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2022/01/8DB2EF0E-BC0C-4A8B-B17B-AD1D76F643DA.jpeg?resize=618%2C876&ssl=1'
        ],

        images: [
            {
                url: homeNews2,
            }
        ],
    },
]