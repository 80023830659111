import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg from "../../assets/images/logo.jpg";

const Logo = () => {
    return (
        <div className="logo">
            <Link to="/">
                <img style={{ width: '8rem' }} src={LogoImg} alt="Logo" />
            </Link>
        </div>
    )
}

export default Logo;