import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import conexao1 from '../../assets/images/conexao-cpg/CONEXAO45-CAPA.jpg';
import conexao2 from '../../assets/images/conexao-cpg/CONEXAOED46-CAPA.jpg';
import conexao3 from '../../assets/images/conexao-cpg/CONEXAOED47-CAPA.jpg';
import conexao4 from '../../assets/images/conexao-cpg/CONEXAO44-CAPA.jpg';
import conexao5 from '../../assets/images/conexao-cpg/CONEXAO43CAPA.jpg';
import conexao6 from '../../assets/images/conexao-cpg/CONEXAO42CAPA.jpg';
import pdf1 from '../../assets/images/conexao-cpg/pdf-conexao-45.pdf';
import pdf2 from '../../assets/images/conexao-cpg/CONEXAO-46.pdf';
import pdf3 from '../../assets/images/conexao-cpg/CONEXAOED47.pdf';
import pdf4 from '../../assets/images/conexao-cpg/CONEXAO44.pdf';
import pdf5 from '../../assets/images/conexao-cpg/CONEXAO43.pdf';
import pdf6 from '../../assets/images/conexao-cpg/CONEXAO42.pdf';
// import Pagination from '../pagination/Pagination';
import styles from '../../styles/global.module.css';

const BlogStyleThreeData = [

    {
        id: uuidv4(),
        img: conexao3,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXAO47',
        content: '',
        date: '',
        author: '',
        btnLink: pdf3,
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: conexao2,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXÃO 46',
        content: '',
        date: '',
        author: '',
        btnLink: pdf2,
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: conexao1,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXÃO 45',
        content: '',
        date: '',
        author: '',
        btnLink: pdf1,
        btnText: 'SAIBA MAIS',
    },

    
    {
        id: uuidv4(),
        img: conexao4,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXÃO 44',
        content: '',
        date: '',
        author: '',
        btnLink: pdf4,
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: conexao5,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXÃO 43',
        content: '',
        date: '',
        author: '',
        btnLink: pdf5,
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: conexao6,
        parentCat: '',
        regularCat: '',
        heading: 'CONEXÃO 42',
        content: '',
        date: '',
        author: '',
        btnLink: pdf6,
        btnText: 'SAIBA MAIS',
    },
];

const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <a href={btnLink} target='_blank'>
                        <div className="blog-img">
                            <img src={img} alt="" />
                        </div>
                    </a>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><a href={btnLink} target='_blank'>{heading}</a></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <a href={btnLink} target='_blank' className="l-read-more" >{btnText}<FaLongArrowAltRight /></a>
                    </div>
                </div>

            </div>
        </>
    )
}

const ConexaoCPGContent = () => {
    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="section-title text-center mb-70">
                                <span>Latest News & Blog</span>
                                <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <Pagination />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ConexaoCPGContent;