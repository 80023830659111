import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const AboutCPGRevive = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <p className={stylesHeadOffice.textPattern}>
                                A história do Grupo iniciou-se em 20 de outubro de 1992, durante o tradicional Chá
                                Prata, nesta data, a professora Natércia dos Santos lançou a brilhante ideia para o
                                então presidente do Clube do Professor Gaúcho, Odalgir Lazzari que aceitou criar
                                um grupo de convivência, nomeando a professora Zoraida Souza para ser a
                                coordenadora, na semana seguinte o presidente ofereceu uma sala provisória para
                                os encontros que eram de 15 em 15 dias. Em 17 de novembro de 1992, no 3º encontro
                                do grupo começou uma campanha para escolher um nome para o grupo, algumas
                                sugestões foram dadas como: Andorinhas, Renascer ou Reviver. No verão do ano
                                seguinte o grupo se reuniu para decidir o nome do grupo e por unanimidade o
                                nome escolhido foi Reviver. Nos meados de 1996, a professora Alice Maria Raiher
                                passou a coordenar o grupo até 1 de junho de 2006, quando assume a
                                coordenadoria do grupo a professora Vera Maria Fernandes Borges. – “Tive a honra
                                de assumir sua coordenação em 2006 e criar, seis anos depois, um grupo de
                                danças para comemorar suas duas décadas: As Revivetes assim se mostra que,
                                mesmo na melhor idade, somos capazes de dançar e ser muito feliz”. Vera Borges.
                            </p>

                            <p className={stylesHeadOffice.textPattern}>
                                Hoje, o Reviver tornou-se uma verdadeira família de amigos, o grupo participa
                                ativamente das atividades propostas pelo clube, organiza chás beneficentes,
                                passeios, oficinas e tem sucesso absoluto em campanhas assistenciais. Podem

                                participar qualquer associado do Clube.
                            </p>

                            <p className={stylesHeadOffice.textPattern}>
                                Em 2023 a professora Doris Maria Louis Fogaça assumiu a coordenadoria do

                                departamento.
                            </p>
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>COORDENADOR</h4>
                                <p style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>Profª DÓRIS MARIA LOUIS FOGAÇA</p>
                                <p style={{ paddingBottom: "30px" }} className={styles.textPattern}></p>
                            </div>
                        </div>
                        <div className='pt-70'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>DIA</th>
                                        <th>HORÁRIO</th>
                                        <th>LOCAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Quarta-feira</td>
                                        <td>14 horas</td>
                                        <td>Sala Multiuso</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGRevive;