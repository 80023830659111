import { BsFillCheckCircleFill, BsPeopleFill, BsSnow, BsClockFill, BsFillChatFill } from 'react-icons/bs';
import { MdDirectionsCar } from 'react-icons/md';
import gourmetImg1 from '../../src/assets/images/gourmet-details_2.webp';
import gourmetImg2 from '../../src/assets/images/gourmet_details3.jpg';
import gourmetImg3 from '../../src/assets/images/gourmet-img_4webp.webp';
import gourmetImg4 from '../../src/assets/images/gourmet-details_1.jpg';
import salao1 from '../../src/assets/images/salaoimg_1.jpg';
import salao2 from '../../src/assets/images/salaoimg_2.webp';
import salao3 from '../../src/assets/images/salaoimg_4.webp';
import figueira1 from '../../src/assets/images/figueira_1.webp';
import figueira2 from '../../src/assets/images/figueira_2.webp';
import figueira3 from '../../src/assets/images/figueira_3.webp';
import figueira4 from '../../src/assets/images/figueira_4.webp';
import figueira5 from '../../src/assets/images/figueira_5.webp';

export const events_list = [
    {
        id: 1,
        title: "SALÃO GOURMET",
        subtitle: "O Salão Gourmet, compacto e aconchegante, pode acomodar até 30 pessoas sentadas. Está localizado no novo Espaço Multiartes para receber eventos de pequeno porte.",
        text: "Mais informações sobre as locações na Central de Atendimento ou 51 3248.5710 e através do e-mail atendimento@cpg.com.br.",
        images: [
            {
                url: gourmetImg1,
            },
            {
                url: gourmetImg2,
            },
            {
                url: gourmetImg3,
            },
            {
                url: gourmetImg4,
            }
        ],
        categorias: [
            {
                nome: 'ITENS INCLUÍDOS',
                icon: <BsFillCheckCircleFill size={25} />,
                itens: [
                    'Cozinha equipada com cooktop por indução e refrigeradora',
                    'Mesas e cadeiras'
                ]
            },
            {
                nome: 'CAPACIDADE',
                icon: <BsPeopleFill size={25} />,
                itens: [
                    '25 pessoas sentadas',
                ]
            },
            {
                nome: 'AR-CONDICIONADO',
                icon: <BsSnow size={25} />,
                itens: [
                    'Sim',
                ]
            },
            {
                nome: 'ESTACIONAMENTO',
                icon: <MdDirectionsCar size={30} />,
                itens: [
                    'Vagas para até 250 veículos, conforme disponibilidade.',
                ]
            },
            {
                nome: 'HORÁRIO LIMITE',
                icon: <BsClockFill size={25} />,
                itens: [
                    'Horário a combinar',
                ]
            },
            {
                nome: 'ENTRE EM CONTATO',
                icon: <BsFillChatFill size={25} />,
                itens: [
                    'Fale com a Central de Atendimento. 51 3248.5710 ou atendimento@cpg.com.br',
                ]
            },
        ]
    },
    {
        id: 2,
        title: "SALÃO THEREZA NORONHA",
        subtitle: "O Salão Thereza Noronha, aconchegante, pode receber até 280 pessoas, com vista para as piscinas e jardins internos, além de contar com palco para apresentações.",
        text: "Mais informações sobre as locações na Central de Atendimento ou 51 3248.5710 e através do e-mail atendimento@cpg.com.br.",
        images: [
            {
                url: salao1,
            },
            {
                url: salao2,
            },
            {
                url: salao3,
            },
        ],
        categorias: [
            {
                nome: 'ITENS INCLUÍDOS',
                icon: <BsFillCheckCircleFill size={25} />,
                itens: [
                    'Palco',
                    'Camarim',
                    'Mesas e cadeiras',
                    'Cozinha (com 1 fogão industrial a gás)',
                    '1 churrasqueira',
                    '1 refrigerador com 6 portas'
                ]
            },
            {
                nome: 'CAPACIDADE',
                icon: <BsPeopleFill size={25} />,
                itens: [
                    '280 pessoas sentadas',
                ]
            },
            {
                nome: 'AR-CONDICIONADO',
                icon: <BsSnow size={25} />,
                itens: [
                    'Sim',
                ]
            },
            {
                nome: 'ESTACIONAMENTO',
                icon: <MdDirectionsCar size={30} />,
                itens: [
                    'Vagas para até 250 veículos, conforme disponibilidade.',
                ]
            },
            {
                nome: 'HORÁRIO LIMITE',
                icon: <BsClockFill size={25} />,
                itens: [
                    'Até às 4h da manhã',
                ]
            },
            {
                nome: 'ENTRE EM CONTATO',
                icon: <BsFillChatFill size={25} />,
                itens: [
                    'Fale com a Central de Atendimento. 51 3248.5710 ou atendimento@cpg.com.br',
                ]
            },
        ]
    },
    {
        id: 3,
        title: "SALAO DA FIGUEIRA",
        subtitle: "Nosso Salão da Figueira dispõe de acomodações para, aproximadamente, 900 pessoas, além de possuir ar-condicionado, sua arquitetura é um convite ao embelezamento, tendo em sua lateral direita vista ampla para os jardins externos.",
        text: "Mais informações sobre as locações na Central de Atendimento ou 51 3248.5710 e através do e-mail atendimento@cpg.com.br.",
        images: [
            {
                url: figueira1,
            },
            {
                url: figueira3,
            },
            {
                url: figueira4,
            },
            {
                url: figueira5,
            },
        ],
        categorias: [
            {
                nome: 'ITENS INCLUÍDOS',
                icon: <BsFillCheckCircleFill size={25} />,
                itens: [
                    'Palco',
                    'Camarim',
                    'Mesas e cadeiras',
                    'Cozinha (com 1 fogão industrial a gás)',
                    '1 churrasqueira',
                    '1 refrigerador com 6 portas'
                ]
            },
            {
                nome: 'CAPACIDADE',
                icon: <BsPeopleFill size={25} />,
                itens: [
                    '900 pessoas sentadas',
                ]
            },
            {
                nome: 'AR-CONDICIONADO',
                icon: <BsSnow size={25} />,
                itens: [
                    'Sim',
                ]
            },
            {
                nome: 'ESTACIONAMENTO',
                icon: <MdDirectionsCar size={30} />,
                itens: [
                    'Vagas para até 250 veículos, conforme disponibilidade.',
                ]
            },
            {
                nome: 'HORÁRIO LIMITE',
                icon: <BsClockFill size={25} />,
                itens: [
                    'Até às 4h da manhã',
                ]
            },
            {
                nome: 'ENTRE EM CONTATO',
                icon: <BsFillChatFill size={25} />,
                itens: [
                    'Fale com a Central de Atendimento. 51 3248.5710 ou atendimento@cpg.com.br',
                ]
            },
        ]
    },
    {
        id: 4,
        title: "AQUISIÇÕES JULHO 2018",
        libraryContent: [
            {
                titleLibrary: "Pequenos Incêndios por Toda Parte – Celeste N. G. – ROMANCE NORTE-AMERICANO – 10843",
                textLibrary: "Um encontro entre duas famílias completamente diferentes vai afetar a vida de todos.Em Shaker Heights tudo é planejado: da localização das escolas à cor usada na pintura das casas. E ninguém se identifica mais com esse espírito organizado do que Elena Richardson.Mia Warren, uma artista solteira e enigmática, chega nessa bolha idílica com a filha adolescente e aluga uma casa que pertence aos Richardson. Em pouco tempo, as duas se tornam mais do que meras inquilinas: todos os quatro filhos da família Richardson se encantam com as novas moradoras de Shaker. Porém, Mia carrega um passado misterioso e um desprezo pelo status quo que ameaça desestruturar uma comunidade tão cuidadosamente ordenada.Eleito nos Estados Unidos um dos melhores livros de 2017 por veículos como Entertainment Weekly, The Guardian e The Washington Post, Pequenos incêndios por toda parte explora o peso dos segredos, a natureza da arte e o perigo de acreditar que simplesmente seguir as regras vai evitar todos os desastres.",
            },
            {
                titleLibrary: "Passageiro do Fim do Dia – Rubens Figueiredo – ROMANCE BRASILEIRO – 10835",
                textLibrary: "Este romance de escritura primorosa narra um percurso. É o que se opera na consciência de Pedro durante uma viagem de ônibus para o bairro do Tirol, na periferia pobre da cidade onde mora — uma espécie de panela de pressão de violência e injustiça sistemática. É lá que mora Rosane, namorada de Pedro: faz algum tempo que ele passa os fins de semana com ela.De radinho no ouvido, lendo a intervalos, observando o que se passa dentro do ônibus e fora nas ruas, Pedro, sem se dar conta, costura as ideias. Ao fim da viagem ele não será mais o mesmo: o que vê e pensa durante o trajeto, os fatos de sua vida, seus afetos, o mundo em que está imerso, tudo reunido terá formado um novo conhecimento, mais profundo e mais crítico, mas que nem por isso o deixará desprotegido numa sociedade em que parece não haver como fugir de um destino opressivo.O passageiro do fim do dia não deixa dúvida sobre a importância de Rubens Figueiredo no cenário literário contemporâneo no Brasil.",
            },
            {
                titleLibrary: "Tudo Aquilo que Eu Não Disse – Kathryn Hughes – ROMANCE INGLÊS – 10840",
                textLibrary: "A vida da doce Tina Craig parece estar destinada a mesmice dos anos 70: ela vive presa em um casamento infeliz com um marido problemático. Isso desafia Tina a unir todas as suas forças para sair desse abismo e finalmente conquistar a paz de espírito que ela tanto quer. Seu destino toma um rumo diferente quando ela encontra uma carta escrita em setembro de 1939. A carta, que nunca chegou ao destino certo, lhe traz uma nova esperança, um alento para o seu coração tão maltratado. Tudo muda de figura quando a vida de Tina se choca com os destinos do casal Billy e Chrissie, trazendo William, um jovem em busca de sua mãe biológica, para sua jornada por conta de um mero acaso.",
            },
            {
                titleLibrary: "A Mulher na Janela – A. J. Finn – SUSPENSE NORTE-AMERICANO – 10833",
                textLibrary: "Anna Fox mora sozinha na bela casa que um dia abrigou sua família feliz. Separada do marido e da filha e sofrendo de uma fobia que a mantém reclusa, ela passa os dias bebendo (muito) vinho, assistindo a filmes antigos, conversando com estranhos na internet e… espionando os vizinhos.Quando os Russells – pai, mãe e o filho adolescente – se mudam para a casa do outro lado do parque, Anna fica obcecada por aquela família perfeita. Até que certa noite, bisbilhotando através de sua câmera, ela vê na casa deles algo que a deixa aterrorizada e faz seu mundo – e seus segredos chocantes – começar a ruir.Mas será que o que testemunhou aconteceu mesmo? O que é realidade? O que é imaginação? Existe realmente alguém em perigo? E quem está no controle?Neste thriller diabolicamente viciante, ninguém – e nada – é o que parece. ‘A mulher na janela’ é um suspense psicológico engenhoso e comovente que remete ao melhor de Hitchcock.",
            },
            {
                titleLibrary: "Você – Caroline Kepnes – SUSPENSE NORTE-AMERICANO – 10838",
                textLibrary: "Bestseller do The New York Times, o romance de estreia de Caroline Kepnes ganhou elogios de escritores do calibre de Stephen King e Sophie Hannah, além de resenhas estreladas, e deu origem a uma série de TV homônima que estreia neste primeiro semestre nos EUA. Não é para menos. Hipnótico, assustador, brilhante são alguns dos adjetivos usados para descrever este thriller sobre um amor obsessivo e suas perigosas consequências. A trama tem início quando Guinevere Beck, que deseja ser escritora, entra na livraria do East Village onde Joe Goldberg trabalha. Bonita, inteligente e sexy, Beck ainda não sabe, mas é a mulher perfeita para Joe, que, a partir do nome impresso no cartão de crédito de sua cliente, passa a vasculhar sua vida na internet e a orquestrar uma série de eventos para garantir que ela caia em seus braços, fazendo com que tudo pareça obra do acaso. À medida que o romance entre os dois engrena, porém, o leitor descobre que Beck também guarda certos segredos e os desdobramentos desse relacionamento mutuamente obsessivo podem ser mortais.",
            },
            {
                titleLibrary: "O Marido Perfeito – Kimberly Belle – SUSPENSE NORTE-AMERICANO – 10839",
                textLibrary: "Iris e Will estão casados há sete anos e a vida não poderia ser mais perfeita. Até que, em uma manhã, quando Will voa para Orlando em uma viagem de negócios, o mundo feliz de Iris desmorona subitamente: um avião em direção a Seattle sofre um acidente matando todos a bordo com a queda e, de acordo com a companhia aérea, Will estava entre os passageiros. Entre dor e confusão, Iris está convencida de que tudo deve ser um enorme mal-entendido. Por que Will mentiria sobre onde ele estava indo? E sobre o que mais ele mentiu? Em sua busca desesperada para descobrir a verdade, Iris encontrará respostas que abalarão tudo o que ela acreditava saber, conhecer e amar. O que mais não sei sobre o meu marido? O que mais ele deixou de me contar? Existem quantas mentiras mais? Temos os vols. 1 e 2",
            },
            {
                titleLibrary: "Princesa Mecânica – Série As Peças Infernais – Vol. 3 – Cassandra Clare – SUSPENSE NORTE-AMERICANO – 10836",
                textLibrary: "Continuação de Príncipe mecânico, Princesa mecânica é ambientado no universo dos Caçadores de sombras, também explorado na série Os Instrumentos mortais, que chega agora ao cinema. Neste volume, o mistério sobre Tessa Gray e o Magistrado continua. Mas enquanto luta para descobrir mais sobre o próprio passado, a moça se envolve cada vez mais num triângulo amoroso que pode trazer consequências nefastas para ela, seu noivo, seu verdadeiro amor e os habitantes do Submundo. • Continuação de Príncipe mecânico, que entrou na lista de mais vendidos do New York Times. • A série está há mais de um ano na lista do New York Times. • Mais de 24 milhões de exemplares vendidos. Publicada em mais de 34 países. • A série Os Instrumentos Mortais estreou no cinema em agosto de 2013, numa coprodução da Sony Pictures. Segundo diversos sites de cinema, City of bones é o filme mais esperado do verão americano. • No Brasil, o filme é distribuído pela Paris Filmes, com grande investimento de marketing.",
            },
            {
                titleLibrary: "A Luz que Perdemos – Jill Santopolo – ROMANCE NORTE-AMERICANO – 10842",
                textLibrary: "A luz que perdemos é um romance impactante sobre o poder do primeiro amor. Uma ode comovente aos sacrifícios que fazemos em nome dos nossos sonhos e uma reflexão sobre os extremos que perseguimos em nome do amor.Lucy e Gabe se conhecem na faculdade na manhã de 11 de setembro de 2001. No mesmo instante, dois aviões colidem com as Torres Gêmeas. Ao ver as chamas arderem em Nova York, eles decidem que querem fazer algo importante com suas vidas, algo que promova uma diferença no mundo.Quando se veem de novo, um ano depois, parece um encontro predestinado. Só que Gabe é enviado ao Oriente Médio como fotojornalista e Lucy decide investir em sua carreira em Nova York.Nos treze anos que se seguem, o caminho dos dois se cruza e se afasta muitas vezes, numa odisseia de sonhos, desejo, ciúme, traição e, acima de tudo, amor. Lucy começa um relacionamento com o lindo e confiável Darren, enquanto Gabe viaja o mundo. Mesmo separados pela distância, eles jamais deixam o coração um do outro.Ao longo dessa jornada emocional, Lucy começa a se fazer perguntas fundamentais sobre destino e livre-arbítrio: será que foi o destino que os uniu? E, agora, é por escolha própria que eles estão separados?",
            },
            {
                titleLibrary: "Um Jantar Entre Espiões – Olen Steinhauer – ROMANCE NORTE-AMERICANO – 10844",
                textLibrary: "Dois amantes. Dois espiões. Um reencontro. Quem sairá vivo desse jantar?Em 2006, terroristas sequestraram um avião no Aeroporto de Viena, exigindo uma troca de reféns e determinados a não negociar. E, de fato, não negociaram – 120 pessoas foram mortas no incidente. Anos depois, quando um informante em Guantánamo afirma que os terroristas contaram com a ajuda de um traidor no posto avançado da CIA em Viena, o agente Henry Pelham é encarregado de investigar o assunto.O problema é que, para fazer isso, ele precisará ir até a idílica cidade de Carmel-by-the-Sea, na Califórnia, para rever um antigo amor. Celia Harrison também fazia parte do grupo que trabalhava na Agência em Viena na época, mas decidiu abandonar a vida no serviço secreto para se casar e ter filhos. Ao se encontrarem para um simples jantar, os dois logo se veem em um jogo de manipulação e mentira, no qual ambos se perguntam o que o outro tem a esconder.",
            },
            {
                titleLibrary: "Outros Jeitos de Usar a Boca – Rupi Kaur – POEMAS CANADENSES – 10845",
                textLibrary: "Maior best-seller de poesia dos últimos anos, agora em edição limitada com capa dura, bilíngue e com prefácio inédito da autora Outros jeitos de usar a boca é um livro de poemas sobre a sobrevivência, o amor, o sexo, o abuso, o trauma, a perda e a feminilidade. O volume é dividido em quatro partes, e cada uma delas serve a um propósito diferente. Lida com uma dor diferente. Cura uma mágoa diferente. Outros jeitos de usar a boca transporta o leitor por uma jornada pelos momentos mais amargos da vida e encontra uma maneira de tirar delicadeza deles. Publicado inicialmente de forma independente por Rupi Kaur, poeta, artista plástica e performer canadense nascida na índia e que também assina as ilustrações presentes neste volume , o livro se tornou o maior fenômeno do gênero nos últimos anos nos Estados Unidos, com mais de 1 milhão de exemplares impressos.Temos os vols. 2 e 3.",
            },
            {
                titleLibrary: "Conquistada por um Visconde – Trilogia As Irmãs Cynster – Vol. 1 – Stephanie Laurens – ROMANCE AUSTRALIANO – 10846",
                textLibrary: "Londres, 1829. Decidida a escolher seu próprio herói, um que a deixe completamente extasiada e pronta para a felicidade conjugal, Heather Cynster declina convites para os bailes conservadores da aristocracia londrina e busca aventuras fora de seu círculo social. Ao ultrapassar as fronteiras de seu mundo seguro e comparecer a um sarau oferecido por uma dama de reputação duvidosa, Heather acaba entrando na cova de leões. Mas seus planos de independência são arruinados quando encontra, no meio do salão, o enervante Visconde Breckenridge. Amigo dos Cynster e, principalmente, dos irmãos de Heather, ele a toma pelo braço e a leva até a carruagem. Mas seus esforços são inúteis. A poucos metros de entrar no carro, Heather é interceptada e sequestrada por inimigos ocultos que buscam vingança contra os Cynster. Agora, o arrojado Visconde terá de provar que é realmente o herói que Heather tanto almeja… Escrito com maestria, paixão e toques de humor, “Conquistada por um visconde” é uma trama inesquecível, que deixará o leitor querendo mais após a última página. Composta por Stephanie Laurens, considerada a sucessora de Georgette Heyer e a principal referência de muitas escritoras de sucesso, seus romances têm como cenário a alta sociedade de Londres do período regencial. “Conquistada por um visconde” é a escolha certa para os fãs de narrativas cujos personagens são damas audaciosas arrebatadas por nobres sedutores.Temos os vols. 1 e 3.",
            },
            {
                titleLibrary: "Salva por um Cavalheiro – Trilogia As Irmãs Cynster – Vol. 2 – Stephanie Laurens – ROMANCE AUSTRALIANO – 10847",
                textLibrary: "Londres, 1829.Impetuosamente sequestrada do baile de noivado de sua irmã Heather Cynster com o Visconde Breckenridge, Eliza desperta em um coche estranho a caminho de Edimburgo… Após passar 3 dias e 3 noites sedada, ela fará de tudo para escapar — nem que precise fingir estar desacordada para enganar seus algozes ao longo do percurso.Enquanto percorre os prados escoceses pensando em pergaminhos a serem decifrados e em uma esposa com quem possa compartilhar a vida, o erudito Jeremy Carling é pego de surpresa ao ver uma mulher gritando desesperada de dentro de um coche. Parecia alguma conhecida… Alguém a quem fora apresentado em um salão londrino… Mais precisamente… Eliza Cynster!Apesar de não ser nenhum herói, e sim um especialista em hieróglifos de grande prestígio, seu código de cavalheiro jamais permitiria ignorar uma dama aflita! Mas o perigo os espreita sorrateiramente na forma de um lorde misterioso que insiste em se apoderar de uma das irmãs Cynster. Um confronto à beira do penhasco colocaria um ponto final aos ardis do vilão oculto? Ou seria o momento certo para Eliza e Jeremy ousarem assumir um amor que nasceu em meio a tantos percalços? Em “Salva Por Um Cavalheiro”, Stephanie Laurens presenteia seus leitores com a apaixonante história de Eliza, a segunda irmã Cynster, e Jeremy. Ao longo das estradas, vales e montanhas que ligam Edimburgo a Londres, a autora desenvolve uma narrativa audaciosa com personagens sedutores em uma trama de mistério capaz de prender a atenção até a última página.",
            },
            {
                titleLibrary: "O Lado Azul da Vida – Osmar Barbosa – ROMANCE ESPÍRITA – 10849",
                textLibrary: "Neste livro você vai conhecer o Fernando, que sofre desde menino por ser homossexual. Sua irmã Raquel tenta a todo custo auxiliá-lo a enfrentar o preconceito, as diferenças e acima de tudo a dificuldade familiar. A escola? A rua? Nas festas? Como Fernando supera tudo isso? Seu pai o maltrata, e Fernando sofre muito. Como e porque nascem meninas em um corpo de meninos, ou vice-versa? Porque Raquel sua irmã, escolheu também nasceu homossexual? Haverá alternativas para eles? A família, como superar as diferenças? Sua avó é a grande amiga das horas difíceis. Porque nascem bissexuais? Porque meninas estão beijando meninas e meninos estão beijando meninos? Como lidar com essas diferenças? Como é ter em casa dois filhos homossexuais? Você vai viver uma experiência inesquecível nas linhas deste livro. Porque todos têm dentro de si um Lado Azul na Vida. Obrigado Nina Brestonini por essa incrível experiência.",
            },
            {
                titleLibrary: "Nunca é Tarde para Mudar – Mônica de Castro – ROMANCE ESPÍRITA – 10837",
                textLibrary: "Em romance inédito de Mônica de Castro, descubra que sempre é possível recomeçarHebron, cidade da Palestina, 1929. Um grupo de muçulmanos fanáticos promove um ataque aos judeus. O massacre é sangrento, cruel e inexplicável.Décadas depois, mundo contemporâneo. Bruno, jovem de família católica, desenvolve estranha obsessão pelo islamismo, ao mesmo tempo em que odeia os judeus. Apesar disso, apaixona-se por Tamara, moça judia, amiga do namorado de sua irmã. Tamara, contudo, o rejeita veementemente, fazendo recrudescer, na alma de Bruno, um desejo de vingança nascido muitas vidas atrás. Da mistura de sentimentos confusos e incompreensíveis, surge o plano macabro que fará reviver o ódio adormecido, porém, nunca esquecido.Cada vez mais envolvido pelo fanatismo islâmico, Bruno planeja e executa a obra máxima de sua vida, colocando em risco não apenas sua atual encarnação, como também sua própria permanência na Terra.Mas a vida obedece a critérios específicos de motivação espiritual, e não há atitude ou pensamento desprovido de causas, cujo conhecimento gravita nos recônditos mais distantes e sombrios da consciência. Mesmo as obras mais cruéis e inexplicáveis possuem um sentido oculto, ainda que totalmente desvirtuado dos caminhos do bem e da moral. Todavia, como o mal não é eterno, a oportunidade de mudança nunca deixará de existir.Não importa o quão obscuro foi o seu passado. Ser uma pessoa melhor depende da vontade e de suas próprias escolhas.",
            },
            {
                titleLibrary: "O Vírus das Tulipas Vermelhas – Daniëlle Hermans – SUSPENSE HOLANDÊS – 10851",
                textLibrary: "Dois assassinatos, um mistério e uma flor mortal que atravessa gerações Alkmaar, século XVII. O respeitado taberneiro e negociante de tulipas Wouter Winckel é encontrado brutalmente assassinado no salão de seu estabelecimento. Ele era o mais respeitado negociante de tulipas da cidade e possuía a mais bela coleção de flores da região, incluindo a mais cobiçada e cara de todas, a Semper augustus. Londres, século XXI. Com inúmeros ferimentos, Frank Schoeller é encontrado à beira da morte por seu sobrinho Alec. Pelas últimas palavras do tio – e pelo livro que segura com toda a força –, Alec deduz que sua morte esteja de alguma forma relacionada às tulipas. Com a ajuda de dois melhores amigos, ele segue um rastro que não só os leva ao comércio de tulipas do século XVII e à morte de Wouter Winckel, mas também a um grupo de investidores ricos do qual Frank fazia parte. Alec então se depara com uma cientista ambiciosa, Tara Quispel, que, ao que parece, conhecia Frank muito bem, mas não pelas razões que ele imagina. Quando ficam claras as intenções de Frank e o que estava em jogo, todos os envolvidos passam a temer pela própria vida.",
            },
        ],
    }
]