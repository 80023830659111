import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import CPGPoaNews from "../components/news-categories/CPGPoaNews";


const CPGPinhalCategory = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CPG POA"
                description=""
                currentPage="CPG POA" 
            />
            <CPGPoaNews />
            <FooterOne />
        </>
    )
}

export default CPGPinhalCategory;