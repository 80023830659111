import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGFuncionalContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>FUNCIONAL</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                As aulas de funcional são compostas por exercícios que envolvem treinar o corpo para
                                desenvolver capacidades funcionais necessárias às atividades da vida diária, como por
                                exemplo: saltar, agachar, empurrar, puxar, correr, levantar e arremessar. Para realização
                                dos exercícios, pode ser utilizado acessórios de ginástica ou o peso do próprio corpo.
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGFuncionalContent;