import React from 'react';

import styles from '../../styles/global.module.css';

const HandicraftContent = () => {
    return (
        <>
            <div className="about-block pb-110 pt-110">
                <div className="container">
                    <div className="row">
                        <div className={styles.columnCenter}>
                            
                            <p className={styles.textPattern}>O Departamento do Artesanato realiza bazares e feiras expondo o melhor do artesanato realizado não só por associados do Clube do Professor Gaúcho, mas também aberto a não-associados. Além de promover diversas oficinas e atividades que integram a programação Sociocultural do CPG.<br />

                            <span style={{fontWeight: "500"}}>Mais informações com o Departamento Sociocultural, pelo telefone (51) 3248.5710.</span></p>

                            <div className={styles.rowCenter}>
                                <div className='mt-50' style={{width: "400px", borderTop: "1px solid #ddd"}}>
                                    <h4 className='pt-50'  style={{ color: "#222"}}>COORDENADOR</h4>
                                    <p style={{fontWeight: "500", color: "#222", marginBottom:"2px"}}>Profª Cleusa Brussa</p>
                                    <p className='pb-50' style={{borderBottom: "1px solid #ddd", color: "#222"}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HandicraftContent;