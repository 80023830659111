import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import '../../App.css';

const LocationCPGPoa = () => {
    return (
        <>
            <div className="about-block pt-30 pb-110">
                <div>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>LOCALIZAÇÃO</h3><br />
                        </div>
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3450.2422805509577!2d-51.22626492352104!3d-30.144488435924675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519839c8ab9fb91%3A0xb3bdb00c4baa3d01!2sClube%20do%20Professor%20Ga%C3%BAcho!5e0!3m2!1sen!2sbr!4v1696944828400!5m2!1sen!2sbr" width="100%" height="600"></iframe>
                        </div>
                        <div className={stylesHeadOffice.address} id="text-transform">
                            <div className={styles.aboutContent}>
                                <h3 className={stylesHeadOffice.titlePattern}>ENDEREÇO DA SEDE</h3>
                                <p className={stylesHeadOffice.textPattern}>AV GUAÍBA, 12060 – BAIRRO IPANEMA<br />
                                    PORTO ALEGRE/RS<br />
                                    CEP 91760-740<br />
                                    FONE: <a href="tel:+555132485710">(51) 3248.5710</a> / <a href="tel:+5551989065114">(51) 98906.5114</a>
                                </p><br /><br />

                                <h3 className={stylesHeadOffice.titlePattern}>HORÁRIOS</h3>
                                <p className={stylesHeadOffice.textPattern}>
                                    <strong>Funcionamento da Sede</strong><br />
                                    TERÇA-FEIRA A DOMINGO: 8H ÀS 22H<br /><br />

                                    <strong>PISCINAS EXTERNAS</strong><br />
                                    TERÇA-FEIRA A DOMINGO: 9H ÀS 18H<br /><br />

                                    <strong>CENTRAL DE ATENDIMENTO</strong><br />
                                    TERÇA-FEIRA A SÁBADO: 8H30 ÀS 19H50<br />
                                    DOMINGOS E FERIADOS: 9H ÀS 16H50<br /><br />

                                    <strong>ATENDIMENTO PARA ASSOCIAÇÃO</strong><br />
                                    TERÇA-FEIRA A SÁBADO: 9H ÀS 16H50<br /><br />

                                    <strong>MUSCULAÇÃO</strong><br />
                                    DE TERÇA-FEIRA A QUINTA-FEIRA: 8H ÀS 22H.<br />
                                    SEXTA-FEIRA: 8H ÀS 21H.<br />
                                    SÁBADO: 8H ÀS 12H E 14H ÀS 18H.<br /><br />

                                    <strong>BIBLIOTECA</strong><br />
                                    TERÇA-FEIRA A SÁBADO: 9H ÀS 17H.<br /><br />

                                    <strong>CASINHA</strong><br />
                                    TERÇA-FEIRA A DOMINGO: 10H ÀS 19H.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationCPGPoa;