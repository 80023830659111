import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGJudoContent from '../components/cpg-sports/CPGJudoContent';
import CPGKarateContent from '../components/cpg-sports/CPGKarateContent';

const CPGKarate = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="KARATÊ"
                description=""
                currentPage="KARATÊ" 
            />
            <CPGKarateContent />
            <FooterOne />
        </>
    )
}

export default CPGKarate;