import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import professorClub from '../../assets/images/about/CPG-PINHAL.jpg';

const HistoryCPGPinhal = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className={stylesHeadOffice.historyContent}>
                        <div className=''>
                            <img src={professorClub} alt="" />
                        </div>
                        <div>
                            <h3 className={stylesHeadOffice.titlePattern}>HISTÓRIA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                            A sede de veraneio foi fundada em 1981, permitindo uma alternativa para seus associados curtirem veraneio em Pinhal, com estrutura completa de camping (área para 33 barracas) e hospedagem em 24 Apartamentos.
                            </p>
                            <p className={stylesHeadOffice.textPattern}>Associados de todo o Estado fazem suas reservas e convivem, durante a temporada, solidificando amizades, valorizando convívios. Mas fora dos meses de verão também existe uso do local, que é acolhedor e permite lazer e descanso em qualquer época do ano.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoryCPGPinhal;