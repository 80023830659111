import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import professorClub from '../../assets/images/about/SM.jpg';

const HistoryCPGSantaMaria = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className={stylesHeadOffice.historyContent}>
                        <div className=''>
                            <img src={professorClub} alt="" />
                        </div>
                        <div>
                            <h3 className={stylesHeadOffice.titlePattern}>HISTÓRIA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                                A sede do CPG em Santa Maria faz jus à importância daquela cidade no contexto estadual como cidade universitária. Fundada em 13 de julho de 1981, Ela nasceu com a necessidade de atender associados que teriam mais dificuldades, devido à distância, para usufruir o que Porto Alegre oferece. E faz isso com uma estrutura apropriada e uma administração competente. Tem salão de festas, seis cabanas para locação cozinha coletiva, área de churrasqueiras, cancha de bocha coberta, piscinas adulto e infantil, quadra de futebol sete, vestiários, banheiros, mesa de jogos, sala de brinquedos, lancheria e estacionamento. A região é cercada por cerros verdes, que dão a impressão de um abraço que protege e embeleza a sede.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoryCPGSantaMaria;