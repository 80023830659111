import React, { useEffect, useState } from 'react';
import HeaderTop from '../../common/header/HeaderTop';
import HeaderBottomTwo from '../../common/header/HeaderBottomTwo';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../../assets/images/breadcrumb/about-banner.jpg';
import { useParams } from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import GalleryDetailsContent from './GalleryDetailsContent';
import cpg_gallery_list from '../../data/cpg_gallery';
import styles from '../../styles/global.module.css';

const GalleryDetails = () => {

    const { id } = useParams();

    const [currentPage, setCurrentPage] = useState([{}]);

    useEffect(() => {

        const chosenPage = cpg_gallery_list.filter(obj => (obj.id == id));

        setCurrentPage(chosenPage[0]);

    }, []);

    console.log(id)

    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading={currentPage.title}
                description=""
                currentPage={currentPage.title}
            />
            <div className="service-details pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-4 col-lg-4 pr-50">
                            {currentPage.title}
                            <ServiceWidget />
                            <ContactWidget />
                            <QuoteWidget />
                        </div> */}
                        <div className="container">
                            <div className={styles.picturesGallery}>
                                <GalleryDetailsContent currentPage={currentPage} />
                                {/* <ServiceFaq /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default GalleryDetails;