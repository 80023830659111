export const santa_maria_news_list = [
    {
        id: 1,
        title: "COMUNICADO BANDEIRA PRETA EM TODO ESTADO",
        subtitle: "COMUNICADO BANDEIRA PRETA EM TODO ESTADO",
        // textContent: [
        //     {
        //         text: "Confira a retrospectiva do ano de 2022, com os principais acontecimentos das Sedes Porto Alegre, Santa Maria e Pinhal, nas áreas sociocultural, esporte e infraestrutura.",
        //     },
        //     {
        //         text: "Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede.",

        //     },
        //     {
        //         text: "Não esqueça de retirar o seu!",
        //     },
        //     {
        //         text: "Confira a versão digital! ?",
        //     }
        // ],

        principalImage: 'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANNER-BANDEIRA-PRETA.jpg?resize=740%2C400&ssl=1',

        infosImage: ['https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANDEIRA-PRETA-2.jpg?resize=557%2C790&ssl=1'],

        // iframe: "https://e.issuu.com/embed.html?identifier=r9xq1ik1ce82&embedType=script&u=clubedoprofessorgaucho&d=conex_o_45_-_retrospectiva_a062c7727b686e&p=1",

        images: [
            {
                url: 'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANDEIRA-PRETA-2.jpg?resize=557%2C790&ssl=1',
            }
        ],

    },
    {
        id: 2,
        title: "CPG REFORÇA AÇÕES PARA EVITAR O RISCO DE DISSEMINAÇÃO DO CORONAVÍRUS",
        subtitle: "CPG REFORÇA AÇÕES PARA EVITAR O RISCO DE DISSEMINAÇÃO DO CORONAVÍRUS",
        textContent: [
            {
                text: [
                    "E com o objetivo que manter os associados e a comunidade informados e conscientes sobre a rápida propagação e sobre prevenção da doente, o CPG promoverá neste sábado, 14 de março, a Palestra sobre Prevenção do CORONAVÍRUS (COVID-19), com o Técnico de Enfermagem do Trabalho, Hilton Carlos. A palestra será a partir das 14 horas, no Salão Thereza Noronha. A atividade é gratuita e aberta ao público."
                ],
            },
            {
                text: [
                    "O Clube do Professor Gaúcho também orienta aos associados que evitem frequentar as instalações do clube caso apresentem algum sintoma da doença."
                ],

            },
            {
                text: [
                    "// PALESTRA",
                ],
            },
            {
                text: [
                    "Palestra sobre Prevenção do Coronavírus (covid-19)",
                    "Sábado, 14 de março de 2020 a partir das 14 horas",
                    "Palestrante: Hilton Carlos – Técnico de Enfermagem do Trabalho – Atua no Pólo Petroquímico de Triunfo.",
                    "Gratuito e aberto ao público"
                ],
            },
            {
                text: [
                    "// COMO SE PREVENIR CONTRA O VÍRUS?",
                ],
            },
            {
                text: [
                    "Lavar as mãos",
                    "– A lavagem frequente das mãos é a principal recomendação para se prevenir;",
                    "– Higienizar as mãos com água e sabão por pelo menos 20 segundos a cada vez;",
                    "– Esfregar os espaços entre os dedos, o dorso da mão e cavidades (dobras dos dedos e unhas), onde as bactérias podem se alojar;",
                    "– Usar sabonete (apenas água é insuficiente para a higienização). Se não houver água e sabonete, usar um desinfetante para as mãos à base de álcool;"
                ],
            },
            {
                text: [
                    "Evitar contato próximo com pessoas doentes",
                ],
            },
            {
                text: [
                    "Ficar em casa quando estiver doente",
                ],
            },
            {
                text: [
                    "Cobrir boca e nariz ao tossir ou espirrar com um lenço de papel e jogar no lixo",
                ],
            },
            {
                text: [
                    "Profissionais de saúde devem utilizar medidas de precaução padrão, de contato e de gotículas (máscara cirúrgica, luvas, avental não estéril e óculos de proteção)",
                ],
            },
            {
                text: [
                    "Evitar tocar olhos, boca e nariz",
                    "– Contato com olhos, nariz ou boca permite que o vírus entre no corpo, gerando infecção. Essas regiões do corpo têm mucosas;"
                ],
            },
            {
                text: [
                    "Cuidados em ambientes com aglomeração de pessoas",
                    "– Em locais com grande concentração de pessoas (transporte público, por exemplo), é preciso tomar cuidados especiais;"
                ],
            },
            {
                text: [
                    "– Preferencialmente, mantenha-se a pelo menos 1 metro de distância de pessoas que estiverem tossindo ou espirrando;",
                ],
            },
            {
                text: [
                    "– Se tiver de tossir ou espirrar, cubra o rosto com o braço dobrado. Isso evita que as secreções do corpo entrem em contato com superfícies ou com outras pessoas;",
                ],
            },
            {
                text: [
                    "– As mesmas recomendações valem para qualquer local fechado, como o ambiente de trabalho;",
                ],
            },
            {
                text: [
                    "Álcool gel e máscaras",
                    "– O uso de álcool gel é uma medida eficaz para higienização das mãos, segundo o Ministério da Saúde. No entanto, deve ser considerada uma segunda opção, somente para ocasiões em que não é possível lavar as mãos com água e sabão;"
                ],
            },
            {
                text: [
                    "– As máscaras devem ser usadas somente por aqueles que já estão infectados pelo vírus, por profissionais da saúde ou por pessoas que estão com sintomas do coronavírus.",
                ],
            },
            {
                text: [
                    "Fonte: Secretaria Estadual da Saúde do Rio Grande do Sul e Ministério da Saúde",
                ],
            },
        ],

        principalImage: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2020/03/noticia_corona-virus.jpg?resize=740%2C400&ssl=1',

        // infosImage: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2021/01/PROTOCOLO-PINHAL-1.jpg?resize=590%2C827&ssl=1',

        // link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_ed44?utm_medium=referral&utm_source=cpg.com.br",

        // iframe: "https://e.issuu.com/embed.html?identifier=rbjoolwg22a6&embedType=script&u=clubedoprofessorgaucho&d=conex_o_ed44&p=1",


        // images: [
        //     {
        //         url: homeNews2,
        //     }
        // ],
    },
]