import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGJudoContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>JUDÔ</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                O Judô é uma modalidade que na sua prática agrega o desenvolvimento da defesa pessoal, resistência, agilidade e equilíbrio. Com isso pode-se identificar que os benefícios relacionados a prática do judô por crianças são inúmeros, e que vão além dos aspectos físicos ou relacionais. A modalidade correlaciona o desenvolvimento relacional, físico e comportamental de uma forma global.
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGJudoContent;