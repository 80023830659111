import React from 'react';
import '../../assets/css/main.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from "../../styles/global.module.css"
import AboutTeamImg1 from '../../assets/images/head-office/quadra-tenis.jpg';
import AboutTeamImg2 from '../../assets/images/head-office/playground-cpg-porto-alegre.jpg';
import AboutTeamImg3 from '../../assets/images/head-office/biblioteca-cpg-porto-alegre.jpg';
import AboutTeamImg4 from '../../assets/images/head-office/sala-de-sinuca-cpg-porto-alegre.jpg';
import AboutTeamImg5 from '../../assets/images/head-office/churrasqueiras-cpg-porto-alegre.jpg';
import AboutTeamImg6 from '../../assets/images/head-office/piscinas-externas.jpg';
import AboutTeamImg7 from '../../assets/images/head-office/piscina-termica.jpg';
import AboutTeamImg8 from '../../assets/images/head-office/churrasqueira2.jpg';
import AboutTeamImg9 from '../../assets/images/head-office/churrasqueira3.jpg';
import AboutTeamImg10 from '../../assets/images/head-office/churrasqueira4.jpg';
import AboutTeamImg11 from '../../assets/images/head-office/churrasqueira5.jpg';
import AboutTeamImg12 from '../../assets/images/head-office/churrasqueira6.jpg';
import AboutTeamImg13 from '../../assets/images/head-office/churrasqueira7.jpg';
import AboutTeamImg14 from '../../assets/images/head-office/churrasqueira8.jpg';
import { useState } from 'react';
import { HiDownload } from 'react-icons/hi';

const CarrouselPOAData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: 'Quadra de Tênis',

        heading: 'Quadra de Tênis',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: 'Playground',

        heading: 'Playground',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: 'Biblioteca CPG',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: 'Sala de Sinuca',

        heading: 'Sala de Sinuca',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg5,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg6,
        imgAlt: 'Piscinas Externas',

        heading: 'Piscinas Externas',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg7,
        imgAlt: 'Piscina Térmica',

        heading: 'Piscina Térmica',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg8,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg9,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg10,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg11,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },


    {
        id: uuidv4(),
        img: AboutTeamImg12,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg13,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg14,
        imgAlt: 'Churrasqueiras',

        heading: 'Churrasqueiras',
        position: '',
    },
];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position, openModal }) => {
    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center position-relative">
                    <div className="single-team-style-2-img">
                        <img onClick={() => openModal(img)} style={{cursor:"pointer"}} src={img} alt={imgAlt} />
                    </div>

                    <div className='shadow'>
                    </div>

                    <div className={styles.aboutSede}>
                        <h4 className='infosSedeImage'>{heading}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CarrouselPOA = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Add state to track the current image

    const downloadImage = () => {
        if (currentImage) {
            const downloadLink = document.createElement('a');
            downloadLink.href = currentImage;
            downloadLink.download = 'imagem_cpg.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowDownloadButton();
        }
    }

    function openModal(img) {
        setCurrentImage(img); // Set the current image before opening the modal
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    var settings = {
        speed: 500,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        prevArrow: <button type="button" className="slick-prev"><FaChevronLeft /></button>,
        nextArrow: <button type="button" className="slick-next"><FaChevronRight /></button>,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className='decor'>

            </div>

            <div className="team-area white-bg pt-30 pb-30">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span>Presidentes</span>
                                <h2 className={styles.titleTeamArea}>CLUBE DO PROFESSOR GAÚCHO</h2>
                            </div>
                        </div>
                    </div> */}
                    <Slider
                        {...settings}
                    >
                        {CarrouselPOAData.map((data) => (
                            <>
                                <AboutTeamItem
                                    key={data.id}
                                    img={data.img}
                                    imgAlt={data.imgAlt}

                                    heading={data.heading}
                                    position={data.position}
                                    openModal={openModal}
                                    downloadImage={downloadImage}
                                />
                            </>
                        ))}
                    </Slider>
                    {
                        showModal == true ?
                            <div className={styles.imageModal}>
                                <img className={styles.zoomImageGallery} src={currentImage} alt="" />
                                <button onClick={closeModal} title="Fechar" className={styles.closeModalGallery}><AiOutlineClose size={28} /></button>
                                <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                            </div>
                            :

                            ""
                    }
                </div>
            </div>
        </>
    )
}

export default CarrouselPOA;