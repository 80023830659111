import React, { useEffect, useState } from 'react';
import styles from '../../styles/global.module.css';
import { useParams } from 'react-router-dom';
import api from '../../services/api';


const CPGSchedulesModalities = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);

    console.log("id que puxamos:", id);

    useEffect(() => {
        api.get('/horario-modalidade-esportiva.php')
            .then(res => {
                if (res.data.success) {
                    const filteredData = res.data.horarios.filter(item => item.id_modalidade === id);
                    setData(filteredData);

                    console.log("filtered Data", filteredData);

                    // setData(res.data.horarios || []);
                    console.log("HORARIOS MODALIDADES", res.data.horarios);

                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [id]);

    return (
        <>
            {data.length > 0 && (
                <div className='pt-40'>
                    <div>
                        <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                    </div>
                    <table className={styles.tablePattern}>
                        {data.length > 0 && (
                            <thead>
                                <tr>
                                    <th>DIAS</th>
                                    <th>HORÁRIOS</th>
                                    <th>MODALIDADE</th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id_imagens_esportivas}>
                                    <td>{item.dia}</td>
                                    <td>{item.horario}</td>
                                    <td>{item.modalidade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default CPGSchedulesModalities;