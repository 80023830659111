import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import img1 from '../../assets/images/head-office/piscina-termica.jpg';
import img2 from '../../assets/images/head-office/churrasqueira2.jpg';
import img3 from '../../assets/images/head-office/biblioteca-cpg-porto-alegre.jpg';
import img4 from '../../assets/images/head-office/sala-de-sinuca-cpg-porto-alegre.jpg';
import img5 from '../../assets/images/head-office/churrasqueiras-cpg-porto-alegre.jpg';
import img6 from '../../assets/images/head-office/piscinas-externas.jpg';
import img7 from '../../assets/images/head-office/piscina-termica.jpg';
import img8 from '../../assets/images/head-office/churrasqueira2.jpg';
import img9 from '../../assets/images/head-office/churrasqueira3.jpg';
import img10 from '../../assets/images/head-office/churrasqueira4.jpg';
import img11 from '../../assets/images/head-office/churrasqueira5.jpg';
import img12 from '../../assets/images/head-office/churrasqueira6.jpg';
import img13 from '../../assets/images/head-office/churrasqueira7.jpg';
import img14 from '../../assets/images/head-office/churrasqueira8.jpg';
import img15 from '../../assets/images/head-office/quadra-tenis.jpg';
import img16 from '../../assets/images/head-office/playground-cpg-porto-alegre.jpg';

const AboutCPGPoa = () => {
    const [isCultureLazerOpen, setCultureLazerOpen] = useState(false);
    const [isEsportivosOpen, setEsportivosOpen] = useState(false);
    const [isEspacosEventosOpen, setEspacosEventosOpen] = useState(false);
    const [isEventosOpen, setEventosOpen] = useState(false);
    const [isEventosSociaisOpen, setEventosSociaisOpen] = useState(false);

    const toggleCultureLazer = () => setCultureLazerOpen(!isCultureLazerOpen);
    const toggleEsportivos = () => setEsportivosOpen(!isEsportivosOpen);
    const toggleEspacosEventos = () => setEspacosEventosOpen(!isEspacosEventosOpen);
    const toggleEventos = () => setEventosOpen(!isEventosOpen);
    const toggleEventosSociais = () => setEventosSociaisOpen(!isEventosSociaisOpen);

    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>INFRAESTRUTURA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                                A sede do CPG Porto Alegre fica localizada à beira do Guaíba, na Zona Sul da capital. Com 40.000 m² de área total, oferece uma completa infraestrutura para a prática de atividades sociais, esportivas, culturais e de lazer, que visam o bem-estar e a qualidade de vida.
                            </p><br />

                            <div className={stylesHeadOffice.recreationArea}>
                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleCultureLazer}>ESPAÇOS DE CULTURA E LAZER <span className={isCultureLazerOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isCultureLazerOpen && (
                                        <div className='list-content'>
                                            <ul className={stylesHeadOffice.list}>
                                                <li className={stylesHeadOffice.listItem}>Área de Churrasqueiras</li>
                                                <li className={stylesHeadOffice.listItem}>Quiosques</li>
                                                <li className={stylesHeadOffice.listItem}>Casinha de Brinquedos</li>
                                                <li className={stylesHeadOffice.listItem}>Biblioteca</li>
                                                <li className={stylesHeadOffice.listItem}>Playground</li>
                                                <li className={stylesHeadOffice.listItem}>Centro de Tradições Gaúchas</li>
                                                <li className={stylesHeadOffice.listItem}>Espaço Multiartes</li>
                                            </ul>
                                            <div className={stylesHeadOffice.imageWrapper}>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img1} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img2} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img3} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img4} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img5} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img6} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img7} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} id='titleAcordion' onClick={toggleEsportivos}>ESPAÇOS ESPORTIVOS <span className={isEsportivosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEsportivosOpen && (
                                        <>
                                            <ul className={stylesHeadOffice.list}>
                                                <li className={stylesHeadOffice.listItem}>Piscina Térmica</li>
                                                <li className={stylesHeadOffice.listItem}>Piscina Externa</li>
                                                <li className={stylesHeadOffice.listItem}>Quadras poliesportivas de vôlei, basquete e futsal</li>
                                                <li className={stylesHeadOffice.listItem}>Quadras de tênis</li>
                                                <li className={stylesHeadOffice.listItem}>Quadra de vôlei, beach tênis e futebol de areia</li>
                                                <li className={stylesHeadOffice.listItem}>Quadras de grama sintética</li>
                                                <li className={stylesHeadOffice.listItem}>Sala de musculação</li>
                                                <li className={stylesHeadOffice.listItem}>Sala de sinuca</li>
                                                <li className={stylesHeadOffice.listItem}>Sala de ginástica</li>
                                            </ul>
                                            <div className={stylesHeadOffice.imageWrapper}>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img1} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img6} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img15} />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEspacosEventos}>ESPAÇOS DE EVENTOS <span className={isEspacosEventosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEspacosEventosOpen && (
                                        <>
                                            <ul className={stylesHeadOffice.list}>
                                                <li className={stylesHeadOffice.listItem}>3 espaços para realização de eventos</li>
                                                <li className={stylesHeadOffice.listItem}>Salão Gourmet</li>
                                                <li className={stylesHeadOffice.listItem}>Salão Thereza Noronha</li>
                                                <li className={stylesHeadOffice.listItem}>Salão da Figueira</li>
                                                <li className={stylesHeadOffice.listItem}>Capacidade para 50 a 500 pessoas</li>
                                                <li className={stylesHeadOffice.listItem}>250 vagas de estacionamento</li>
                                            </ul>

                                            <div className={stylesHeadOffice.imageWrapper}>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img2} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img5} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img9} />
                                                </div>
                                                <div className={stylesHeadOffice.imageContent}>
                                                    <img src={img10} />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                            <div className={stylesHeadOffice.recreationArea}>
                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEventos}>EVENTOS <span className={isEventosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEventosOpen &&
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Eventos esportivos</li>
                                            <li className={stylesHeadOffice.listItem}>Natação</li>
                                            <li className={stylesHeadOffice.listItem}>Futsal</li>
                                            <li className={stylesHeadOffice.listItem}>Hidroginástica</li>
                                            <li className={stylesHeadOffice.listItem}>Musculação</li>
                                            <li className={stylesHeadOffice.listItem}>Ginástica</li>
                                            <li className={stylesHeadOffice.listItem}>Danças</li>
                                            <li className={stylesHeadOffice.listItem}>Yoga</li>
                                            <li className={stylesHeadOffice.listItem}>Tênis</li>
                                            <li className={stylesHeadOffice.listItem}>Sauna</li>
                                            <li className={stylesHeadOffice.listItem}>Piscina Térmica</li>
                                            <li className={stylesHeadOffice.listItem}>Vôlei</li>
                                            <li className={stylesHeadOffice.listItem}>Judô</li>
                                            <li className={stylesHeadOffice.listItem}>Caratê</li>
                                            <li className={stylesHeadOffice.listItem}>Basquete</li>
                                            <li className={stylesHeadOffice.listItem}>Patinação</li>
                                        </ul>
                                    }

                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEventosSociais}>EVENTOS SOCIAIS <span className={isEventosSociaisOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEventosSociaisOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Artesanato</li>
                                            <li className={stylesHeadOffice.listItem}>Confraria dos casais</li>
                                            <li className={stylesHeadOffice.listItem}>Confraria Cookies do saber</li>
                                            <li className={stylesHeadOffice.listItem}>Confraria mestres do Sabor</li>
                                            <li className={stylesHeadOffice.listItem}>Coral</li>
                                            <li className={stylesHeadOffice.listItem}>CTG Laço da Querência</li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGPoa;