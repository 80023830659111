import React from 'react';
import styles from '../styles/global.module.css';
import Slider from "react-slick";

const HomeDetailsContent = ({ currentPage }) => {
    // var settings = {
    //     speed: 500,
    //     autoplay: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     responsive: [

    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 arrows: false,
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 arrows: false,
    //             }
    //         }
    //     ]
    // };

    return (
        <>
            <img style={{ maxWidth: '750px' }} src={currentPage.principalImage} className="mb-35" alt="" />
            <p className={styles.titlePattern} style={{ textAlign: "left" }}>{currentPage.subtitle}</p>
            <p className={styles.textPattern}>{currentPage.text}</p>

            <div className={styles.textContent}>
                {
                    currentPage.textContent?.map((item, index) => {
                        return (
                            <div key={index}>
                                <p className={styles.textPattern} style={{ textAlign: "left" }}>
                                    {item.text?.map((textLine, index) => {
                                        return (
                                            <span key={index}>{textLine} <br /></span>
                                        )
                                    })}
                                </p>
                            </div>
                        )
                    })
                }
            </div>

            <div className={styles.newsInfosImage}>
                {
                    currentPage.infosImage?.map((info, index) => {
                        return (
                            <div key={index}>
                                <img src={info} /><br /><br /><br />
                            </div>
                        )
                    })
                }

            </div>

            <div className='link'>
                {currentPage.link ?
                    <div className={styles.newsPDFContainer}>
                        <iframe src={currentPage.iframe} width="380" height="354s"></iframe>
                    </div>
                    :
                    ""}
            </div>
        </>
    )
}

export default HomeDetailsContent;