import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import ContactForm from '../components/contact/ContactForm';
import ContactMap from '../components/contact/ContactMap';
import FooterOne from '../common/footer/FooterOne';
import ReceiveNewsContent from '../components/cpg-receive-news/ReceiveNewsContent';

const ReceiveNews = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="QUERO RECEBER NOTÍCIAS"
                description=""
                currentPage="QUERO RECEBER NOTÍCIAS" 
            />
            <ReceiveNewsContent />
            <FooterOne />
        </>
    )
}

export default ReceiveNews;