import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from '../../styles/global.module.css';

const AboutCooksFraternity = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-90 pb-60">
                <div className="container">
                    <div className="row">
                        <div className={styles.columnCenter}>
                            <p className={styles.textPattern}>
                            A confraria feminina “Cooks do Saber”, foi criada em 17 de junho de 2010, seu surgimento foi motivado pelo sonho de ver as associadas do clube trocar experiências de culinária. Promovem jantares, oficinas, concursos e atividades que a integram a programação Sociocultural do CPG.
                            </p>
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222" }}>COORDENADOR</h4>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", fontWeight: "500", color: "#222", marginBottom: "2px" }}>MAGDA REGINA FLORES DE AGUIAR CONDE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCooksFraternity;