export const pinhal_news_list = [
    {
        id: 1,
        title: "COMUNICADO BANDEIRA PRETA EM TODO ESTADO",
        subtitle: "COMUNICADO BANDEIRA PRETA EM TODO ESTADO",
        // textContent: [
        //     {
        //         text: "Confira a retrospectiva do ano de 2022, com os principais acontecimentos das Sedes Porto Alegre, Santa Maria e Pinhal, nas áreas sociocultural, esporte e infraestrutura.",
        //     },
        //     {
        //         text: "Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede.",
                
        //     },
        //     {
        //         text: "Não esqueça de retirar o seu!",
        //     },
        //     {
        //         text: "Confira a versão digital! ?",
        //     }
        // ],

        principalImage: 'https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANNER-BANDEIRA-PRETA.jpg?resize=740%2C400&ssl=1',

        infosImage: ['https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANDEIRA-PRETA-2.jpg?resize=557%2C790&ssl=1'],

        // iframe: "https://e.issuu.com/embed.html?identifier=r9xq1ik1ce82&embedType=script&u=clubedoprofessorgaucho&d=conex_o_45_-_retrospectiva_a062c7727b686e&p=1",
        
        images: [
            {
                url: ['https://i1.wp.com/cpg.com.br/site/wp-content/uploads/2021/02/BANDEIRA-PRETA-2.jpg?resize=557%2C790&ssl=1'],
            }
        ],
        
    },
    {
        id: 2,
        title: "CPG REFORÇA AÇÕES PARA EVITAR O RISCO DE DISSEMINAÇÃO DO CORONAVÍRUS",
        subtitle: "CPG REFORÇA AÇÕES PARA EVITAR O RISCO DE DISSEMINAÇÃO DO CORONAVÍRUS",
        // textContent: [
        //     {
        //         text: "Confira os principais acontecimentos das Sedes Porto Alegre e Santa Maria, nas áreas sociocultural, esporte e infraestrutura.",
        //     },
        //     {
        //         text: "Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede.",
                
        //     },
        //     {
        //         text: "Não esqueça de retirar o seu! ⠀",
        //     },
        //     {
        //         text: "Confira a versão digital! ?",
        //     }
        // ],

        principalImage: 'https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2020/03/noticia_corona-virus.jpg?resize=740%2C400&ssl=1',

        infosImage: ['https://i0.wp.com/cpg.com.br/site/wp-content/uploads/2021/01/PROTOCOLO-PINHAL-1.jpg?resize=590%2C827&ssl=1'],

        // link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_ed44?utm_medium=referral&utm_source=cpg.com.br",

        // iframe: "https://e.issuu.com/embed.html?identifier=rbjoolwg22a6&embedType=script&u=clubedoprofessorgaucho&d=conex_o_ed44&p=1",
        

        // images: [
        //     {
        //         url: homeNews2,
        //     }
        // ],
    },
]