import React from 'react';
import styles from '../../styles/global.module.css';
import { Link } from "react-router-dom";

const CPGAddress = () => {
    return (
        <>
            <div className="about-block pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.column}>
                            <h3 className={styles.titlePattern}>Conheça as sedes</h3>
                            <h5 className={styles.center}><Link to="/cpg-porto-alegre">SEDE PORTO ALEGRE</Link></h5>
                            <p className={styles.textPattern}>AV GUAÍBA, 12060 – BAIRRO IPANEMA<br />
                                PORTO ALEGRE/RS<br />
                                CEP 91760-740<br />
                                FONE: <a href="tel:+555132485710">(51) 3248.5710</a> / <a href="tel:+5551989065114">(51) 98906.5114</a>
                            </p>

                            <h5 className={styles.center}><Link to="/cpg-santa-maria">SEDE SANTA MARIA</Link></h5>
                            <p className={styles.textPattern}>TRAVESSA ESPÍNDOLA, 430 – BAIRRO CAMPESTRE<br />
                                SANTA MARIA/RS <br />
                                CEP 97090-190<br />
                                FONE: <a href="tel:+5551996357557">(55) 99635.7557</a></p>

                            <h5 className={styles.center}><Link to="/cpg-pinhal">SEDE BALNEÁRIO PINHAL</Link></h5>
                            <p className={styles.textPattern}>RUA AGOSTINHO ROCHA, 1458<br />
                                BALNEÁRIO PINHAL/RS<br />
                                CEP 95599-000<br />
                                FONE: <a href="tel:+555136822995">(51) 3682-2995</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGAddress;