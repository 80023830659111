import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ProjectStyleFive from '../components/cpg-associate/CPGAssociateImages';
import CtaTwo from '../components/cta/CtaTwo';
import WhyChoseUsTwo from '../components/why-choose-us/WhyChoseUsTwo';
import Subscribe from '../components/subscribe/Subscribe';
import FooterTwo from '../common/footer/FooterTwo';
import ServiceContent from '../components/cpg-associate/AboutAssociate';
import ServiceAddress from '../components/cpg-associate/CPGAddress';
import AboutAssociate from '../components/cpg-associate/AboutAssociate';
import CPGAddress from '../components/cpg-associate/CPGAddress';
import FooterOne from '../common/footer/FooterOne';

const CPGAssociate = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="ASSOCIE-SE"
                description=""
                currentPage="ASSOCIE-SE" 
            />
            <AboutAssociate />
            <ProjectStyleFive />
            <CPGAddress />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGAssociate;