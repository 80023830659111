import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ProjectStyleFive from '../components/cpg-associate/CPGAssociateImages';
import AboutAssociate from '../components/cpg-associate/AboutAssociate';
import CPGAddress from '../components/cpg-associate/CPGAddress';
import FooterOne from '../common/footer/FooterOne';
import AboutCouplesFraternity from '../components/cpg-couples-fraternity/AboutCouplesFraternity';
import CouplesFraternityImages from '../components/cpg-couples-fraternity/CouplesFraternityImages';

const CPGCouplesFraternity = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CONFRARIA DOS CASAIS"
                description=""
                currentPage="CONFRARIA DOS CASAIS" 
            />
            <AboutCouplesFraternity />
            <CouplesFraternityImages />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGCouplesFraternity;