import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import ContactForm from '../components/contact/ContactForm';
import TestimonialStyleThree from '../components/testimonial/TestimonialStyleThree';
import ContactMap from '../components/contact/ContactMap';
import Subscribe from '../components/subscribe/Subscribe';
import FooterTwo from '../common/footer/FooterTwo';
import FooterOne from '../common/footer/FooterOne';
import WorkForm from '../components/work/WorkForm';

const WorkWithUs = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="TRABALHE CONOSCO"
                description=""
                currentPage="TRABALHE CONOSCO" 
            />
            <WorkForm />
            {/* <TestimonialStyleThree /> */}
            <FooterOne />
        </>
    )
}

export default WorkWithUs;