import React, { useEffect, useState } from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import { Link, useParams } from 'react-router-dom';
import { cpg_sport_news } from '../data/cpg_sport_news';
import FooterOne from '../common/footer/FooterOne';
import HomeDetailsContent from '../components/HomeDetailsContent';
import styles from '../styles/global.module.css';
import stylesHeadOffice from '../styles/head-office/headOffice.module.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import api from '../services/api';
import CPGCarrousselSubmodalidades from '../components/cpg-sports/CPGCarrousselSubmodalidades';

const SportsModalitiesSubmenuDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        api.get('/modalidades-esportivas-detalhes-item.php?id='+id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.modalidades);
                    if (res.data.modalidades.submenus) {
                        // console.log("Submenus:", res.data.modalidades);
                    }
                }
            });
    }, [id]);

    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading={data.nome}
                description=""
                currentPage={data.nome}
            />
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            {data.nome && (
                                <h3 style={{textTransform:"uppercase"}} className={styles.titlePattern}>{data.nome}</h3>
                            )}

                            {data.descricao && (
                                <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>{data.descricao}</p>
                            )}
                        </div>

                        <CPGCarrousselSubmodalidades />

                        <div className='pt-40'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>DIAS</th>
                                        <th>HORÁRIOS</th>
                                        <th>MODALIDADE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>3ª e 5ª</td>
                                        <td>17h</td>
                                        <td>11 A 13 ANOS</td>
                                    </tr>
                                    <tr>
                                        <td>3ª e 5ª</td>
                                        <td>18h</td>
                                        <td>14 A 18 ANOS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default SportsModalitiesSubmenuDetails;