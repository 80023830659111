import React from 'react';
import { FaPhoneAlt, FaClock, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import Logo from '../../components/logo/Logo';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import Slider from "react-slick";

const HeaderTop = () => {
    var settings = {
        speed: 7000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <div className="header-top pt-5px pb-5px theme-bg-1">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-2 col-md-4">
                        <Logo />
                    </div>

                    <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 pt-25rem">
                        <div className="header-info">
                            <ul>
                                <Slider
                                    {...settings}
                                >
                                    <li>
                                        <FaPhoneAlt /> Contato:
                                        <a className='cellphone-contact cellphone-redirect' href="tel:+555132485710">  (51) 3248-5710</a>
                                    </li>
                                    <li>
                                        <FaClock /> HORÁRIO DE ATENDIMENTO: TERÇA-FEIRA A DOMINGO 08:00 – 22:00
                                    </li>
                                </Slider>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-25rem">
                        <div className="header-social text-center text-xl-end text-lg-end">
                            <a href="http://twitter.com/cpgaucho" target='_blank'><FaTwitter /></a>
                            <a href='https://www.facebook.com/clubedoprofessorgaucho' target='_blank'><FaFacebook /></a>
                            <a href="https://www.instagram.com/cpgaucho" target='_blank'><FaInstagram /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HeaderTop;