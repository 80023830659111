import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../styles/global.module.css';
import ProjectStyleFiveImg1 from '../../assets/images/project/quadra-de-tenis-cpg-porto-alegre.webp';
import ProjectStyleFiveImg2 from '../../assets/images/project/playground-cpg-porto-alegre.webp';
import ProjectStyleFiveImg3 from '../../assets/images/project/biblioteca-cpg-porto-alegre.webp';
import ProjectStyleFiveImg4 from '../../assets/images/project/churrasqueiras-cpg-porto-alegre.webp';
import ProjectStyleFiveImg5 from '../../assets/images/project/piscina-termica.webp';
import ProjectStyleFiveImg6 from '../../assets/images/project/piscinas-externas.webp';
import ProjectStyleFiveImg7 from '../../assets/images/project/ginasio-de-esportes.jpg';
import ProjectStyleFiveImg8 from '../../assets/images/project/area-das-piscinas.jpg';
import ProjectStyleFiveImg9 from '../../assets/images/project/area-das-piscinas2.jpg';
import ProjectStyleFiveImg10 from '../../assets/images/project/cancha-de-bocha.webp';
import ProjectStyleFiveImg11 from '../../assets/images/project/cabanas.webp';
import ProjectStyleFiveImg12 from '../../assets/images/project/apartamentos.webp';
import ProjectStyleFiveImg13 from '../../assets/images/project/cancha-de-bocha-1.webp';
import ProjectStyleFiveImg14 from '../../assets/images/project/convivio.webp';
import ProjectStyleFiveImg15 from '../../assets/images/project/piscinas.webp';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';

const ProjectStyleFiveData = [

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg1,
        heading: 'Garden Care',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg2,
        heading: 'Tree Plantation',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg3,
        heading: 'Watering',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg4,
        heading: 'Commercial Gardening',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg5,
        heading: 'Outdoor Scaping',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg6,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg7,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg8,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg9,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg10,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg11,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg12,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg13,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg14,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

    {
        id: uuidv4(),
        img: ProjectStyleFiveImg15,
        heading: 'Branch Cutting',
        description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime',
        btnLink: '/project-details',
    },

];

const ProjectStyleFiveItem = ({ img, heading, description, btnLink, openModal }) => {
    return (
        <>
            <div>
                <div className="single-project mb-25">
                    <div onClick={() => openModal(img)}  className={styles.singleProjectImg} style={{ backgroundImage: `url(${img})`, cursor:"pointer" }}>
                        {/* <div className="single-project-img-icon">
                            <Link to={btnLink}><FaAngleDoubleRight /></Link>
                        </div> */}
                    </div>
                    {/* <div className="single-project-content">
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}

const CPGAssociateImages = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Add state to track the current image

    const downloadImage = () => {
        if (currentImage) {
            const downloadLink = document.createElement('a');
            downloadLink.href = currentImage;
            downloadLink.download = 'imagem_cpg.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowDownloadButton();
        }
    }

    function openModal(img) {
        setCurrentImage(img); // Set the current image before opening the modal
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }


    return (
        <>
            <div className="container">
                <div className={styles.projectArea}>

                    {/* <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Our Project</span>
                                <h2>We have done more than 534+ <br /> successful projects</h2>
                            </div>
                        </div>
                    </div> */}
                    {ProjectStyleFiveData.map((data) => (
                        <ProjectStyleFiveItem
                            key={data.id}
                            img={data.img}
                            openModal={openModal}
                            downloadImage={downloadImage}
                        // heading={data.heading}
                        // description={data.description}
                        // btnLink={data.btnLink}
                        />
                    ))}
                </div>
                {
                    showModal == true ?
                        <div className={styles.imageModal}>
                            <img className={styles.zoomImageGallery} src={currentImage} alt="" />
                            <button onClick={closeModal} title="Fechar" className={styles.closeModalAssociate}><AiOutlineClose size={28} /></button>
                            <button onClick={downloadImage} title="Download" className={styles.downloadModalAssociate}><HiDownload size={28} /></button>
                        </div>
                        :

                        ""
                }
            </div>
        </>
    )
}

export default CPGAssociateImages;