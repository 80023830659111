import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGDancaDeSalaoContent from '../components/cpg-sports/CPGDancaDeSalaoContent';

const CPGDancaDeSalao = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="DANÇA DE SALÃO"
                description=""
                currentPage="DANÇA DE SALÃO" 
            />
            <CPGDancaDeSalaoContent />
            <FooterOne />
        </>
    )
}

export default CPGDancaDeSalao;