import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGGinasioDeEsportesContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>GINÁSIO DE ESPORTES</h3><br />
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                • Horário de funcionamento: de terça a domingo das 8h às 22h<br /><br />
                                O ginásio de esportes do CPG possui duas quadras poliesportivas, onde são
                                realizadas aulas de Futsal, Vôlei, Basquete, Patinação. Nos horários livres, as quadras
                                ficam à disposição do associado que quiser vir bater uma bola.<br /><br />
                                No ginásio temos também, uma sala de jogos com mesas de snooker, tênis de mesa
                                e pebolim. O acesso para academia de musculação e ginástica também fica dentro
                                do ginásio.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGGinasioDeEsportesContent;