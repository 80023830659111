import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGMusculacaoContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>MUSCULAÇÃO</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                A musculação é uma modalidade física na qual são praticados exercícios localizados
                                com uso de halteres, caneleiras e em aparelhos específicos. Uma das grandes
                                vantagens da musculação é de que o treinamento pode ser ajustado de acordo com
                                a condição, necessidade e disponibilidade de tempo do praticante.
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGMusculacaoContent;