import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGQuadraSinteticaContent from '../components/cpg-sports/CPGQuadraSinteticaContent';
import CPGQuadraPoliesportivaContent from '../components/cpg-sports/CPGQuadraPoliesportivaContent';
import CPGSalaSnookerContent from '../components/cpg-sports/CPGSalaSnookerContent';

const CPGSalaSnooker = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="SALA DE SNOOKER"
                description=""
                currentPage="SALA DE SNOOKER" 
            />
            <CPGSalaSnookerContent />
            <FooterOne />
        </>
    )
}

export default CPGSalaSnooker;