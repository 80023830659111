import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import HeroStyleOneImg1 from '../../assets/images/home/logo_CPG_3D.jpg';
import { BsClockFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { MdSportsSoccer } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { GiWhiteBook } from "react-icons/gi";


const HeroStyleOne = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="y1tdWr1nsVE"
                onClose={() => setOpen(false)}
            />
            <div className="hero-area height-800 bg-property d-flex align-items-center" data-overlay="black" data-opacity="3" style={{ backgroundImage: `url(${HeroStyleOneImg1})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-wrapper">
                                <h1>Conheça as novidades do website CPG</h1>
                                <p>Estamos felizes em ver você aqui! Aproveite nossos ambientes e fique ligado em nossas aulas e eventos exclusivos - turmas com vagas limitadas.</p>
                                {/* <Link to="/" className="l-btn">Agendar aula agora</Link> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-popup-content">
                                <img src={HeroStyleOneImg1} alt="" />
                                <div className="hero-popup-video">
                                    <button
                                        className="popup-video pulse-effect"
                                        onClick={() => setOpen(true)}>
                                        <FaPlay />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fast-access-container'>
                    <div className='fast-access'>
                        <Link to="/horario-de-funcionamento">
                            <div className='fast-access-content'>
                                <BsClockFill size={21} />
                                Horário de Funcionamento
                            </div>
                        </Link>

                        {/* <Link to="/socios"> */}
                        <a href="/socios/">
                            <div className='fast-access-content'>
                                <FaUser size={21} />
                                Área do Usuário
                            </div>
                        </a>
                        {/* </Link> */}

                        <Link to="/espacos-esportivos">
                            <div className='fast-access-content'>
                                <MdSportsSoccer size={25} />
                                Esportes
                            </div>
                        </Link>

                        <Link to="/cpg-centro-de-eventos">
                            <div className='fast-access-content'>
                                <BsFillCalendar2DateFill size={21} />
                                Espaços para Locação
                            </div>
                        </Link>

                        <Link to="/cpg-sociocultural">
                            <div className='fast-access-content'>
                                <GiWhiteBook size={25} />
                                Sociocultural
                            </div>
                        </Link>

                        <Link to="/fale-conosco">
                            <div className='fast-access-content'>
                                <MdEmail size={25} />
                                Contato
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default HeroStyleOne;