import React, { useEffect, useState } from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import { Link, useParams } from 'react-router-dom';
import { cpg_sport_news } from '../data/cpg_sport_news';
import FooterOne from '../common/footer/FooterOne';
import HomeDetailsContent from '../components/HomeDetailsContent';
import styles from '../styles/global.module.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const SportNewsDetails = () => {

    const { id } = useParams();

    const [currentPage, setCurrentPage] = useState([{}]);

    useEffect(() => {

        const chosenPage = cpg_sport_news.filter(obj => (obj.id == id));

        setCurrentPage(chosenPage[0]);

    }, []);

    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading={currentPage.title}
                description=""
                currentPage={currentPage.title}
            />
            <div className="service-details pt-110 pb-110">
                <div className="container">
                    <div className={styles.newsContent}>
                        <div className="row">
                            {/* <div className="col-xl-4 col-lg-4 pr-50">
                                {currentPage.title}
                                <ServiceWidget />
                                <ContactWidget />
                                <QuoteWidget />
                            </div> */}
                            <div className="container">
                                <div className="service-details-wrapper">
                                    <HomeDetailsContent currentPage={currentPage} />
                                    {/* <ServiceFaq /> */}
                                </div>
                            </div>
                        </div>
                        <div className='news-right-content'>
                            <div className={styles.newsCategoryPage}>
                                <h3 className={styles.newsCategoryTitle}>Categorias</h3>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-comunicacao">COMUNICAÇÃO</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-pinhal">CPG PINHAL</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-poa">CPG POA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-cpg-santa-maria">CPG SANTA MARIA</Link>
                                </div>
                                <div className={styles.category}>
                                    <AiOutlineCheckCircle /><Link className={styles.categoryLink} to="/categoria-ctg-laco-da-querencia">CTG LAÇO DA QUERÊNCIA</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default SportNewsDetails;