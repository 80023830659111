import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import horarioSantaMaria from '../../assets/images/cpg-santa-maria/horarios-santa-maria.jpg';
import defaultStyles from '../../styles/global.module.css';


const AboutCPGSantaMaria = () => {
    const [isCulturaOpen, setCulturaOpen] = useState(false);
    const [isEspacosEsportivosOpen, setEspacosEsportivosOpen] = useState(false);
    const [isEspacosEventosOpen, setEspacosEventosOpen] = useState(false);

    const toggleCulturaELazer = () => setCulturaOpen(!isCulturaOpen);
    const toggleEspacosEsportivos = () => setEspacosEsportivosOpen(!isEspacosEsportivosOpen);
    const toggleEspacosEventos = () => setEspacosEventosOpen(!isEspacosEventosOpen);

    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={stylesHeadOffice.titlePattern}>INFRAESTRUTURA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                                Com seus 27.000 m² de área e localizada no coração do Rio Grande do Sul, a sede do CPG Santa Maria está às margens da Barragem do Rio Vacacaí. Uma região cercada de verdes que enriquecem o ambiente e proporcionam um visual deslumbrante.
                            </p><br />

                            <div className={stylesHeadOffice.recreationArea}>
                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleCulturaELazer}>ESPAÇOS DE CULTURA E LAZER <span className={isCulturaOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isCulturaOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Área de Churrasqueiras</li>
                                            <li className={stylesHeadOffice.listItem}>Cabanas</li>
                                            <li className={stylesHeadOffice.listItem}>Mesa de Jogos</li>
                                            <li className={stylesHeadOffice.listItem}>Biblioteca</li>
                                            <li className={stylesHeadOffice.listItem}>Sala de Brinquedos</li>
                                            <li className={stylesHeadOffice.listItem}>Cozinha coletiva</li>
                                        </ul>
                                    )}
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEspacosEsportivos}>ESPAÇOS ESPORTIVOS <span className={isEspacosEsportivosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEspacosEsportivosOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Cancha de Bocha</li>
                                            <li className={stylesHeadOffice.listItem}>Quadra de futebol sete</li>
                                            <li className={stylesHeadOffice.listItem}>Quiosques</li>
                                            <li className={stylesHeadOffice.listItem}>Sala de ginástica</li>
                                            <li className={stylesHeadOffice.listItem}>Piscina Externa</li>
                                        </ul>
                                    )}
                                </div>

                                <div className={stylesHeadOffice.recreationAreaItem}>
                                    <h3 className={stylesHeadOffice.titleAccordion} onClick={toggleEspacosEventos}>ESPAÇOS DE EVENTOS <span className={isEspacosEventosOpen ? stylesHeadOffice.arrowIconOpen : stylesHeadOffice.arrowIcon}><MdKeyboardArrowDown size={27} /></span></h3>
                                    {isEspacosEventosOpen && (
                                        <ul className={stylesHeadOffice.list}>
                                            <li className={stylesHeadOffice.listItem}>Espaço para realização de eventos</li>
                                            <li className={stylesHeadOffice.listItem}>Capacidade para 50 a 200 pessoas</li>
                                            <li className={stylesHeadOffice.listItem}>100 vagas de estacionamento</li>
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className={defaultStyles.horarioStaMaria}>
                                <img src={horarioSantaMaria} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGSantaMaria;