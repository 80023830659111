import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const AboutCPGCultural = () => {
    return (
        <>
            <div className="about-block pt-110 pb-50">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <p className={stylesHeadOffice.textPattern}>
                                O Departamento Cultural é responsável por uma gama de atividades promovidas pelo Clube do Professor Gaúcho. Ainda em 2012, o grupo foi reestruturado e Coordenado, na época, pela Profª Alice Maria Raiher, reunindo outros associados com o mesmo objetivo: promover e multiplicar a cultura no CPG. Hoje, coordenado pelo associado Luiz Alfredo Moroni, o grupo se reúne uma vez ao mês para programar e organizar eventos de diversos segmentos: música, dança, literatura e artes plásticas, além de promover o Clube de Cinema e Clube de Leitura.
                            </p><br />

                            <p className={stylesHeadOffice.textPattern}><strong>Mais informações com o Departamento Sociocultural, pelo telefone (51) 3248.5710.</strong></p>
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>COORDENADOR</h4>
                                <p style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>LUIS ALFREDO MORONI</p>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", color: "#222", fontSize: "15px" }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGCultural;