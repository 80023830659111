import React, { useState } from 'react';
import '../../assets/css/main.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from "../../styles/global.module.css"
import AboutTeamImg1 from '../../assets/images/cpg-management/maria-terezinha.jpg';
import AboutTeamImg2 from '../../assets/images/cpg-management/teresa-carmem.jpg';
import AboutTeamImg3 from '../../assets/images/cpg-management/olinda.jpg';
import AboutTeamImg4 from '../../assets/images/cpg-management/julve.jpg';
import AboutTeamImg5 from '../../assets/images/cpg-management/lucy.jpg';
import AboutTeamImg6 from '../../assets/images/cpg-management/iara-teresinha.jpg';
import AboutTeamImg7 from '../../assets/images/cpg-management/cassiano-noimann.jpg';
import AboutTeamImg8 from '../../assets/images/cpg-management/zulma.jpg';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';


const CarrouselManagementData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: 'Maria Teresinha Mendes',

        heading: 'Maria Teresinha Mendes',
        position: 'Presidente',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: 'Teresa Carmen Marchetti',

        heading: 'Teresa Carmen Marchetti',
        position: 'Vice - Presidente',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: 'Olinda Pereira Morel Trindade',

        heading: 'Olinda Pereira Morel Trindade',
        position: 'Diretora Administrativa',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: 'Julve Claudett Vanz',

        heading: 'Julve Claudett Vanz',
        position: 'Diretora Financeira',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg5,
        imgAlt: 'Lucy Beatriz Lopes',

        heading: 'Lucy Beatriz Lopes',
        position: 'Diretora Patrimonial',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg6,
        imgAlt: 'Iara Teresinha Bernardes Malta',

        heading: 'Iara Teresinha Bernardes Malta',
        position: 'Diretora Sociocultural',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg7,
        imgAlt: 'Cassiano Noimann Leal',

        heading: 'Cassiano Noimann Leal',
        position: 'Diretor de Desportos',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg8,
        imgAlt: 'Zulma Machado',

        heading: 'Zulma Machado',
        position: 'Diretora Regional de Santa Maria',
    },
];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position, openModal }) => {


    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center">
                    <div className="single-team-style-2-img">
                        <img onClick={() => openModal(img)} className={styles.imgPadding} style={{cursor:"pointer"}} src={img} alt={imgAlt} />
                    </div>
                    <div className="single-team-content-style-2">
                        <h4 style={{ color: "#222", fontSize: "16px", textTransform: "uppercase" }}>{heading}</h4>
                        <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}>{position}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CarrouselManagement = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Add state to track the current image

    const downloadImage = () => {
        if (currentImage) {
            const downloadLink = document.createElement('a');
            downloadLink.href = currentImage;
            downloadLink.download = 'imagem_cpg.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowDownloadButton();
        }
    }

    function openModal(img) {
        setCurrentImage(img); // Set the current image before opening the modal
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className='decor'>

            </div>

            <div className="team-area white-bg pt-30 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span>Diretoria Executiva</span>
                                <h2 className={styles.titleTeamArea}>GESTÃO 2021/2024</h2>
                            </div>
                        </div>
                    </div>
                    <Slider
                        {...settings}
                    >
                        {CarrouselManagementData.map((data) => (
                            <AboutTeamItem
                                key={data.id}
                                img={data.img}
                                imgAlt={data.imgAlt}

                                heading={data.heading}
                                position={data.position}
                                openModal={openModal}
                                downloadImage={downloadImage}
                            />
                        ))}
                    </Slider>
                    {
                        showModal == true ?
                            <div className={styles.imageModal}>
                                <img className={styles.zoomImageGallery} src={currentImage} alt="" />
                                <button onClick={closeModal} title="Fechar" className={styles.closeModalGallery}><AiOutlineClose size={28} /></button>
                                <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                            </div>
                            :

                            ""
                    }
                </div>
            </div>
        </>
    )
}

export default CarrouselManagement;