import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutCPGPinhal from '../components/cpg-pinhal/AboutCPGPinhal';
import HistoryCPGPinhal from '../components/cpg-pinhal/HistoryCPGPinhal';
import LocationCPGPinhal from '../components/cpg-pinhal/LocationCPGPinhal';
import CarrouselPinhal from '../components/cpg-pinhal/CarrouselPinhal';
import AboutCPGSantaMaria from '../components/cpg-santa-maria/AboutCPGSantaMaria';
import HistoryCPGSantaMaria from '../components/cpg-santa-maria/HistoryCPGSantaMaria';
import LocationCPGSantaMaria from '../components/cpg-santa-maria/LocationCPGSantaMaria';
import CarrouselSantaMaria from '../components/cpg-santa-maria/CarrouselSantaMaria';


const CPGSantaMaria = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CPG SANTA MARIA"
                description=""
                currentPage="CPG SANTA MARIA"
            />
            <AboutCPGSantaMaria />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            <CarrouselSantaMaria />
            <HistoryCPGSantaMaria />
            <LocationCPGSantaMaria />
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default CPGSantaMaria;

