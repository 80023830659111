import { BsFillCheckCircleFill, BsPeopleFill, BsSnow, BsClockFill, BsFillChatFill } from 'react-icons/bs';
import { MdDirectionsCar } from 'react-icons/md';
import salao1 from '../../src/assets/images/salaoimg_1.jpg';
import salao2 from '../../src/assets/images/salaoimg_2.webp';
import salao3 from '../../src/assets/images/salaoimg_4.webp';
import figueira1 from '../../src/assets/images/figueira_1.webp';
import figueira2 from '../../src/assets/images/figueira_2.webp';
import figueira3 from '../../src/assets/images/figueira_3.webp';
import figueira4 from '../../src/assets/images/figueira_4.webp';
import figueira5 from '../../src/assets/images/figueira_5.webp';
import homeNews1 from '../assets/images/home/home_news1.webp';
import homeNews2 from '../assets/images/home/home_news2.webp';
import homeNews3 from '../assets/images/home/home_news3.webp';
import principalImage1 from '../assets/images/home/principal-new-image-1.webp';
import principalImage2 from '../assets/images/home/principal-new-image-2.webp';
import principalImage3 from '../assets/images/home/principal-new-image-3.webp';
import principalImage4 from '../assets/images/home/principalImage4.webp';
import horarioFuncionamento from '../assets/images/home/infos-horario-funcionamento.webp';

export const home_news_list = [
    {
        id: 1,
        title: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        subtitle: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        textContent: [
            {
                text: [
                    "Confira a retrospectiva do ano de 2022, com os principais acontecimentos das Sedes Porto Alegre, Santa Maria e Pinhal, nas áreas sociocultural, esporte e infraestrutura."
                ],
            },
            {
                text: [
                "Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede.",
                ]
                
            },
            {
                text: ["Não esqueça de retirar o seu!"],
            },
            {
                text: ["Confira a versão digital! ?"],
            }
        ],

        principalImage: principalImage1,

        link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_45_-_retrospectiva_a062c7727b686e?utm_medium=referral&utm_source=cpg.com.br",

        iframe: "https://e.issuu.com/embed.html?identifier=r9xq1ik1ce82&embedType=script&u=clubedoprofessorgaucho&d=conex_o_45_-_retrospectiva_a062c7727b686e&p=1",
        
        images: [
            {
                url: homeNews1,
            }
        ],
    },
    {
        id: 2,
        title: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        subtitle: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        textContent: [
            {
                text: ["Confira os principais acontecimentos das Sedes Porto Alegre e Santa Maria, nas áreas sociocultural, esporte e infraestrutura."],
            },
            {
                text: ["Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede."],
                
            },
            {
                text: ["Não esqueça de retirar o seu!"],
            },
            {
                text: ["Confira a versão digital! ?"],
            }
        ],

        principalImage: principalImage2,

        link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_ed44?utm_medium=referral&utm_source=cpg.com.br",

        iframe: "https://e.issuu.com/embed.html?identifier=rbjoolwg22a6&embedType=script&u=clubedoprofessorgaucho&d=conex_o_ed44&p=1",
        

        images: [
            {
                url: homeNews2,
            }
        ],
    },
    {
        id: 3,
        title: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        subtitle: "CONFIRA A NOVA EDIÇÃO DO CONEXÃO CPG",
        textContent: [
            {
                text: ["Conexão CPG” é o informativo do Clube do Professor Gaúcho, que traz as novidades e o que rolou no terceiro bimestre de 2022."],
            },
            {
                text: ["Confira os principais acontecimentos das Sedes Porto Alegre e Santa Maria, dentre os departamentos: sociocultural, esporte e infraestrutura."],
                
            },
            {
                text: ["Além de oferecer a versão online, o informativo é distribuído nos principais pontos da sede."],
            },
            {
                text: ["Não esqueça de retirar o seu!"],
            }
        ],

        principalImage: principalImage3,

        link: "https://issuu.com/clubedoprofessorgaucho/docs/conex_o_43_-_issu?utm_medium=referral&utm_source=cpg.com.br",

        iframe: "https://e.issuu.com/embed.html?identifier=chwtlpir8dil&embedType=script&u=clubedoprofessorgaucho&d=conex_o_43_-_issu&p=1",

        images: [
            {
                url: homeNews3,
            },
        ],
    },
    {
        id: 4,
        title: "HORÁRIO DE FUNCIONAMENTO NO FERIADO | 16.06.2022",
        subtitle: "HORÁRIO DE FUNCIONAMENTO NO FERIADO | 16.06.2022",
        // textContent: [
        //     {
        //         text: "",
        //     },
        // ],

        principalImage: principalImage4,

        infosImage: [horarioFuncionamento],

        images: [
            {
                url: homeNews3,
            },
        ],
    },
]