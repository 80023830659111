import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import institutionalImage from '../../assets/images/cpg-management/DIRETORIA.jpg';
import churrasqueiraBaixaTemporada from '../../assets/images/pdfs/regulamento-baixa-temporada.pdf';

const AboutCPGStatute = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className="">
                            <p className={stylesHeadOffice.textPattern}>
                                O Estatuto e Regulamentos do Clube do Professores Gaúcho possibilitam uma disciplina nas atividades e rotina do nosso Clube. As versões on-line de todos estes, estão disponíveis em nosso site. É importante lembrar, que é dever de todo associado cumprir com todos os regimentos dispostos nos Regulamentos e Estatuto do CPG.
                            </p>

                            <h5 className={styles.titleLink}><a href='https://cpg.com.br/site/wp-content/uploads/2018/08/ESTATUTO-CPG-2015.pdf' target='_blank'>Estatuto do Clube do Professor Gaúcho</a></h5>
                            <h5 className={styles.titleLink}><a href='https://drive.google.com/file/d/1lxYm4GwC41yfkXhqLkeypk3OfK_d35a6/view' target='_blank'>Regulamento Interno</a></h5>
                            <h5 className={styles.titleLink}><a href={churrasqueiraBaixaTemporada} target="_blank">Regulamento das Churrasqueiras na Baixa Temporada</a></h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGStatute;