import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGDancaDaMaturidadeContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>DANÇA DA MATURIDADE</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                Já conhece a Dança da Maturidade? Aqui no clube a maturidade representa energia e alegria, a nova modalidade esportiva é oferecida exclusivamente aos cepegeanos acima de 50 anos.
                                <br />É balançar o corpo, ativar a energia e liberar a endorfina!
                            </p>
                            <a className='l-btn2' href={horariosEsportivos} target='_blank'>Horários Esportivos</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGDancaDaMaturidadeContent;