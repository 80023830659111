import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const CPGSalaSnookerContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>SALA DE SNOOKER
                            </h3><br />
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                • Horário de funcionamento: de terça a domingo das 8h às 22h<br /><br />
                                A sala é equipada com duas mesas profissionais de snooker e um espaço com
                                televisão e cadeiras. Podendo ser utilizada por associados maiores de idade.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGSalaSnookerContent;