import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import { AiOutlineClose } from 'react-icons/ai';
import styles from '../../styles/global.module.css';
import event1 from '../../assets/images/home/ola-verao.jpg';
import event2 from '../../assets/images/home/cpg-modal-image.jpg';
import { HiDownload } from 'react-icons/hi';


const EventsData = [

    {
        id: uuidv4(),
        img: event2,
        imgAlt: 'Noite dos Esportes',

        heading: 'Noite dos Esportes',
        position: '',
    },

];

const AboutEventsData = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position }) => {
    const [openModal, setOpenModal] = useState(true);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const downloadImage = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = img; // Use the selected image URL for download
        downloadLink.download = 'cpg-imagem.jpg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowDownloadButton(false);
    }

    return (
        <>
            {
                openModal
                    ?

                    <div className={styles.imageModalHome}>
                        <img className={styles.zoomImage} src={img} alt="" />
                        <button onClick={() => setOpenModal(!openModal)} className={styles.closeModalHome}><AiOutlineClose size={22} /></button>
                        <button onClick={downloadImage} title="Download" className={styles.downloadModalPopup}><HiDownload size={28} /></button>
                    </div>
                    
                    :

                    ""
            }

        </>

    )
}

const CPGPopup = () => {

    return (
        <>
            <div className='decor'>

            </div>

            <div className="team-area white-bg pt-30 pb-30">
                <div className="container">
                    {EventsData.map((data) => (
                        <AboutEventsData
                            key={data.id}
                            img={data.img}
                            imgAlt={data.imgAlt}

                            heading={data.heading}
                            position={data.position}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default CPGPopup;