import React, { useState, useEffect } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import styles from '../../styles/global.module.css';
import axios from '../../services/api';

const WorkForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [link, setLink] = useState('');
    const [message, setMessage] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'

    useEffect(() => {
        setTimeout(() => {
          setFeedbackMessage('');
        }, 5000)
      }, [feedbackMessage]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name,
            email,
            phone,
            link,
            message,
        };

        try {
            const response = await axios.post('contato-trabalhe-conosco.php', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.success === true) {
                console.log('Requisição bem-sucedida:', response.data);

                setFeedbackMessage(response.data.msg || 'Mensagem enviada com sucesso!');
                setFeedbackType('success');

                setName('');
                setEmail('');
                setPhone('');
                setLink('');
                setMessage('');

            } else {
                console.error('Erro na resposta:', response.data);
                setFeedbackMessage(response.data.msg || 'Erro inesperado. Tente novamente mais tarde.');
                setFeedbackType('error');
            }
        } catch (error) {
            let errorMessage = 'Erro inesperado. Tente novamente mais tarde.';

            if (error.response && error.response.data && typeof error.response.data === 'string') {
                errorMessage = error.response.data;
            } else if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } else if (error.message) {
                errorMessage = error.message;
            }

            console.error('Erro:', errorMessage);
            setFeedbackMessage(errorMessage);
            setFeedbackType('error');
        }
    };

    return (
        <div className="contact-area white-bg pt-110 pb-110">
            <div className="container">
                <div className="pt-60 pb-60 pl-80 pr-80">
                    <div className="row">
                        <div className="col-xl-12 col-lg-6">
                            <div>
                                <div className="mb-40">
                                    <h2 className={styles.titlePattern}>TRABALHE CONOSCO</h2>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className={styles.column12}>
                                        <label htmlFor="nome" className={styles.formLabel}>NOME*:</label>
                                        <input
                                            className={styles.formControl}
                                            id='nome'
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className={styles.column6}>
                                        <label htmlFor="email" className={styles.formLabel}>E-MAIL*:</label>
                                        <input
                                            className={styles.formControl}
                                            id='email'
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className={styles.column6}>
                                        <label htmlFor="telefone" className={styles.formLabel}>TELEFONE*:</label>
                                        <input
                                            className={styles.formControl}
                                            id='telefone'
                                            type="text"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className={styles.column12}>
                                        <label htmlFor="link" className={styles.formLabel}>PORTIFÓLIO OU LINK PROFISSIONAL:</label>
                                        <input
                                            className={styles.formControl}
                                            id='link'
                                            type="text"
                                            value={link}
                                            required
                                            onChange={(e) => setLink(e.target.value)}
                                        />
                                    </div>

                                    <div className={styles.column12}>
                                        <label htmlFor="mensagem" className={styles.formLabel}>SUA MENSAGEM*:</label>
                                        <textarea
                                            className={styles.formControl}
                                            id='mensagem'
                                            style={{ marginTop: "0" }}
                                            rows="5"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        ></textarea>
                                    </div>

                                    <div className={styles.formBtnContent}>
                                        <button className={styles.formButton} type="submit">
                                            <HiOutlineMail size={20} />Enviar Mensagem
                                        </button>
                                    </div>

                                    {feedbackMessage && (
                                        <div
                                            style={{
                                                color: feedbackType === 'success' ? 'green' : 'red',
                                                marginTop: '10px',
                                                fontWeight: 'bold',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {feedbackMessage}
                                        </div>
                                    )}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkForm;
