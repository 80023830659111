import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import CPGDancaDeSalaoContent from '../components/cpg-sports/CPGDancaDeSalaoContent';
import CPGMusculacaoContent from '../components/cpg-sports/CPGMusculacaoContent';

const CPGMusculacao = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="MUSCULAÇÃO"
                description=""
                currentPage="MUSCULAÇÃO" 
            />
            <CPGMusculacaoContent />
            <FooterOne />
        </>
    )
}

export default CPGMusculacao;