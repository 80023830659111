import React, { useState, useEffect } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import styles from '../../styles/global.module.css';
import axios from '../../services/api';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [matricula, setMatricula] = useState('');
  const [userType, setUserType] = useState('');
  const [contactReason, setContactReason] = useState('');
  const [otherClassification, setOtherClassification] = useState('');
  const [contactReturnMethod, setContactReturnMethod] = useState({
    email: false,
    phone: false,
    noReturn: false,
  });
  const [message, setMessage] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'

  const handleUserTypeChange = (type) => {
    setUserType(type);
    if (type === 'nao-associado') {
      setMatricula(''); // Limpa o campo de matrícula
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setFeedbackMessage('');
    }, 5000)
  }, [feedbackMessage]);
  const handleReturnMethodChange = (method) => {
    if (method === 'noReturn') {
      setContactReturnMethod({
        email: false,
        phone: false,
        noReturn: !contactReturnMethod.noReturn,
      });
    } else {
      setContactReturnMethod({
        ...contactReturnMethod,
        [method]: !contactReturnMethod[method],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contato = {
      name,
      email,
      phone,
      matricula: userType === 'associado' ? matricula : null,
      userType,
      contactReason,
      otherClassification,
      contactReturnMethod: Object.keys(contactReturnMethod).filter(
        (key) => contactReturnMethod[key]
      ),
      message,
    };

    console.log(contato);

    try {
      const response = await axios.post('contato-fale-conosco.php', contato, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success === true) {  // Supondo que o sucesso seja indicado por response.data.success
        console.log('Requisição bem-sucedida:', response.data);
        setFeedbackMessage(response.data.msg || 'Mensagem enviada com sucesso!');
        setFeedbackType('success');

        // Limpar campos após sucesso
        setName('');
        setEmail('');
        setPhone('');
        setMatricula('');
        setUserType('');
        setContactReason('');
        setOtherClassification('');
        setContactReturnMethod({
          email: false,
          phone: false,
          noReturn: false,
        });
        setMessage('');
      } else {
        console.error('Erro na resposta:', response.data);
        setFeedbackMessage(response.data.msg || 'Erro inesperado. Tente novamente mais tarde.');
        setFeedbackType('error');
      }
    } catch (error) {
      let errorMessage = 'Erro inesperado. Tente novamente mais tarde.';

      if (error.response && error.response.data && typeof error.response.data === 'string') {
        errorMessage = error.response.data;
      } else if (error.response && error.response.data && error.response.data.msg) {
        errorMessage = error.response.data.msg;
      } else if (error.message) {
        errorMessage = error.message;
      }

      console.error('Erro:', errorMessage);
      setFeedbackMessage(errorMessage);
      setFeedbackType('error');
    }
  };

  return (
    <div className="contact-area white-bg pt-110 pb-110">
      <div className="container">
        <div className="pt-60 pb-60 pl-80 pr-80">
          <div className="row">
            <div className="col-xl-12 col-lg-6">
              <div>
                <div className="mb-40">
                  <h2 className={styles.titlePattern}>FALE CONOSCO</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className={styles.column12}>
                    <input
                      className={styles.formControl}
                      type="text"
                      placeholder="NOME*"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className={styles.column6}>
                    <input
                      className={styles.formControl}
                      type="email"
                      placeholder="E-MAIL*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={contactReturnMethod.noReturn}
                    />
                  </div>
                  <div className={styles.column6}>
                    <input
                      className={styles.formControl}
                      type="text"
                      placeholder="TELEFONE"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={contactReturnMethod.noReturn}
                    />
                  </div>

                  <div className={styles.column6}>
                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-1"
                        id="radio-1"
                        onChange={() => handleUserTypeChange('associado')}
                      />
                      <label htmlFor="radio-1">
                        Sou Associado, minha matrícula é
                      </label>
                    </div>

                    <input
                      className={styles.formControl}
                      type="text"
                      placeholder="NÚMERO DA MATRÍCULA"
                      value={matricula}
                      onChange={(e) => setMatricula(e.target.value)}
                      disabled={userType !== 'associado'}
                    />

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-1"
                        id="radio-2"
                        onChange={() => handleUserTypeChange('nao-associado')}
                      />
                      <label htmlFor="radio-2">Não sou Associado</label>
                    </div>
                  </div>

                  <div className={styles.column6}>
                    <h4 className={styles.textPattern2}>
                      Qual o motivo do seu contato?
                    </h4>

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-contact"
                        id="contact-1"
                        onChange={() => setContactReason('elogio')}
                      />
                      <label htmlFor="contact-1">Elogio</label>
                    </div>

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-contact"
                        id="contact-2"
                        onChange={() => setContactReason('sugestao')}
                      />
                      <label htmlFor="contact-2">Sugestão</label>
                    </div>

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-contact"
                        id="contact-3"
                        onChange={() => setContactReason('reclamacao')}
                      />
                      <label htmlFor="contact-3">Reclamação</label>
                    </div>

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-contact"
                        id="contact-4"
                        onChange={() => setContactReason('solicitacao')}
                      />
                      <label htmlFor="contact-4">Solicitação</label>
                    </div>

                    <div className={styles.formRadioHeader}>
                      <input
                        type="radio"
                        name="option-contact"
                        id="contact-5"
                        onChange={() => setContactReason('outros')}
                      />
                      <label htmlFor="contact-5">Outros, Qual?</label>
                    </div>

                    <input
                      className={styles.formControl}
                      type="text"
                      placeholder="OUTRA CLASSIFICAÇÃO"
                      value={otherClassification}
                      onChange={(e) => setOtherClassification(e.target.value)}
                      disabled={contactReason !== 'outros'}
                    />
                  </div>

                  <div className={styles.column6}>
                    <h4 className={styles.textPattern2}>
                      Forma de Retorno do Contato
                    </h4>
                    <div className={styles.formRadioHeader}>
                      <input
                        type="checkbox"
                        id="check-1"
                        onChange={() => handleReturnMethodChange('email')}
                        checked={contactReturnMethod.email}
                        disabled={contactReturnMethod.noReturn}
                      />
                      <label htmlFor="check-1">E-mail</label>
                    </div>
                    <div className={styles.formRadioHeader}>
                      <input
                        type="checkbox"
                        id="check-2"
                        onChange={() => handleReturnMethodChange('phone')}
                        checked={contactReturnMethod.phone}
                        disabled={contactReturnMethod.noReturn}
                      />
                      <label htmlFor="check-2">Telefone</label>
                    </div>
                    <div className={styles.formRadioHeader}>
                      <input
                        type="checkbox"
                        id="check-3"
                        onChange={() => handleReturnMethodChange('noReturn')}
                        checked={contactReturnMethod.noReturn}
                      />
                      <label htmlFor="check-3">Não desejo Retorno</label>
                    </div>
                  </div>

                  <div className={styles.column12}>
                    <textarea
                      className={styles.formControl}
                      rows="5"
                      placeholder="SUA MENSAGEM*"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>

                  <div className={styles.formBtnContent}>
                    <button className={styles.formButton} type="submit">
                      <HiOutlineMail size={20} />
                      Enviar Mensagem
                    </button>
                  </div>

                  {feedbackMessage && (
                    <div
                      style={{
                        color: feedbackType === 'success' ? 'green' : 'red',
                        marginTop: '10px',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {feedbackMessage}
                    </div>
                  )}

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
