import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { FaLevelUpAlt } from "react-icons/fa";

//Pages
import HomeDefault from "./pages/HomeDefault";
import HomeTwo from './pages/HomeTwo';
import HomeThree from './pages/HomeThree';
import About from "./pages/About";
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';
import ProjectOne from './pages/CPGAssociate';
import ProjectTwo from './pages/ProjectTwo';
import ProjectDetails from "./pages/ProjectDetails";
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Pricing from './pages/Pricing';
import Faq from './pages/Faq';
import Blog from "./pages/Blog";
import BlogList from './pages/BlogList';
import BlogDetails from './pages/BlogDetails';
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendor/slick/slick.css";
import './assets/css/default.css';
import './assets/css/main.css';
import './assets/css/responsive.css';
import './assets/vendor/modal-video/modal-video.min.css';
import "../node_modules/yet-another-react-lightbox/dist/styles.css";
import BusinessHours from './pages/BusinessHours';
import MakeAnEvent from './pages/MakeAnEvent';
import Handicraft from './pages/Handicraft';
import HeadOffice from './pages/CPGPoa';
import CPGPinhal from './pages/CPGPinhal';
import CPGSantaMaria from './pages/CPGSantaMaria';
import CPGManagement from './pages/CPGManagement';
import CPGStatute from './pages/CPGStatute';
import CPGAssociate from './pages/CPGAssociate';
import CPGLibrary from './pages/CPGLibrary';
import CPGCouplesFraternity from './pages/CPGCouplesFraternity';
import CPGCooksFraternity from './pages/CPGCooksFraternity';
import CPGTasteMasters from './pages/CPGTasteMasters';
import CPGCoral from './pages/CPGCoral';
import CPGCultural from './pages/CPGCultural';
import CPGLacoDaQuerencia from './pages/CPGLacoDaQuerencia';
import CPGRevive from './pages/CPGRevive';
import CPGSportActivities from './pages/CPGSportActivities';
import CPGFitnessActivities from './pages/CPGFitnessActivities';
import CPGSportSchool from './pages/CPGSportSchools';
import CPGGallery from './pages/CPGGallery';
import CPGSociocultural from './pages/CPGSociocultural';
import HomeNewsDetails from './pages/HomeNewsDetails';
import ReceiveNews from './pages/ReceiveNews';
import GalleryDetails from './components/cpg-gallery/GalleryDetails';
import ComunicationCategory from './pages/ComunicationCategory';
import CPGPinhalCategory from './pages/CPGPinhalCategory';
import CPGPoaCategory from './pages/CPGPoaCategory.js';
import CPGLacoDaQuerenciaCategory from './pages/CPGLacoDaQuerenciaCategory';
import CPGSantaMariaCategory from './pages/CPGSantaMariaCategory';
import CPGPinhalNewsDetails from './pages/CPGPinhalNewsDetail';
import CPGSantaMariaNewsDetails from './pages/CPGSantaMariaNewsDetails';
import CPGCTGNewsDetails from './pages/CPGCTGNewsDetails';
import CPGEsporte from './pages/CPGEsporte';
import SportNewsDetails from './pages/SportNewsDetails';
import CPGSocialCategory from './pages/CPGSocialCategory';
import SocialNewsDetails from './pages/SocialNewsDetails';
import ConexaoCPG from './pages/ConexaoCPG';
import CPGTerceirizados from './pages/CPGTerceirizados';
import WorkWithUs from './pages/WorkWithUs.js';
import CPGBallet from './pages/CPGBallet.js';
import CPGBasquete from './pages/CPGBasquete.js';
import CPGDancaCigana from './pages/CPGDancaCigana.js';
import CPGDancaDaMaturidade from './pages/CPGDancaDaMaturidade.js';
import CPGDancaDeSalao from './pages/CPGDancaDeSalao.js';
import CPGHandebol from './pages/CPGHandebol.js';
import CPGFutsal from './pages/CPGFutsal.js';
import CPGJazz from './pages/CPGJazz.js';
import CPGJudo from './pages/CPGJudo.js';
import CPGKarate from './pages/CPGKarate.js';
import CPGVivenciasAquaticas from './pages/CPGVivenciasAquaticas.js';
import CPGMusculacao from './pages/CPGMusculacao.js';
import CPGLocal from './pages/CPGLocal.js';
import CPGFuncional from './pages/CPGFuncional.js';
import CPGGinasioDeEsportes from './pages/CPGGinasioDeEsportes.js';
import CPGQuadraSintetica from './pages/CPGQuadraSintetica.js';
import CPGQuadraPoliesportiva from './pages/CPGQuadraPoliesportiva.js';
import CPGSalaSnooker from './pages/CPGSalaSnooker.js';
import CPGPiscinaESauna from './pages/CPGPiscinaESauna.js';
import SportsModalitiesDetails from './pages/SportsModalitiesDetails.js';
import SportsModalitiesSubmenuDetails from './pages/SportsModalitiesSubmenuDetails.js';

function App() {
  return (
    //basename='/cpg-web/'
    <Router>
      <ScrollToTopRoute />
      <Routes>
        <Route path="/" exact element={<HomeDefault />} />
        <Route path="/categoria-comunicacao" exact element={<ComunicationCategory />} />
        <Route path="/categoria-cpg-pinhal" exact element={<CPGPinhalCategory />} />
        <Route path="/categoria-cpg-poa" exact element={<CPGPoaCategory />} />
        <Route path="/categoria-cpg-santa-maria" exact element={<CPGSantaMariaCategory />} />
        <Route path="/categoria-ctg-laco-da-querencia" exact element={<CPGLacoDaQuerenciaCategory />} />
        <Route path="/categoria-esporte" exact element={<CPGEsporte />} />
        <Route path="/categoria-social" exact element={<CPGSocialCategory />} />
        <Route path="/home-two" exact element={<HomeTwo />} />
        <Route path="/home-three" exact element={<HomeThree />} />
        <Route path="/historia" exact element={<About />} />
        <Route path="/cpg-porto-alegre" exact element={<HeadOffice />} />
        <Route path="/cpg-pinhal" exact element={<CPGPinhal />} />
        <Route path="/cpg-santa-maria" exact element={<CPGSantaMaria />} />
        <Route path="/gestao-cpg" exact element={<CPGManagement />} />
        <Route path="/estatuto-regulamentos-e-relatorios" exact element={<CPGStatute />} />
        <Route path="/service-one" exact element={<ServiceOne />} />
        <Route path="/horario-de-funcionamento" exact element={<BusinessHours />} />
        <Route path="/cpg-centro-de-eventos" exact element={<MakeAnEvent />} />
        <Route path="/terceirados-no-clube" exact element={<CPGTerceirizados />} />
        <Route path="/reviver/" exact element={<CPGRevive />} />
        <Route path="/reviver/" exact element={<CPGRevive />} />
        <Route path="/cpg-sociocultural" exact element={<CPGSociocultural />} />
        <Route path="/ctg-laco-da-querencia" exact element={<CPGLacoDaQuerencia />} />
        <Route path="/coral" exact element={<CPGCoral />} />
        <Route path="/cultural" exact element={<CPGCultural />} />
        <Route path="/artesanato" exact element={<Handicraft />} />
        <Route path="/confraria-cooks-do-saber" exact element={<CPGCooksFraternity />} />
        <Route path="/confraria-mestres-do-sabor" exact element={<CPGTasteMasters />} />
        <Route path="/confraria-dos-casais" exact element={<CPGCouplesFraternity />} />
        <Route path="/artesanato" exact element={<Handicraft />} />
        <Route path="/biblioteca" exact element={<CPGLibrary />} />
        <Route path="/confraria-dos-casais" exact element={<CPGCouplesFraternity />} />
        <Route path="/confraria-cooks-do-saber" exact element={<CPGCooksFraternity />} />
        <Route path="/confraria-mestres-do-sabor" exact element={<CPGTasteMasters />} />
        <Route path="/coral" exact element={<CPGCoral />} />
        <Route path="/cultural" exact element={<CPGCultural />} />
        <Route path="/ctg-laco-da-querencia" exact element={<CPGLacoDaQuerencia />} />
        <Route path="/reviver" exact element={<CPGRevive />} />
        <Route path="/cpg-ginasio-de-esportes" exact element={<CPGGinasioDeEsportes />} />
        <Route path="/cpg-quadra-sintetica" exact element={<CPGQuadraSintetica />} />
        <Route path="/cpg-quadra-poliesportiva" exact element={<CPGQuadraPoliesportiva />} />
        <Route path="/cpg-sala-de-snooker" exact element={<CPGSalaSnooker />} />
        <Route path="/cpg-sala-de-snooker" exact element={<CPGSalaSnooker />} />
        <Route path="/cpg-piscina-termica-e-sauna" exact element={<CPGPiscinaESauna/>} />
        
        <Route path="/modalidades-esportivas/:id" exact element={<SportsModalitiesDetails />} />
        <Route path="/modalidades-esportivas-submenu/:id/" exact element={<SportsModalitiesSubmenuDetails />} />
        <Route path="/cpg-ballet" exact element={<CPGBallet />} />
        <Route path="/cpg-musculacao" exact element={<CPGMusculacao />} />
        <Route path="/cpg-local" exact element={<CPGLocal />} />
        <Route path="/cpg-funcional" exact element={<CPGFuncional />} />
        <Route path="/cpg-basquete" exact element={<CPGBasquete />} />
        <Route path="/cpg-danca-cigana" exact element={<CPGDancaCigana />} />
        <Route path="/cpg-danca-da-maturidade" exact element={<CPGDancaDaMaturidade />} />
        <Route path="/cpg-danca-de-salao" exact element={<CPGDancaDeSalao />} />
        <Route path="/cpg-futsal" exact element={<CPGFutsal />} />
        <Route path="/cpg-handebol" exact element={<CPGHandebol />} />
        <Route path="/cpg-jazz" exact element={<CPGJazz />} />
        <Route path="/cpg-judo" exact element={<CPGJudo />} />
        <Route path="/cpg-karate" exact element={<CPGKarate />} />
        <Route path="/cpg-vivencias-aquaticas" exact element={<CPGVivenciasAquaticas />} />
        {/* <Route path="/cpg-escolas-esportivas" exact  element={<CPGSportSchool />} /> */}
        <Route path="/service-two" exact element={<ServiceTwo />} />
        <Route path="/service-details-:id" exact element={<ServiceDetails />} />
        <Route path="/cpg-galeria-:id" exact element={<GalleryDetails />} />
        <Route path="/news-details-:id" exact element={<HomeNewsDetails />} />
        <Route path="/cpg-pinhal-news-details/:id" exact element={<CPGPinhalNewsDetails />} />
        <Route path="/cpg-santa-maria-news-details/:id" exact element={<CPGSantaMariaNewsDetails />} />
        <Route path="/ctg-laco-da-querencia/:id" exact element={<CPGCTGNewsDetails />} />
        <Route path="/esporte-details/:id" exact element={<SportNewsDetails />} />
        <Route path="/social-details/:id" exact element={<SocialNewsDetails />} />
        <Route path="/cpg-galeria" exact element={<CPGGallery />} />
        <Route path="/conexao-cpg" exact element={<ConexaoCPG />} />
        {/* <Route path="/cpg-centro-de-eventos" exact element={<CPGGallery />} /> */}
        <Route path="/associe-se" exact element={<CPGAssociate />} />
        <Route path="/project-two" exact element={<ProjectTwo />} />
        <Route path="/project-details" exact element={<ProjectDetails />} />
        <Route path="/team" exact element={<Team />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/faq" exact element={<Faq />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog-list" exact element={<BlogList />} />
        <Route path="/blog-details" exact element={<BlogDetails />} />
        <Route path="/fale-conosco" exact element={<Contact />} />
        <Route path="/trabalhe-conosco" exact element={<WorkWithUs />} />
        <Route path="/quero-receber-noticias" exact element={<ReceiveNews />} />
        <Route path="*" element={<HomeDefault />} />
      </Routes>
      <ScrollToTop className="scrollUp" smooth top="1500" component={<FaLevelUpAlt />} />
    </Router>
  );
}

export default App;
