import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import api from '../../services/api';

const Nav = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        api.get('/modalidades-esportivas.php')
            .then(res => {
                if (res.data.success) {
                    setData(res.data.modalidades);
                    // console.log("dataaa", res.data.modalidades);

                    res.data.modalidades.forEach(modalidade => {
                        // console.log("submenus:", modalidade.submenus);
                    });
                }
            });
    }, []);

    return (
        <div key={data.length} className="main-menu text-center">
            <nav id="mobile-menu">
                <ul>
                    <li>
                        <Link>Institucional</Link>
                        <ul className="submenu">
                            <li><Link to="/historia">História</Link></li>
                            <li><Link>Sedes</Link>
                                <ul className="submenu sub">
                                    <li><Link to="/cpg-porto-alegre">CPG Porto Alegre</Link></li>
                                    <li><Link to="/cpg-pinhal">CPG Pinhal</Link></li>
                                    <li><Link to="/cpg-santa-maria">CPG Santa Maria</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/gestao-cpg">Gestão</Link></li>
                            <li><Link to="/estatuto-regulamentos-e-relatorios">Estatuto</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link>Serviços</Link>
                        <ul className="submenu">
                            <li><Link to="/associe-se">Associe-se</Link></li>
                            <li><Link to="/horario-de-funcionamento">Horário de funcionamento</Link></li>
                            <li><Link to="/cpg-centro-de-eventos">Faça seu evento</Link></li>
                            <li><Link to="/terceirados-no-clube">TERCEIRIZADOS NO CLUBE</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/cpg-sociocultural">Sociocultural</Link>
                        <ul className='submenu'>
                            <li><Link to="/artesanato">Artesanato</Link></li>
                            <li><Link to="/biblioteca">Biblioteca</Link></li>
                            <li><Link to="/confraria-dos-casais">CONFRARIA DOS CASAIS</Link></li>
                            <li><Link to="/confraria-cooks-do-saber">CONFRARIA COOKS DO SABER</Link></li>
                            <li><Link to="/confraria-mestres-do-sabor">CONFRARIA MESTRES DO SABOR</Link></li>
                            <li><Link to="/coral">Coral</Link></li>
                            <li><Link to="/cultural">CULTURAL</Link></li>
                            <li><Link to="/ctg-laco-da-querencia">CTG LAÇO DA QUERÊNCIA</Link></li>
                            <li><Link to="/reviver">REVIVER</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link>Esporte</Link>
                        <ul className='submenu'>
                            <li><Link>MODALIDADES ESPORTIVAS</Link>
                                <ul className="submenu sub">
                                    {data.map((item, index) => (
                                        <div className='modalidades-esportivas' key={index}>
                                            {item.nome && (
                                                <li>
                                                    <Link to={`/modalidades-esportivas/${item.submenus ? '#' : item.id_esporte}`}>
                                                        {item.nome}
                                                    </Link>

                                                    {/* {console.log("item.submenus:", item.submenus)} */}

                                                    {item.submenus && (
                                                        <ul className="submenu sub">
                                                            {item.submenus.map((submenu, subIndex) => (
                                                                <li key={subIndex}>
                                                                    <Link to={`/modalidades-esportivas-submenu/${submenu.id_submenu}`}>
                                                                        {submenu.nome_submenu}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            </li>
                            <li><Link>ESPAÇOS ESPORTIVOS</Link>
                                <ul className="submenu sub">
                                    <li><Link to="/cpg-ginasio-de-esportes">GINÁSIO DE ESPORTES</Link></li>
                                    <li><Link to="/cpg-quadra-sintetica">QUADRA DE GRAMA SINTÉTICA</Link></li>
                                    <li><Link to="/cpg-quadra-poliesportiva">QUADRA POLIESPORTIVA EXTERNA</Link></li>
                                    <li><Link to="/cpg-sala-de-snooker">SALA DE SNOOKER</Link></li>
                                    <li><Link to="/cpg-piscina-termica-e-sauna">PISCINA TÉRMICA E SAUNA</Link></li>
                                </ul>
                            </li>
                            {/* <li><Link to="/cpg-escolas-esportivas">ESCOLAS ESPORTIVAS</Link></li> */}
                        </ul>
                    </li>
                    <li>
                        <Link>Comunicação</Link>
                        <ul className='submenu'>
                            <li><Link to="/cpg-galeria">GALERIA DE FOTOS</Link></li>
                            <li><Link to="/conexao-cpg">Conexão CPG</Link></li>
                        </ul>
                    </li>
                    {/* <li>
                        <Link to="/cpg-centro-de-eventos">Locação</Link>
                    </li> */}
                    <li>
                        {/* <a href='https://dedstudio.com.br/cpg/admin/' target='_blank'>Meu CPG</a> */}
                        <a href='/socios/'>Meu CPG</a>
                    </li>
                    <li>
                        <Link to="/fale-conosco">Contato</Link>
                        <ul className='submenu'>
                            <li><Link to="/fale-conosco">FALE CONOSCO</Link></li>
                            <li><Link to="/trabalhe-conosco">TRABALHE CONOSCO</Link></li>
                            <li><Link to="/quero-receber-noticias">QUERO RECEBER NOTÍCIAS</Link></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;