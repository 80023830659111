import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const AboutCPGCoral = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <p className={stylesHeadOffice.textPattern}>
                                Criado 4 de julho em 1995, está aberto aos associados e não associados
                                interessados em praticar o talento do canto. Sua primeira maestrina foi
                                Maristela Zancan, responsável por selecionar as vozes enquanto a associada
                                Prof.ª Zilda Soares tinha a responsabilidade de coordenar as atividades do
                                grupo. o Coral do Clube do Professor Gaúcho tem como objetivo divulgar o canto
                                coral através de suas apresentações. Ao longo dos anos, o Coral participou de
                                diversos eventos, encontros de coros (regional e inter-regional), além de
                                festividades de clubes coirmãos e instituições. Composto pelos associados do clube
                                que são apreciadores do canto, o grupo é coordenado pela profª. Lourdes Lemos e
                                regido pelo maestro João Fernando Araújo. Hoje o Coral conta com a participação
                                de mais de 30 integrantes e viaja o Estado entre uma apresentação e outra. Na
                                sequência do tempo, temos os seguintes regentes: Maristela Zancan, Liana
                                Reinach, Elizabet Jaeguer, Clarissa Peretti, Manoel Figueiredo de Abreu, José

                                Aluísio Prediger e João Fernando Azambuja de Araújo.
                            </p><br />
                        </div>
                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>COORDENADOR</h4>
                                <p style={{ fontWeight: "500", color: "#222", marginBottom: "2px", fontSize: "15px" }}>Profª LOURDES CATARINA CORREA DE LEMOS</p>
                                <p className='pb-50' style={{ borderBottom: "1px solid #ddd", color: "#222", fontSize: "15px" }}></p>
                            </div>
                        </div>

                        <div className={styles.rowCenter}>
                            <div className='mt-50' style={{ width: "400px", borderTop: "1px solid #ddd" }}>
                                <h4 className='pt-50' style={{ color: "#222", fontSize: "20px", fontWeight: "600" }}>MAESTRO</h4>
                                <p className='pb-50' style={{ fontWeight: "500", color: "#222", marginBottom: "2px", borderBottom: "1px solid #ddd", fontSize: "15px" }}>João Fernando de Azambuja Araújo</p>
                            </div>
                        </div>

                        <div className='pt-70'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>Dia</th>
                                        <th>Horário</th>
                                        <th>Local</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Quinta-feira</td>
                                        <td>14 horas</td>
                                        <td>Sala Multiuso</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCPGCoral;