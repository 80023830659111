import React from 'react';
import '../../assets/css/main.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from "../../styles/global.module.css"
import AboutTeamImg1 from '../../assets/images/cpg-pinhal/convivio.jpg';
import AboutTeamImg2 from '../../assets/images/cpg-pinhal/piscinas.jpg';
import AboutTeamImg3 from '../../assets/images/cpg-pinhal/apartamentos.jpg';
import AboutTeamImg4 from '../../assets/images/cpg-pinhal/cancha-de-bocha-1.jpg';
import AboutTeamImg5 from '../../assets/images/cpg-pinhal/quadra-de-areia.jpg';


const CarrouselPinhalData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: 'convivio',

        heading: 'convívio',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: 'Área das Piscinas',

        heading: 'Área das Piscinas',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: 'Apartamentos',

        heading: 'Apartamentos',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: 'Cancha de bocha',

        heading: 'Cancha de bocha',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg5,
        imgAlt: 'Quadra de areia e basquete',

        heading: 'Quadra de areia e basquete',
        position: '',
    },
];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position }) => {


    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center position-relative">
                    <div className="single-team-style-2-img">
                        <img src={img} alt={imgAlt} />
                    </div>

                    <div className='shadow'>

                    </div>

                    <div className={styles.aboutSede}>
                        <h4 className='infosSedeImage'>{heading}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CarrouselPinhal = () => {
    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        prevArrow: <button type="button" className="slick-prev"><FaChevronLeft /></button>,
        nextArrow: <button type="button" className="slick-next"><FaChevronRight /></button>,
        responsive: [
            
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className='decor'>
                
            </div>

            <div className="team-area white-bg pt-30 pb-30">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span>Presidentes</span>
                                <h2 className={styles.titleTeamArea}>CLUBE DO PROFESSOR GAÚCHO</h2>
                            </div>
                        </div>
                    </div> */}
                    <Slider
                        {...settings}
                    >
                            {CarrouselPinhalData.map((data) => (
                                <AboutTeamItem
                                    key={data.id}
                                    img={data.img}
                                    imgAlt={data.imgAlt}

                                    heading={data.heading}
                                    position={data.position}
                                />
                            ))}
                    </Slider>

                </div>
            </div>
        </>
    )
}

export default CarrouselPinhal;