import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import styles from '../../styles/global.module.css';
import event1 from '../../assets/images/cpg-home-events/PASCOA-FESTA_01.png';
import event2 from '../../assets/images/cpg-home-events/57-ANOS-DO-CPG---CARROSSEL_01.jpg';
import event3 from '../../assets/images/cpg-home-events/CARTAZ-CARNAVALADULTO-E-INFANTIL-CARD.jpg';
import event4 from '../../assets/images/cpg-home-events/BRINQUEDOS-INFLAVEIS-CARD.jpg';
import event5 from '../../assets/images/cpg-home-events/FESTA-DISCO-CARD.jpg';
import event6 from '../../assets/images/cpg-home-events/CARD-FESTA-JUNINA.png';
import event7 from '../../assets/images/cpg-home-events/CHA-DIA-DAS-MAES.jpg';
import event8 from '../../assets/images/cpg-home-events/HAPPYDAYDIADAMULHER-CARD.jpg';
import event9 from '../../assets/images/cpg-home-events/noite-do-samba.png';

// const BlogStyleThreeData = [

//     {
//         id: uuidv4(),
//         img: event1,
//         parentCat: '',
//         regularCat: '',
//         heading: 'DRIVE THRU DIA DAS CRIANÇAS',
//         content: '',
//         date: '10 October',
//         author: '',
//         btnLink: '/events-details/1',
//         btnText: 'CONTINUE LENDO',
//     },

//     {
//         id: uuidv4(),
//         img: event2,
//         parentCat: '',
//         regularCat: '',
//         heading: 'EVENTO CANCELADO // 3ª EDIÇÃO FESTA DISCO',
//         content: '',
//         date: '28 MARCH',
//         author: '',
//         btnLink: '/events-details/2',
//         btnText: 'SAIBA MAIS',
//     },
// ];

// const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
//     // return (
//     //     <>

//     //         <div className={styles.eventContent}>
//     //             <div className="bg-hover-style-1">
//     //                 <Link to={btnLink}><img className="event-img" src={img} alt="" /></Link>
//     //                 {/* <Link to={btnLink}><FaPlus /></Link> */}
//     //             </div>
//     //             <div className="event-infos">
//     //                 {/* <ul className="cat-list mt-30 mb-30">
//     //                         <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
//     //                         <li><Link to="/">{regularCat}</Link></li>
//     //                     </ul> */}
//     //                 <h3 className={styles.eventDate}>{date}</h3>
//     //                 <h4 className={styles.eventTitle}><Link to={btnLink}>{heading}</Link></h4>
//     //                 <p>{content}</p>
//     //                 {/* <ul className="post-meta mb-30">
//     //                         <li><Link to="/">{date}</Link></li>
//     //                         <li><span>By</span> <Link to="/">{author}</Link></li>
//     //                     </ul> */}
//     //                 {/* <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link> */}
//     //             </div>
//     //         </div>
//     //     </>
//     // )

//     return (
//         <div className="col-11">
//             <div className="single-team-style-2 text-center">
//                 <div className="single-team-style-2-img">
//                     <img className={styles.imgPadding} src={img} alt="" />
//                 </div>
//                 <div className="single-team-content-style-2">
//                     <h4 style={{ color: "#222", fontSize: "16px", textTransform: "uppercase" }}>{heading}</h4>
//                     <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}></span>
//                 </div>
//             </div>
//         </div>
//     )
// }

// const HomeEvent = () => {
//     var settings = {
//         speed: 500,
//         autoplay: true,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         dots: false,
//         arrows: true,
//         infinite: true,
//         responsive: [

//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     arrows: false,
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     arrows: false,
//                 }
//             }
//         ]
//     };

//     return (
//         <>
//             <div className="blog-area bg-white pt-90 pb-110">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className="section-title mb-70">
//                                 <span style={{ fontSize: "28px", fontWeight: "300" }}>Fique por dentro dos</span>
//                                 <h3 style={{ fontSize: "34px", fontWeight: "600", color: "#333", textAlign: "left" }}>PRÓXIMOS EVENTOS DO CPG</h3>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.eventsContainer}>
//                         <Slider
//                             {...settings}
//                         >
//                             {BlogStyleThreeData.map((data, index) => (
//                                 <BlogStyleThreeItem
//                                     key={data.id}
//                                     img={data.img}
//                                     parentCat={data.parentCat}
//                                     regularCat={data.regularCat}
//                                     heading={data.heading}
//                                     content={data.content}
//                                     date={data.date}
//                                     author={data.author}
//                                     description={data.description}
//                                     btnLink={data.btnLink}
//                                     btnText={data.btnText}
//                                 />
//                             ))}
//                         </Slider>
//                     </div>

//                 </div>
//             </div>
//         </>
//     )
// }

// export default HomeEvent;


const EventsData = [

    {
        id: uuidv4(),
        img: event1,
        imgAlt: 'Festa de Páscoa CPG',

        heading: 'Festa de Páscoa CPG',
        position: '',
    },

    {
        id: uuidv4(),
        img: event2,
        imgAlt: 'Festa de 57 anos do CPG',

        heading: 'Festa de 57 anos do CPG',
        position: '',
    },

    {
        id: uuidv4(),
        img: event3,
        imgAlt: 'Carnaval Adulto e Infantil',

        heading: 'Carnaval Adulto e Infantil',
        position: '',
    },

    {
        id: uuidv4(),
        img: event4,
        imgAlt: 'Happy Hour de Verão',

        heading: 'Happy Hour de Verão',
        position: '',
    },

    {
        id: uuidv4(),
        img: event5,
        imgAlt: 'Festa Disco',

        heading: 'Festa Disco',
        position: '',
    },

    {
        id: uuidv4(),
        img: event6,
        imgAlt: 'Festa Junina',

        heading: 'Festa Junina',
        position: '',
    },

    {
        id: uuidv4(),
        img: event7,
        imgAlt: 'Chá em homenagem ao dia das mães',

        heading: 'Chá em Homenagem ao Dia Das Mães',
        position: '',
    },

    {
        id: uuidv4(),
        img: event8,
        imgAlt: 'Happy Day Dia Da Mulher',

        heading: 'Happy Day Dia Da Mulher',
        position: '',
    },

    {
        id: uuidv4(),
        img: event9,
        imgAlt: 'Noite do Samba',

        heading: 'Noite do Samba',
        position: '',
    },
];

const AboutEventsData = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position }) => {


    return (
        <div className={styles.centerCarroussel}>
            <div className="col-11">
                <div className="single-team-style-2 text-center">
                    <div className="single-team-style-2-img">
                        <img className={styles.imgPadding} src={img} alt={imgAlt} />
                    </div>
                    <div className="single-team-content-style-2">
                        <h4 style={{ color: "#222", fontSize: "19px", fontWeight: "600", textTransform: "uppercase" }}>{heading}</h4>
                        <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}>{position}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const HomeEvent = () => {
    var settings = {
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className='decor'>

            </div>

            <div className="team-area white-bg pt-30 pb-30" id="eventos-home">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title mb-30 mt-50">
                                <span>Fique por dentro dos</span>
                                <h2 className={styles.titleTeamArea}>PRÓXIMOS EVENTOS DO CPG</h2>
                            </div>
                        </div>
                    </div>
                    <Slider
                        {...settings}
                    >
                        {EventsData.map((data) => (
                            <AboutEventsData
                                key={data.id}
                                img={data.img}
                                imgAlt={data.imgAlt}

                                heading={data.heading}
                                position={data.position}
                            />
                        ))}
                    </Slider>

                </div>
            </div>
        </>
    )
}

export default HomeEvent;