import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaTwitter, FaInstagram, FaMailBulk, FaFacebook } from 'react-icons/fa';

const FooterOne = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-110 pb-80" style={{ backgroundColor: '#182f61' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4>Sobre nós</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>O Clube do Professor Gaúcho é a única agremiação social de educadores do Brasil e maior clube classista da América do Sul.</p>
                                    </div>
                                    <div className="footer-info">
                                        <a href='tel:+555132485710'><FaPhoneAlt />(51) 3248-5710</a>
                                    </div>
                                    <div className="footer-info">
                                        <a href='mailto:atendimento@cpg.com.br'><FaMailBulk />atendimento@cpg.com.br</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-2">
                                    <div className="footer-title">
                                        <h4>Categorias</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/cpg-centro-de-eventos">Eventos</Link></li>
                                            <li><Link to="/associe-se">Social</Link></li>
                                            <li><Link to="/cultural">Cultura</Link></li>
                                            <li><Link to="/cpg-galeria">Galeria</Link></li>
                                            <li><Link to="/modalidades-esportivas">Esporte</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                                <div className="footer-widget-3">
                                    <div className="footer-title">
                                        <h4>Serviços</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/associe-se">Associe-se</Link></li>
                                            <li><Link to="/clube-de-vantagens">Clube de Vantagens</Link></li>
                                            <li><Link to="/cpg-centro-de-eventos">Faça seu Evento</Link></li>
                                            <li><Link to="/horario-de-funcionamento">Horário de Funcionamento</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-title">
                                        <h4>Comunicação</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/conexao-cpg">Conexão CPG</Link></li>
                                            <li><Link to="/categoria">CPG em Revista</Link></li>
                                            {/* <li><Link to="/">CPG na Mídia</Link></li> */}
                                            <li><Link to="/cpg-galeria">Galeria de Fotos</Link></li>
                                            <li><Link to="/categoria">Publicidade</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom theme-bg-1 pt-40 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="copyright">
                                    <p>© 2018 CLUBE DO PROFESSOR GAÚCHO. Todos os direitos reservados</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-xl-end text-lg-end text-md-end text-center">
                                <div className="footer-menu">
                                    <ul>
                                        <li><a href="http://twitter.com/cpgaucho" target='_blank'><FaTwitter /></a></li>
                                        <li><a href='https://www.facebook.com/clubedoprofessorgaucho' target='_blank'><FaFacebook /></a></li>
                                        <li><a href='https://www.instagram.com/cpgaucho' target='_blank'><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterOne;