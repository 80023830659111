import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutCPGPinhal from '../components/cpg-pinhal/AboutCPGPinhal';
import HistoryCPGPinhal from '../components/cpg-pinhal/HistoryCPGPinhal';
import LocationCPGPinhal from '../components/cpg-pinhal/LocationCPGPinhal';
import CarrouselPinhal from '../components/cpg-pinhal/CarrouselPinhal';


const CPGPinhal = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CPG PINHAL"
                description=""
                currentPage="CPG PINHAL"
            />
            <AboutCPGPinhal />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            <CarrouselPinhal />
            <HistoryCPGPinhal />
            <LocationCPGPinhal />
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default CPGPinhal;

