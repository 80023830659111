import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const CPGFutsalContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>FUTSAL</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                O Futsal é uma atividade física que desenvolve habilidades motoras gerais como locomoção, coordenação, domínio da bola, manipulação e equilíbrio, através de movimentos específicos, identificando e valorizando as regras e características básicas do esporte, assim como promovendo a socialização dos praticantes e desenvolvendo o espírito de equipe.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGFutsalContent;