import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ProjectStyleFive from '../components/cpg-associate/CPGAssociateImages';
import FooterOne from '../common/footer/FooterOne';
import AboutCouplesFraternity from '../components/cpg-couples-fraternity/AboutCouplesFraternity';
import CouplesFraternityImages from '../components/cpg-couples-fraternity/CouplesFraternityImages';
import AboutCPGTasteMasters from '../components/cpg-taste-masters/AboutTasteMasters';
import TasteMastersImages from '../components/cpg-taste-masters/TasteMastersImages';

const CPGTasteMasters = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CONFRARIA MESTRES DO SABOR"
                description=""
                currentPage="CONFRARIA MESTRES DO SABOR" 
            />
            <AboutCPGTasteMasters />
            <TasteMastersImages />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGTasteMasters;