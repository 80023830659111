import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import style from '../../styles/global.module.css';

const AboutAssociate = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-90 pb-60">
                <div className={style.container}>
                    <div className="row">
                        <div className={style.column}>
                            <h3 className={style.titlePattern}>Confira algumas das vantagens de ser um associado cepegeano!</h3>
                            <p className={`${style.textPattern} ${style.mb2Rem}`}>São várias opções esportivas, atividades sociais e culturais para todas as faixas etárias, na zona sul de Porto Alegre, Santa Maria e Balneário Pinhal.
                            Aqui você encontra mais qualidade de vida e bem-estar para toda a família, com um leque de opções para momentos de relaxamento, lazer, descontração e integração.</p>

                            <h3 className={style.titlePattern}>Como se associar</h3>
                            <p className={`${style.textPattern} ${style.mb2Rem}`}>Para se tornar associado do CPG é necessário comprovar formação Pedagógica, Licenciatura, Mestrado, Doutorado, Magistério ou Pós Graduação na área da Educação.
                            A aquisição do Título é diretamente na Central de Atendimento, que funciona das 8h30 às 19h50 de terça-feira a sábado e aos domingos, das 9h às 16h50min.</p>

                            <h3 className={style.titlePattern}>Documentação necessária:</h3>
                            <ul className={style.orderedList}>
                                <li>
                                    <b>Diploma de Magistério, Licenciatura, Pós-Graduação (na área da Educação)*, Mestrado ou Doutorado.</b><br />
                                    <strong id='pl-2'>Diploma que contenha na grade curricular as disciplinas: Didática, Estrutura e Funcionamento do Ensino, Filosofia da Educação e Sociologia/Psicologia da Educação. É obrigatório o mínimo de 2 a 3 disciplinas.*</strong>
                                </li>
                                
                                <li>
                                    Cópia da carteira de identidade e CPF do titular
                                </li>

                                <li>
                                    Cópia da certidão de nascimento ou RG dos dependentes
                                </li>

                                <li>
                                    Cópia da certidão de casamento ou formulário de inclusão de companheiro
                                </li>

                                <li>
                                    Cópia do comprovante de residência atualizado.
                                </li>
                            </ul>
                            <p className={`${style.textPattern} ${style.mb2Rem}`}>*documentação obrigatória para o titular</p>

                            <h3 className={style.titlePattern}>Atendimento para Associação:</h3>
                            <p className={`${style.textPattern} ${style.mb2Rem}`}>Para fazer parte de nosso quadro social, o interessado, deverá encaminhar seu diploma e grade curricular para a Central de Atendimento, através do e-mail: atendimento@cpg.com.br. A análise será realizada em até 5 dias úteis, e após a verificação do diploma, retornaremos seu e-mail.</p><br />

                            <p className={`${style.textPattern} ${style.mb2Rem}`}>De terça-feira aos domingos.<br />Telefone: (51) 3248.5710|98906.5114 ou atendimento@cpg.com.br </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutAssociate;