import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import styleManagement from '../../styles/cpg-management/cpgManagement.module.css'
import institutionalImage from '../../assets/images/cpg-management/DIRETORIA.jpg'

const AboutSection = () => {
    return (
        <>
            <div className="about-block pt-50 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styleManagement.managementArea}>
                            <div className="">
                                <h3 className={styleManagement.titlePattern}>CONSELHO DELIBERATIVO CONSULTIVO</h3>
                                <ul className={styleManagement.list}>
                                    <li className={stylesHeadOffice.listItem}><strong>Presidente:</strong></li>
                                    <li className={stylesHeadOffice.listItem}>Catarina Nunes Martins</li>
                                    <li className={stylesHeadOffice.listItem}><strong>Secretária: </strong></li>
                                    <li className={stylesHeadOffice.listItem}>Liane Solange Lopes Medeiros</li><br />
                                    <li className={stylesHeadOffice.listItem}>Alice Maria Raiher</li>
                                    <li className={stylesHeadOffice.listItem}>Vera Maria Fernandes Borges</li>
                                    <li className={stylesHeadOffice.listItem}>Nubia dos Santos Sagini</li>
                                    <li>Vera Rejane Gazola Hellmann</li>
                                    <li>Guacira Martins Ferlauto</li>
                                    <li>Cleusa Maria Brussa da Silva</li>
                                    <li>Vera Maria D. Barnes</li>
                                    <li>Eunice Dieguez</li>
                                    <li>Doris Maria Louis Fogaça</li>
                                    <li>Maria de Fatima Costa da Rosa</li>
                                    <li>Vera Lucia Fraga Rodrigues</li>
                                    <li>Suzana Maria Bauer Garcia</li>
                                    <li>Maria Salete da Silva Aumond</li>
                                </ul>
                            </div>

                            <div className="">
                                <h3 className={styleManagement.titlePattern}>CONSELHO FISCAL</h3>
                                <ul className={styleManagement.list}>
                                    <li className={stylesHeadOffice.listItem}>Lourdes Catarina Correa de Lemos</li>
                                    <li className={stylesHeadOffice.listItem}>Lídia Maria Bettio Redivo</li>
                                    <li className={stylesHeadOffice.listItem}>Rosa Carmo Araujo Neumann</li>
                                    <li>Ana Maria Otero Veloso</li>
                                    <li>Jussara Kruger Mariano</li>
                                </ul>
                            </div>

                            <div className="">
                                <h3 className={styleManagement.titlePattern}>COMISSÃO DE ÉTICA</h3>
                                <ul className={styleManagement.list}>
                                    <li>Janina Sobral de Rezende</li>
                                    <li>Antônio Borges</li>
                                    <li>Branca Regina Barreto Krause</li>
                                    <li>Magda Duarte Leal Marques</li>
                                    <li>Marli Terezinha Ribas Siqueira</li>
                                    <li>Sandra Jussara Mendes Ribeiro</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection;