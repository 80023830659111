import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGBasqueteContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>BASQUETE</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                O Basquete por ser uma atividade dinâmica, predominantemente aeróbica, envolve uma série de benefícios, além de ser uma ótima maneira de manter a forma. A prática desse esporte exige do jogador equilíbrio e força, fortalecendo os músculos abdominais e da região lombar, favorecendo a resistência e sustentação da coluna vertebral.
                            </p>
                        </div>
                        <div className='pt-40'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>DIAS</th>
                                        <th>HORÁRIOS</th>
                                        <th>MODALIDADE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>3ª e 5ª</td>
                                        <td>17h</td>
                                        <td>11 A 13 ANOS</td>
                                    </tr>
                                    <tr>
                                        <td>3ª e 5ª</td>
                                        <td>18h</td>
                                        <td>14 A 18 ANOS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGBasqueteContent;