import React, { useEffect, useState } from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import { Link, useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import styles from '../styles/global.module.css';
import stylesHeadOffice from '../styles/head-office/headOffice.module.css';
import api from '../services/api';
import CpgCarrousselModalidades from '../components/cpg-sports/CpgCarrousselModalidades';
import CPGSchedulesModalities from '../components/cpg-sports/CPGSchedulesModalities';

const SportsModalitiesDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        api.get('/modalidades-esportivas-detalhes.php?id='+id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.modalidades);
                    // console.log("dataaa", res.data.modalidades);
                }
            });
    }, [id]);

    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={breadcrumbBg}
                heading={data.nome}
                description=""
                currentPage={data.nome}
            />
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            {data.nome && (
                                <h3 className={styles.titlePattern}>{data.nome}</h3>
                            )}

                            {data.descricao && (
                                <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>{data.descricao}</p>
                            )}
                        </div>
                    </div>
                    <CpgCarrousselModalidades />
                    <CPGSchedulesModalities />
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default SportsModalitiesDetails;