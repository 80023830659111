import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { FaPlay } from 'react-icons/fa';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import professorClub from '../../assets/images/about/sede-cpg-porto-alegre.png';

const HistoryCPGPoa = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="0O2aH4XLbto"
                onClose={() => setOpen(false)}
            />
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className={stylesHeadOffice.historyContent}>
                        <div className=''>
                            <img src={professorClub} alt="" />
                        </div>
                        <div>
                            <h3 className={stylesHeadOffice.titlePattern}>HISTÓRIA</h3>
                            <p className={stylesHeadOffice.textPattern}>
                                O Clube do Professor Gaúcho foi fundado em 1966 por um grupo visionário de educadores, que viam a necessidade de um espaço que representasse a classe dos professores na sociedade. Com o objetivo de oferecer à classe do magistério, a promoção do bem estar físico, moral e social, através da recreação, encontros e práticas esportivas o CPG  se tornou a única agremiação social de educadores do Brasil e maior clube classista da América do Sul. Embora sua primeira edificação fosse erguida em 1971, no bairro Ipanema, Zona Sul da Capital, as margens do rio Guaíba, o CPG foi fundado 13 de agosto de 1966 pela professora Thereza Noronha Carvalho, também sua primeira presidente, que liderou o grupo sob o lema: “A união de uma classe materializada em concreto”.
                            </p><br />
                            <p className={stylesHeadOffice.textPattern}>O Clube do Professor Gaúcho mantém atualmente três sedes sociais no Estado do Rio Grande do Sul: em Porto Alegre, em Santa Maria e no Litoral Norte, no Balneário Pinhal. Consagrado espaço de sociabilidade que proporciona agradável convívio entre professores de todas as instituições de ensino fundamental, médio e superior, públicas ou particulares, o clube conta hoje com cerca de 8 mil associados titulares e aproximadamente 11 mil beneficiários em todo Estado.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoryCPGPoa;