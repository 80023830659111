import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const CPGQuadraPoliesportivaContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>QUADRA POLIESPORTIVA EXTERNA
                            </h3><br />
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                • Horário de funcionamento: de terça a domingo das 8h às 22h<br /><br />
                                O clube possui uma quadra poliesportiva externa, que pode ser utilizada pelos
                                associados, para jogos de vôlei, basquete, futebol, handebol e patinação.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGQuadraPoliesportivaContent;