import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import ProjectStyleFive from '../components/cpg-associate/CPGAssociateImages';
import AboutAssociate from '../components/cpg-associate/AboutAssociate';
import CPGAddress from '../components/cpg-associate/CPGAddress';
import FooterOne from '../common/footer/FooterOne';
import AboutCouplesFraternity from '../components/cpg-couples-fraternity/AboutCouplesFraternity';
import CouplesFraternityImages from '../components/cpg-couples-fraternity/CouplesFraternityImages';
import AboutCooksFraternity from '../components/cpg-cooks-fraternity/AboutCooksFraternity';
import CooksFraternityImages from '../components/cpg-cooks-fraternity/CooksFraternityImages';

const CPGCooksFraternity = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CONFRARIA COOKS DO SABER"
                description=""
                currentPage="CONFRARIA COOKS DO SABER" 
            />
            <AboutCooksFraternity />
            <CooksFraternityImages />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGCooksFraternity;