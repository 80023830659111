import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';
import horariosEsportivos from '../../assets/images/cpg-sports/horarios-esportivo.pdf';

const CPGBalletContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>BALLET</h3>
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                Também conhecido dança clássica, é um tipo de dança que reúne uma série de
                                técnicas e movimentos específicos. Os princípios básicos do balé são: postura
                                ereta, movimentos circulares dos membros superiores, verticalidade corporal,
                                disciplina, leveza, harmonia e simetria.
                            </p>
                        </div>
                        <div className='pt-40'>
                            <div>
                                <h3 className={styles.titlePattern2}>TABELA DE HORÁRIOS</h3>
                            </div>
                            <table className={styles.tablePattern}>
                                <thead>
                                    <tr>
                                        <th>HORÁRIOS</th>
                                        <th>3ª FEIRA</th>
                                        <th>4ª FEIRA</th>
                                        <th>5ª FEIRA</th>
                                        <th>SÁBADO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>10h30</td>
                                        <td></td>
                                        <td></td>
                                        <td>BABY CLASS BALLET 3 A 6 ANOS</td>
                                        <td>BABY CLASS BALLET 3 A 6 ANOS</td>
                                    </tr>
                                    <tr>
                                        <td>9h30</td>
                                        <td>JAZZ INFANTIL a partir de 7 anos</td>
                                        <td></td>
                                        <td>JAZZ INFANTIL a partir de 7 anos</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>14h</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>DANÇA CIGANA ( Sala Ginástica)</td>
                                    </tr>
                                    <tr>
                                        <td>15h</td>
                                        <td>JAZZ INFANTIL a partir de 7 ano</td>
                                        <td></td>
                                        <td>BABY CLASS BALLET 3 A 6 ANOS</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>20h</td>
                                        <td></td>
                                        <td>DANÇA DE SALÃO A partir de 15 anos ( S. Thereza Noronha)</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGBalletContent;