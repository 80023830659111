import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload} from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import BlogStyleThreeImg1 from '../../assets/images/cpg-gallery/cha-prata.webp';
import BlogStyleThreeImg2 from '../../assets/images/cpg-gallery/ritmos.webp';
import BlogStyleThreeImg3 from '../../assets/images/cpg-gallery/festa-fantasia.webp';
import BlogStyleThreeImg4 from '../../assets/images/cpg-gallery/festa-disco.webp';
import BlogStyleThreeImg5 from '../../assets/images/cpg-gallery/festa-farroupilha.webp';
import BlogStyleThreeImg6 from '../../assets/images/cpg-gallery/yoga.webp';
import BlogStyleThreeImg7 from '../../assets/images/cpg-gallery/visit.webp';
import BlogStyleThreeImg8 from '../../assets/images/cpg-gallery/coral-birthday.webp';
import BlogStyleThreeImg9 from '../../assets/images/cpg-gallery/volei.webp';
import BlogStyleThreeImg10 from '../../assets/images/cpg-gallery/desafio-futebol.webp';
import Pagination from '../pagination/Pagination';
import styles from '../../styles/global.module.css';

const BlogStyleThreeData = [

    {
        id: uuidv4(),
        img: BlogStyleThreeImg1,
        parentCat: '',
        regularCat: '',
        heading: 'CHÁ PRATA',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-1',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg2,
        parentCat: '',
        regularCat: '',
        heading: 'AULÃO DE RITMOS E ZUMBA',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-2',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg3,
        parentCat: '',
        regularCat: '',
        heading: 'FESTA FANTASIA',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-3',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg4,
        parentCat: '',
        regularCat: '',
        heading: 'FESTA DISCO II EDIÇÃO',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-4',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg5,
        parentCat: '',
        regularCat: '',
        heading: 'Abertura dos Festejos Farroupilhas 2019',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-5',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg6,
        parentCat: '',
        regularCat: '',
        heading: 'AULÃO DE YOGA',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-6',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg7,
        parentCat: '',
        regularCat: '',
        heading: 'Norte Americanos da FCA visitam o CPG',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-7',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg8,
        parentCat: '',
        regularCat: '',
        heading: 'CHÁ DE ANIVERSÁRIO DO CORAL',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-8',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg9,
        parentCat: '',
        regularCat: '',
        heading: 'FINAL METROPOLITANO CPG DE VOLEIBOL +35',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-9',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg10,
        parentCat: '',
        regularCat: '',
        heading: 'DESAFIO INTERNACIONAL E FUTEBOL 2019',
        content: '',
        date: '',
        author: '',
        btnLink: '/cpg-galeria-10',
        btnText: 'SAIBA MAIS',
    },
];

const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const downloadImage = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = img;
        downloadLink.download = 'imagem_cpg.jpg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowDownloadButton()
    }

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }


    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <div className="blog-img">
                        <img onClick={openModal} src={img} alt="" />
                        <Link to={btnLink}><FaPlus /></Link>
                    </div>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to={btnLink}>{heading}</Link></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                    </div>
                </div>

            </div>
            {
            showModal == true ?
                <div className={styles.imageModal}>
                    <img className={styles.zoomImageGallery} src={img} alt="" />
                    <button onClick={closeModal} title="Fechar" className={styles.closeModalGallery}><AiOutlineClose size={28} /></button>
                    <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                </div>
            :
            
            ""
            }
            
        </>
    )
}

const GalleryContent = () => {
    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="section-title text-center mb-70">
                                <span>Latest News & Blog</span>
                                <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <Pagination />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default GalleryContent;