import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutCPGRevive from '../components/cpg-revive/AboutCPGRevive';
import CarrouselCPGRevive from '../components/cpg-revive/CarrouselCPGRevive';

const CPGRevive = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="REVIVER"
                description=""
                currentPage="REVIVER" 
            />
            <AboutCPGRevive />
            <CarrouselCPGRevive/>
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default CPGRevive;