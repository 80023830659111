import React, { useState } from 'react';
import styles from "../../styles/global.module.css";
import stylesHeadOffice from '../../styles/head-office/headOffice.module.css';

const CPGPiscinaESaunaContent = () => {
    return (
        <>
            <div className="about-block pt-110 pb-110">
                <div className={styles.container}>
                    <div className="row">
                        <div className={styles.aboutContent}>
                            <h3 className={styles.titlePattern}>PISCINA TÉRMICA E SAUNA
                            </h3><br />
                            <p className={`${stylesHeadOffice.textPattern} ${stylesHeadOffice.mb3}`}>
                                O espaço possui uma ampla piscina aquecida (15x15) onde acontecem aulas de
                                Hidroginástica, Natação e Vivências Aquáticas. Tem também, os horários livres para
                                que os associados possam nadar, brincar ou praticar atividades físicas.<br /><br />
                                A sauna é uma opção para quem gosta de relaxar e interagir com outras pessoas. No
                                espaço da sauna temos um ambiente para sauna seca e outro para sauna vapor,
                                tem também, duas salas amplas para quem gosta de descansar e relaxar entre uma
                                sauna e outra.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPGPiscinaESaunaContent;