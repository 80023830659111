import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import BlogStyleThreeImg1 from '../../assets/images/project/espaco-gourmet.webp';
import BlogStyleThreeImg2 from '../../assets/images/project/salao.webp';
import BlogStyleThreeImg3 from '../../assets/images/project/salao-figueira.webp';
import styles from '../../styles/global.module.css';
import Pagination from '../pagination/Pagination';
import { events_list } from '../../data/events_list';

const BlogStyleThreeData = [

    {
        id: uuidv4(),
        img: BlogStyleThreeImg1,
        parentCat: 'Gardening',
        regularCat: 'Nursery',
        heading: 'SALÃO GOURMET',
        content: 'O Salão Gourmet, compacto e aconchegante, pode acomodar até 30 pessoas sentadas. Está localizado no novo Espaço Multiartes para receber eventos de pequeno porte. Mais…',
        date: '25 October 2021',
        author: 'Andrew Phili',
        btnLink: '/service-details-1',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg2,
        parentCat: 'Gardening',
        regularCat: 'Nursery',
        heading: 'SALÃO THEREZA NORONHA',
        content: 'O Salão Thereza Noronha, aconchegante, pode receber até 280 pessoas, com vista para as piscinas e jardins internos, além de contar com palco para apresentações.…',
        date: '25 October 2021',
        author: 'Andrew Phili',
        btnLink: '/service-details-2',
        btnText: 'SAIBA MAIS',
    },

    {
        id: uuidv4(),
        img: BlogStyleThreeImg3,
        parentCat: 'Gardening',
        regularCat: 'Nursery',
        heading: 'SALAO DA FIGUEIRA',
        content: 'Nosso Salão da Figueira dispõe de acomodações para, aproximadamente, 900 pessoas, além de possuir ar-condicionado, sua arquitetura é um convite ao embelezamento, tendo em sua…',
        date: '25 October 2021',
        author: 'Andrew Phili',
        btnLink: '/service-details-3',
        btnText: 'SAIBA MAIS',
    },
];

const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <div className="blog-events">
                        <Link to={btnLink}><img src={img} alt="" /></Link>
                    </div>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to={btnLink}>{heading}</Link></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const BlogStyleThree = () => {
    return (
        <>
            <div className="blog-area bg-white pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* <div className="section-title text-center mb-70">
                                <span>Latest News & Blog</span>
                                <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                            </div> */}
                            <div className='section-title'>
                                <h3 className={styles.titlePattern}>Sede CPG Porto Alegre</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <Pagination />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default BlogStyleThree;