import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon_landscape from '../../assets/images/service/landscape.png';
import Icon_growing from '../../assets/images/service/growing.png';
import Icon_harvest from '../../assets/images/service/harvest.png';
import Icon_flower from '../../assets/images/service/flower.png';
import Icon_tree from '../../assets/images/service/tree.png';
import Icon_rubbish from '../../assets/images/service/rubbish.png';
import { FaBuilding, FaCar, FaFootballBall, FaHeart, FaLongArrowAltRight, FaMask } from 'react-icons/fa';

const ServiceOneData = [
    {
        id: uuidv4(),
        heading: '10 OCTOBER',
        description: 'DRIVE THRU DIA DAS CRIANÇAS',
        img: <FaCar />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: '07 MARCH',
        description: 'HAPPY DAY CPG',
        img: <FaBuilding />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: '26 JANUARY',
        description: 'PRÉ CARNAVAL',
        img: <FaMask />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'ESPAÇOS PARA LOCAÇÃO',
        description: 'SAIBA MAIS SOBRE OS ESPAÇOS PARA LOCAÇÃO DE EVENTOS.',
        img: <FaBuilding />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'ESCOLAS ESPORTIVAS',
        description: 'CONHEÇA AS DIVERSAS MODALIDADE DE ESCOLAS ESPORTIVAS QUE O CLUBE OFERECE.',
        img: <FaFootballBall />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'ACADEMIA',
        description: 'ACESSE OS HORÁRIOS DA MUSCULAÇÃO, HIDROGINÁSTICA, GINÁSTICAS E OUTRAS ATIVIDADES.',
        img: <FaHeart />,
        serviceLink: '/',
    },

];

const ServiceOneItem = ({ img, heading, description, serviceLink }) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-service">
                    <div className="service-icon">
                        <span style={{ color: '#182f61', fontSize: '1.5rem' }}>{img}</span>
                    </div>
                    <h3>{heading}</h3>
                    <p>{description}</p>
                    <a style={{ color: '#182f61' }} href={serviceLink}>Saber mais <FaLongArrowAltRight /></a>
                </div>
            </div>
        </>
    )
}

const ServiceStyleOne = () => {
    return (
        <>
            <div className="service-area theme-bg-4 pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>Nossos serviços</span>
                                <h2>Como você pode aproveitar nossos espaços,<br /> e curtir com a familia</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ServiceOneData.map((data) => (
                            <ServiceOneItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                serviceLink={data.serviceLink}
                            />
                        ))}
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="single-service-large">
                                <h3>Quer mais? <span>Conheça TODOS nossos serviços EXCLUSIVOS</span></h3>
                                <p>Tenha sempre um lugar confortável e familiar, para que você e sua familia curtam as férias à vontade ou até mesmo aprimorem uma habilidades enquanto se divertem</p>
                                <a href="/" className="l-btn">Conhecer outros serviços</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceStyleOne;