import React from 'react';
import {Link} from 'react-router-dom';
import { FaPlus, FaLongArrowAltRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import BlogStyleThreeImg1 from '../../assets/images/cpg-library/noticia_novas-aquisicoes-agosto.webp';
import BlogStyleThreeImg2 from '../../assets/images/cpg-library/noticia_novas-aquisicoes-julho.webp';
import BlogStyleThreeImg3 from '../../assets/images/cpg-library/noticia_novas-aquisicoes-junho.webp';
import Pagination from '../pagination/Pagination';
import styles from '../../styles/global.module.css';

const BlogStyleThreeData = [
    
    // {
    //     id: uuidv4(),
    //     img: BlogStyleThreeImg1,
    //     parentCat: '',
    //     regularCat: '',
    //     heading: 'AQUISIÇÕES AGOSTO 2018',
    //     content: '',
    //     date: '11 October 2023',
    //     author: '',
    //     btnLink: '/service-details',
    //     btnText: 'SAIBA MAIS',
    // },
    
    {
        id: uuidv4(),
        img: BlogStyleThreeImg2,
        parentCat: '',
        regularCat: '',
        heading: 'AQUISIÇÕES JULHO 2018',
        content: 'Pequenos Incêndios por Toda Parte – Celeste N. G. - ROMANCE NORTE-AMERICANO - 10843 Um encontro entre duas famílias completamente diferentes vai afetar a vida de…',
        date: '11 October 2023',
        author: '',
        btnLink: '/service-details-4',
        btnText: 'SAIBA MAIS',
    },
];

const BlogStyleThreeItem = ({ img, parentCat, regularCat, heading, content, date, author, btnLink, btnText }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="single-blog-wrapper pt-10 pr-10 pb-10 pl-10 mb-25 box-shadow-1">
                    <div className="blog-img">
                        <img src={img} alt="" />
                        <Link to=""><FaPlus /></Link>
                    </div>
                    <div className="blog-content pt-20 pr-20 pb-20 pl-20">
                        {/* <ul className="cat-list mt-30 mb-30">
                            <li className="parent-cat"><Link to="/">{parentCat}</Link></li>
                            <li><Link to="/">{regularCat}</Link></li>
                        </ul> */}
                        <h4><Link to="/">{heading}</Link></h4>
                        <p>{content}</p>
                        {/* <ul className="post-meta mb-30">
                            <li><Link to="/">{date}</Link></li>
                            <li><span>By</span> <Link to="/">{author}</Link></li>
                        </ul> */}
                        <Link to={btnLink} className="l-read-more">{btnText}<FaLongArrowAltRight /></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const CPGLibraryAcquisitions = () => {
    return (
        <>
            <div className="blog-area bg-white pt-50 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="mb-40">
                                <span className={styles.textPattern2}>Aquisições da</span>
                                <h2 className={styles.titlePattern} style={{textAlign:"left"}}>BIBLIOTECA CPG</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {BlogStyleThreeData.map((data) => (
                            <BlogStyleThreeItem
                                key={data.id}
                                img={data.img}
                                parentCat={data.parentCat}
                                regularCat={data.regularCat}
                                heading={data.heading}
                                content={data.content}
                                date={data.date}
                                author={data.author}
                                description={data.description}
                                btnLink={data.btnLink}
                                btnText={data.btnText}
                            />
                        ))}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <Pagination />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default CPGLibraryAcquisitions;