import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutVideoBlock from '../components/about/AboutVideoBlock';
import FunFact from '../components/fun-fact/FunFact';
import AboutExperience from '../components/about/AboutExperience';
import AboutBlock from '../components/about/AboutBlock';
import CtaTwo from '../components/cta/CtaTwo';
import AboutTeam from '../components/about/AboutTeam';
import Subscribe from '../components/subscribe/Subscribe';
import TestimonialStyleTwo from '../components/testimonial/TestimonialStyleTwo';
import HowItWorkOne from '../components/how-it-work/HowItWorkOne';
import AboutCPGPoa from '../components/cpg-poa/AboutCPGPoa';
import HistoryCPGPoa from '../components/cpg-poa/HistoryCPGPoa';
import LocationCPGPoa from '../components/cpg-poa/LocationCPGPoa';
import CarrouselPOA from '../components/cpg-poa/CarrouselPOA';


const CPGPoa = () => {
    return (
        <>
            <HeaderTop />
            
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="CPG PORTO ALEGRE"
                description=""
                currentPage="CPG PORTO ALEGRE"
            />
            <AboutCPGPoa />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            <CarrouselPOA />
            <HistoryCPGPoa />
            <LocationCPGPoa />
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default CPGPoa;

