import React, { useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import AboutTeamImg1 from '../../assets/images/team/IMG_1.webp';
import AboutTeamImg2 from '../../assets/images/team/IMG_2.webp';
import AboutTeamImg3 from '../../assets/images/team/IMG_3.webp';
import AboutTeamImg4 from '../../assets/images/team/IMG_4.webp';
import AboutTeamImg5 from '../../assets/images/team/IMG_5.webp';
import AboutTeamImg6 from '../../assets/images/team/IMG_6.webp';
import AboutTeamImg7 from '../../assets/images/team/IMG_7.webp';
import styles from '../../styles/global.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';

const AboutTeamData = [

    {
        id: uuidv4(),
        img: AboutTeamImg1,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg2,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg3,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg4,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg5,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg6,
        imgAlt: '',

        heading: '',
        position: '',
    },

    {
        id: uuidv4(),
        img: AboutTeamImg7,
        imgAlt: '',

        heading: '',
        position: '',
    },

];

const AboutTeamItem = ({ img, imgAlt, fbLink, twitterLink, instagramLink, pinterestLink, heading, position, openModal }) => {

    return (
        <>
            <div className={styles.centerCarroussel}>
                <div className="col-11">
                    <div className="single-team-style-2 text-center">
                        <div className="single-team-style-2-img">
                            <img onClick={() => openModal(img)} style={{ paddingBottom: "130px", cursor: "pointer" }} src={img} alt={imgAlt} />
                            {/* <div className="team-social-style-2">
                                <a href={fbLink}><FaFacebookF /></a>
                                <a href={twitterLink}><FaTwitter /></a>
                                <a href={instagramLink}><FaInstagram /></a>
                                <a href={pinterestLink}><FaPinterestP /></a>
                            </div> */}
                        </div>
                        <div className="single-team-content-style-2" style={{ backgroundColor: "transparent" }}>
                            <h4 style={{ color: "#222", fontSize: "16px", textTransform: "uppercase" }}>{heading}</h4>
                            <span style={{ display: "block", color: "#999", fontSize: "12px", fontWeight: "600", borderBottom: "1px solid #eee", textTransform: "uppercase", paddingBottom: "25px" }}>{position}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const HandicraftImages = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Add state to track the current image

    const downloadImage = () => {
        if (currentImage) {
            const downloadLink = document.createElement('a');
            downloadLink.href = currentImage;
            downloadLink.download = 'imagem_cpg.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowDownloadButton();
        }
    }

    function openModal(img) {
        setCurrentImage(img); // Set the current image before opening the modal
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }


    var settings = {
        speed: 500,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <>
            <div className="team-area white-bg pb-110">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-12">
                            <div className="section-title mb-60">
                                <span style={{display: "block", fontSize: "28px", fontWeight:"300", marginBottom:"0px", color: "#222" }}>Presidentes</span>
                                <h2 style={{textTransform:"uppercase", fontSize: "34px", color: "#222"}}>CLUBE DO PROFESSOR GAÚCHO</h2>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <Slider
                            {...settings}
                        >
                            {AboutTeamData.map((data) => (
                                <AboutTeamItem
                                    key={data.id}
                                    img={data.img}
                                    imgAlt={data.imgAlt}
                                    heading={data.heading}
                                    position={data.position}
                                    openModal={openModal}
                                    downloadImage={downloadImage}
                                />
                            ))}
                        </Slider>
                        {
                            showModal == true ?
                                <div className={styles.imageModal}>
                                    <img className={styles.zoomImageGallery} src={currentImage} alt="" />
                                    <button onClick={closeModal} title="Fechar" className={styles.closeModalGallery}><AiOutlineClose size={28} /></button>
                                    <button onClick={downloadImage} title="Download" className={styles.downloadModalGallery}><HiDownload size={28} /></button>
                                </div>
                                :

                                ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HandicraftImages;