import React, { useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { AiOutlineBars } from 'react-icons/ai';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles/global.module.css';


const menuData = [
    {
        title: 'Institucional',
        // path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'História',
                path: '/historia',
            },
            {
                title: 'Sedes',
                // path: '',
                subMenu: [
                    {
                        title: 'CPG Porto Alegre',
                        path: '/cpg-porto-alegre',
                    },
                    {
                        title: 'CPG Pinhal',
                        path: '/cpg-pinhal',
                    },
                    {
                        title: 'CPG Santa Maria',
                        path: '/cpg-santa-maria',
                    },
                ],
            },

            {
                title: 'Gestão',
                path: '/gestao-cpg',
            },

            {
                title: 'Estatuto',
                path: '/estatuto-regulamentos-e-relatorios',
            },
        ],
    },
    {
        title: 'Serviços',
        // path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Associe-se',
                path: '/associe-se',
            },
            {
                title: 'Horário de funcionamento',
                path: '/horario-de-funcionamento',
            },

            {
                title: 'Faça seu evento',
                path: '/cpg-centro-de-eventos',
            },

            {
                title: 'Clube de vantagens',
                path: '/clube-de-vantagens',
            },

            {
                title: 'Terceirizados no Clube',
                path: '/terceirizados-no-clube',
            },
        ],
    },
    {
        title: 'Sociocultural',
        path: '#',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Sociocultural',
                path: '/cpg-sociocultural',
            },
            {
                title: 'Artesanato',
                path: '/artesanato',
            },
            {
                title: 'Biblioteca',
                path: '/biblioteca',
            },

            {
                title: 'Confraria dos casais',
                path: '/confraria-dos-casais',
            },

            {
                title: 'Confraria cooks do saber',
                path: '/confraria-cooks-do-saber',
            },

            {
                title: 'Confraria mestres do sabor',
                path: '/confraria-mestres-do-sabor',
            },

            {
                title: 'Coral',
                path: '/coral',
            },

            {
                title: 'Cultural',
                path: '/cultural',
            },

            {
                title: 'CTG Laço da querência',
                path: '/ctg-laco-da-querencia',
            },

            {
                title: 'Reviver',
                path: '/reviver',
            },
        ],

    },
    {
        title: 'Esporte',
        //path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Modalidades Esportivas',
                // path: '/modalidades-esportivas',
                subMenu: [
                    {
                        title: 'Ballet',
                        path: '/cpg-ballet',
                    },
                    {
                        title: 'Basquete',
                        path: '/cpg-basquete',
                    },
                    {
                        title: 'Dança Cigana',
                        path: '/cpg-danca-cigana',
                    },
                    {
                        title: 'Dança da Maturidade',
                        path: '/cpg-danca-da-maturidade',
                    },
                    {
                        title: 'Dança de Salão',
                        path: '/cpg-danca-de-salao',
                    },
                    {
                        title: 'Futsal',
                        path: '/cpg-futsal',
                    },
                    {
                        title: 'Handebol',
                        path: '/cpg-handebol',
                    },
                    {
                        title: 'Hidroginástica',
                        path: '/cpg-handebol',
                    },
                    {
                        title: 'Jazz',
                        path: '/cpg-jazz',
                    },
                    {
                        title: 'Judô',
                        path: '/cpg-judo',
                    },
                    {
                        title: 'Karatê',
                        path: '/cpg-karate',
                    },
                    {
                        title: 'Natação',
                        path: '/cpg-natacao',
                    },
                    {
                        title: 'Patinação',
                        path: '/cpg-patinacao',
                    },
                    {
                        title: 'Tênis',
                        path: '/cpg-tenis',
                    },
                    {
                        title: 'Vivências Aquaticas',
                        path: '/cpg-vivencias-aquaticas',
                    },
                    {
                        title: 'Yoga',
                        path: '/cpg-yoga',
                    },
                ],
            },
            {
                title: 'Espaços Esportivos',
                path: '/espacos-esportivos',
            }
        ]
    },
    {
        title: 'Comunicação',
        // path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Galeria De Fotos',
                path: '/cpg-galeria',
            },
            {
                title: 'CPG em Revista',
                path: '/categoria',
            },
            {
                title: 'Conexão CPG',
                path: '/conexao-cpg',
            },
        ]
    },
    {
        title: 'Meu CPG',
        path: 'http://cpg.com.br/socios/',
    },
    {
        title: 'Contato',
        path: '#',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Fale Conosco',
                path: '/fale-conosco',
            },
            {
                title: 'Trabalhe Conosco',
                path: '/trabalhe-conosco',
            },
            {
                title: 'Quero Receber Notícias',
                path: '/quero-receber-noticias',
            },
        ]
    },
];

const SidebarLabel = styled.span`
    margin-left: 16px;
    color: #fff;
    font-weight: 400;
`;

const DropdownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    &:hover,
    &:active {
        // background: #e9c200;
        cursor: pointer;
        color: #fff;
    }
`;



const NavIcon = styled(Link)`
    font-size: 2rem;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
`;

const SidebarNav = styled.nav`
    background-color: #183061;
    width: 300px;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
    top: 0;
    right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 99999;
`;

const SidebarWrap = styled.div`
    width: 100%;
`;
const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const [openSubmenu, setOpenSubmenu] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    const showSubMenu = () => setOpenSubmenu(!openSubmenu);

    return (
        <>
            <a className={styles.sidebarLink} href={item.path} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </a>
            {subnav &&
                item?.subNav.map((data, index) => (
                    <>
                        <DropdownLink to={data.path} key={index}>
                            {item.icon}
                            <a onClick={showSubMenu}>
                                <SidebarLabel>{data.title}</SidebarLabel>
                            </a>
                            <div>
                                {data?.subMenu && openSubmenu ? item.iconOpened : data?.subMenu ? item.iconClosed : null}
                            </div>
                        </DropdownLink>
                        {data?.subMenu && openSubmenu ? (
                            data.subMenu.map((submenu, subIndex) => (
                                <DropdownLink  to={submenu.path} key={subIndex}>
                                    <SidebarLabel style={{paddingLeft:"2rem"}}>{submenu.title}</SidebarLabel>
                                </DropdownLink>
                            ))
                        ) : null}
                    </>
                ))}
        </>
    );

    // return (
    //     <>
    //         <a className={styles.sidebarLink} href={item.path} onClick={item.subNav && showSubnav}>
    //             <div>
    //                 {item.icon}
    //                 <SidebarLabel>{item.title}</SidebarLabel>
    //             </div>
    //             <div>
    //                 {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
    //             </div>
    //         </a>
    //         {subnav &&
    //             item?.subNav.map((data, index) => (
    //                 <DropdownLink to={data.path} key={index}>

    //                     {item.icon}
    //                     <a onClick={showSubMenu}>
    //                         <SidebarLabel>{data.title}</SidebarLabel>
    //                     </a>

    //                     { console.log("dataaaa", data.subMenu) }
    //                     <div>
    //                         {data?.subMenu && subnav ? item.iconOpened : item.subMenu ? item.iconClosed : null}
    //                     </div>

    //                     {
    //                         data?.subMenu && openSubmenu ?
    //                             data.subMenu.map((submenu) => {
    //                                 <a href={submenu.path}>
    //                                     {submenu.title}
    //                                 </a>
    //                             })
    //                             :
    //                             null
    //                     }


    //                 </DropdownLink>
    //             ))}
    //     </>
    // );
};
const MobileMenu = () => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <>
                <NavIcon className="d-lg-none" to="#" style={{ justifyContent: 'flex-end' }}>
                    <AiOutlineBars onClick={showSidebar} />
                </NavIcon>

                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose
                                style={{
                                    color: 'white',
                                    fontSize: '18px',
                                    justifyContent: 'flex-start',
                                }}
                                onClick={showSidebar}
                            />
                        </NavIcon>
                        {menuData.map((item, index) => (
                            <SubMenu item={item} key={index} />
                        ))}
                    </SidebarWrap>
                </SidebarNav>
            </>
        </>
    );
};

export default MobileMenu;