import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/about/about-banner.jpg';
import FooterOne from '../common/footer/FooterOne';
import AboutCPGManagement from '../components/cpg-management/AboutCPGManagement';
import CarrouselManagement from '../components/cpg-management/CarrouselManagement';
import AboutSection from '../components/cpg-management/AboutSection';


const CPGManagement = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="DIRETORIA EXECUTIVA"
                description=""
                currentPage="DIRETORIA EXECUTIVA"
            />
            <AboutCPGManagement />
            {/* <FunFact /> */}
            {/* <AboutExperience /> */}
            {/* <AboutBlock /> */}
            {/* <CtaTwo /> */}
            <CarrouselManagement />
            <AboutSection />
            {/* <HowItWorkOne /> */}
            {/* <TestimonialStyleTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
            
        </>
    )
}

export default CPGManagement;

